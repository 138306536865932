import styled from 'styled-components';
import { Button } from 'antd5';
import { Modal as BaseModal } from 'components/Modal';

export const Modal = styled(BaseModal)`
  left: 0.5rem;
  height: calc(100vh - 1rem);
  margin: 0;
  max-width: unset;
  padding: 0;
  top: 0.5rem;
  width: calc(100vw - 1rem) !important;

  .ant-modal-content {
    height: 100%;
    width: 100%;
  }

  .ant-modal-body {
    display: flex;
    height: 100%;
    padding: 0;
    width: 100%;
  }

  .ant-modal-close-x {
    color: white;
    font-size: 2em;
    filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.6));
  }
`;

export const Pane = styled.div`
  position: relative;
  width: 100%;

  &:nth-last-of-type(2) {
    margin-right: 5px;
  }
  &:nth-of-type(2) {
    margin-left: 5px;
  }
`;

export const PaneOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr 40px auto 40px 1fr;
  pointer-events: none;
  padding: 8px;

  > * {
    pointer-events: auto;
  }

  > div:nth-child(2) {
    grid-column: 3;
  }
`;

export const ImageContainer = styled.div`
  height: 100%;
`;

export const ObservationPolygon = styled.polygon.attrs(
  (props: { coordinates?: any[] | null; selected: boolean }) => ({
    points: props.coordinates && props.coordinates.map(xy => xy.join(',')).join(' '),
    selected: props.selected,
    coordinates: props.coordinates,
  })
)`
  fill: none;
  stroke: ${({ theme, color }) => (color ? theme[color] : theme.red)};
  vector-effect: non-scaling-stroke;
  @supports (vector-effect: non-scaling-stroke) {
    stroke-width: ${({ selected }) => (selected ? '3px' : '1px')};
  }
  pointer-events: visible;

  &:hover {
    stroke-width: 6px;
    cursor: pointer;
  }
`;

export const StyledButton = styled(Button)`
  padding: 4px 8px;
`;

export const ThumbnailHeader = styled.div<{ hasControlsOnly?: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${({ hasControlsOnly }) => (hasControlsOnly ? 'center' : 'space-between')};
  padding: 0 0.5rem;
  position: absolute;
  row-gap: 4px;
  top: 0.5rem;
  width: 100%;
  z-index: 1;
`;
