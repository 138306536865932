import styled from 'styled-components';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 10vh;
  width: 100%;

  .ant-spin-dot {
    overflow: hidden;
  }
`;

export const Spinner = ({ className = 'spinner', size = 2 }) => (
  <CenteredContainer data-testid="spinner" className={className}>
    <Spin
      indicator={
        <LoadingOutlined
          spin
          style={{
            fontSize: `${size}rem`,
            color: `${({ theme }: { theme: any }) => theme.blue}`,
          }}
        />
      }
    />
  </CenteredContainer>
);
