import styled from 'styled-components';

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  .ant-btn:first-child {
    margin-left: auto;
    margin-right: 0.5rem;
  }
`;
