import styled from 'styled-components';
import { Button, Popover } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import { NotificationsPopover } from './NotificationsPopover';
import { useGetNotificationsQuery } from 'types/atlas-graphql';
import { notifyBugsnag } from 'utils/bugsnag';
import { addHeapEvent } from 'utils/heap';
import { useFocusedPolling } from 'utils/hooks';

const ONE_MINUTE = 60000;

const BadgeButton = styled(Button)`
  border: none;
  box-shadow: none;
  margin-top: 1.25rem;
  position: relative;

  svg {
    height: 1.25rem;
    width: 1.25rem;
  }
`;

const Badge = styled.span`
  background: ${({ theme }) => theme.red};
  width: 10px;
  height: 10px;
  position: absolute !important;
  border-radius: 50%;
  margin-left: 0 !important;
  right: 5px;
`;

export function NotificationsButton() {
  const { data, error, refetch, startPolling, stopPolling } = useGetNotificationsQuery();

  useFocusedPolling({
    pollInterval: ONE_MINUTE,
    refetch,
    startPolling,
    stopPolling,
  });

  if (error) {
    // @ts-ignore typescript for some reason doesn't like the options argument to Error even though the jsdoc includes it
    notifyBugsnag(new Error('Notification Button failed to get notifications', { cause: error }));
    addHeapEvent('NotificationsButton Error', { error: error.message });
  }

  const notifications = data?.legacyNotifications ?? [];

  if (notifications.length === 0) {
    return null;
  }

  const hasUnread = notifications.some(({ isRead }: { isRead: boolean }) => !isRead);

  return (
    <Popover
      placement="bottomRight"
      content={<NotificationsPopover notifications={notifications} />}
      trigger="click"
    >
      <BadgeButton icon={<BellOutlined />} shape="circle" title="Notifications">
        {hasUnread && <Badge />}
      </BadgeButton>
    </Popover>
  );
}
