import {
  AtlasGqlHorizonDamageObservationGroup,
  AtlasGqlHorizonDamageObservation,
  AtlasGqlHorizonDamage,
} from 'types/atlas-graphql';

/**
 * Checks to see if `source` contains all values from `target`
 * @param source
 * @param target
 * @returns boolean
 */
export const hasAll = (source: string[], target: string[]) => target.every(v => source.includes(v));
/**
 * Checks to see if `source` contains any values from `target`
 * @param source
 * @param target
 * @returns boolean
 */
export const hasSome = (source: string[], target: string[]) => target.some(v => source.includes(v));

/**
 * reducer function for extracting and flattening observation Ids
 * @param allObservations
 * @param group
 * @returns
 */
const reduceObservations = (
  allObservations: string[],
  group: AtlasGqlHorizonDamageObservationGroup | undefined
): string[] => {
  return [
    ...allObservations,
    ...(group?.observations || []).map((obs: AtlasGqlHorizonDamageObservation) => obs?.id),
  ];
};

/**
 * Get all observation IDs from a damage by looking in all of its observation groups
 * @param damage
 * @returns string[]
 */
export const extractObservationIdsFromDamage = (damage: AtlasGqlHorizonDamage) =>
  (
    damage?.observationGroups?.filter(group => !!group) as AtlasGqlHorizonDamageObservationGroup[]
  )?.reduce<string[]>(reduceObservations, [] as string[]) || ([] as string[]);
