import { cloneElement, useState } from 'react';
import { Tooltip } from 'components/Tooltip';
import { AlignType } from 'rc-trigger/lib/interface';
import { isEmpty } from 'lodash';
import { None } from 'components/data/helpers';

import {
  useGetDamageForTooltipQuery,
  useGetHorizonDamageObservationForTooltipQuery,
} from 'types/atlas-graphql';
import { useDamageAttrs } from 'components/damages/withDamages';
import { formatObservationId } from 'horizon/components/Damages/utils';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { useAccountContext } from 'utils/account/AccountContext';
import { CRITICAL_DAMAGE_VISIBILITY } from 'utils/release-toggles';
import { CriticalLevel } from 'components/SeverityAndCriticalIndicator';

type DamageTooltipProps = {
  key: string;
  isHorizonDamage?: boolean | undefined;
  isNewFullSizeImageViewer?: boolean;
  imagePaneBox?: DOMRect;
  id: string;
  align?: AlignType;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  children: JSX.Element;
};

const DEFAULT_ALIGNMENT = {
  targetOffset: [-50],
  useCssBottom: true,
};

const renderAttrs = (
  attrs: { [key: string]: any },
  damageColumns: any[],
  critical?: boolean | null
) => {
  const attrsArray = attrs ? Object.keys(attrs) : [];
  return (
    <>
      {damageColumns
        .filter(
          column => attrsArray.includes(column.key) || (column.key === 'Severity' && critical)
        )
        .map(column => {
          const key = column.key;
          const value = column.render(
            attrs[key],
            column.key === 'Severity' && critical
              ? { critical, criticalLevel: CriticalLevel.Observation }
              : {}
          );

          return (
            <div key={key + value}>
              <b>{key}:</b> {value}
            </div>
          );
        })}
    </>
  );
};

const renderAdditionalAttrs = (key: string, value: string = '') => (
  <div key={key + value}>
    <b>{key}:</b> {!isEmpty(value) ? value : <None />}
  </div>
);

// legacy damage title
const DamageTitle = ({ id }: { id: string }) => {
  const { data: damageData, loading: damageLoading } = useGetDamageForTooltipQuery({
    variables: { damageId: id },
  });
  const damage = damageData?.damage;

  const { damageColumns, loading: damageAttrsLoading } = useDamageAttrs(damage?.schema?.id);

  if (damageLoading || damageAttrsLoading) {
    return <span>'Loading...'</span>;
  }

  return renderAttrs(damage?.attrs ?? [], damageColumns);
};

// horizon observation title
const ObservationTitle = ({ id }: { id: string }) => {
  const { hasReleaseToggle } = useAccountContext();
  const { data: horizonDamageObservationData, loading: observationLoading } =
    useGetHorizonDamageObservationForTooltipQuery({
      variables: { input: { id } },
    });
  const observation = horizonDamageObservationData?.getHorizonDamageObservation;

  const { data: legacyDamageData, loading: legacyDamageLoading } = useGetDamageForTooltipQuery({
    variables: { damageId: observation?.legacyDamageBranchId },
    skip: !observation?.legacyDamageBranchId,
  });
  const legacyDamage = legacyDamageData?.damage;

  const { damageColumns, loading: damageAttrsLoading } = useDamageAttrs(observation?.schema?.id);

  if (observationLoading || legacyDamageLoading || damageAttrsLoading) {
    return <span>'Loading...'</span>;
  }

  return (
    <>
      {renderAdditionalAttrs('Observation', formatObservationId({ id: observation?.id }))}
      {renderAttrs(
        observation?.attrs ?? [],
        damageColumns,
        hasReleaseToggle(CRITICAL_DAMAGE_VISIBILITY) && observation?.critical
      )}
      {renderAdditionalAttrs('Legacy ID', legacyDamage?.number)}
    </>
  );
};

export const DamageTooltip: React.FunctionComponent<DamageTooltipProps> = ({
  id,
  align,
  onClick,
  isHorizonDamage,
  isNewFullSizeImageViewer,
  imagePaneBox,
  ...props
}: {
  id: string;
  align?: AlignType;
  isHorizonDamage?: boolean | undefined;
  isNewFullSizeImageViewer?: boolean;
  imagePaneBox?: DOMRect;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  children: JSX.Element;
}) => {
  const [placement, setPlacement] = useState<TooltipPlacement>('top');

  if (id) {
    const Title = isHorizonDamage ? ObservationTitle : DamageTitle;

    /**
     * Since it is possible to have the observation box go behind the opposite
     * pane in the new full size image viewer, we need to check whether the
     * box's center is behind the opposite pane, and if so, move the tooltip
     * to the left or right accordingly. Also happens when the center is
     * horizontally out of the window for placement consistency.
     */
    const positioningProps = isNewFullSizeImageViewer
      ? {
          placement,
          onMouseEnter: ({ target }: MouseEvent) => {
            if (!!target && !!imagePaneBox) {
              const observationBox = (target as SVGElement).getBoundingClientRect();

              // Find the horizontal centerpoint of the observation
              const horizontalCenter = (observationBox.left + observationBox.right) / 2;

              /**
               * If the center of the observation is off the left side of the pane,
               * position the tooltip to the right of the box and vice versa.
               */
              if (horizontalCenter < imagePaneBox.left) {
                setPlacement('right');
              } else if (horizontalCenter > imagePaneBox.right) {
                setPlacement('left');
              } else {
                setPlacement('top');
              }
            }
          },
        }
      : {
          align: {
            ...DEFAULT_ALIGNMENT,
            ...align,
          },
        };

    return (
      <Tooltip
        title={
          <div style={{ cursor: 'pointer' }} role="button" onClick={onClick}>
            <Title id={id} />
          </div>
        }
        {...positioningProps}
      >
        {onClick ? cloneElement(props.children, { onClick: onClick }) : props.children}
      </Tooltip>
    );
  }

  return props.children || null;
};
