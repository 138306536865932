import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Button, Radio, Switch } from 'antd5';
import {
  CustomFilterPopover,
  CustomFilterContainer,
  SecondaryFilterLabel,
} from 'components/data/helpers/filters.style';
import { SwitchContainer, AdvancedFiltersCollapse } from './TasksFilter.style';
import { AtlasGqlTaskStatus } from 'types/atlas-graphql';
import { clone, isEqual, startCase, uniq } from 'lodash';
import { Tooltip, TooltipWithQuestionIcon } from 'components/Tooltip';
import { useCallback, useMemo } from 'react';
import { TABLE_FILTER_OPERATORS } from 'utils/constants';
import { CustomFilterDropdownProps } from 'horizon/types/TableColumnDef';
import { WarningFilled } from '@ant-design/icons';
import COLORS from 'utils/color/definitions';

const noneValue = ' ';
const hasTasksValue = [1];
const noTasksValue = [TABLE_FILTER_OPERATORS.EQUALS, '0'];

enum damageTaskTypes {
  RepairTask = 'REPAIR_TASK',
  InspectTask = 'INSPECT_TASK',
}
const allDamageTaskTypes = Object.values(damageTaskTypes);
const allTaskStatuses = Object.values(AtlasGqlTaskStatus);

export const TasksFilter: React.FunctionComponent<CustomFilterDropdownProps> = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}) => {
  const getFilterValueForKey = (k: string) => selectedKeys.find(({ key }) => key === k)?.value;

  const {
    taskCountValue,
    taskTypeValue,
    taskStatusValue,
    hasUnarchivedTasksValue,
    hasPrimaryGroupTasksValue,
  } = useMemo(
    () => ({
      taskCountValue: getFilterValueForKey('taskCount'),
      taskTypeValue: getFilterValueForKey('taskType'),
      taskStatusValue: getFilterValueForKey('taskStatus'),
      hasUnarchivedTasksValue: getFilterValueForKey('hasUnarchivedTasks'),
      hasPrimaryGroupTasksValue: getFilterValueForKey('hasPrimaryGroupTasks'),
    }),
    [selectedKeys]
  );
  const advancedFiltersDisabled: boolean = !isEqual(taskCountValue, hasTasksValue);

  const getToggleHandler =
    (filterKey: string, allOptions: string[]) => (option: string, checked: boolean) => {
      /**
       * previously included options is the existing filter, if it exists,
       * otherwise, all options were previously included
       */
      let includedOptions = (getFilterValueForKey(filterKey) as string[]) ?? allOptions;

      if (checked) {
        includedOptions = uniq([...includedOptions, option]);
      } else if (!checked) {
        includedOptions = includedOptions.filter(o => o !== option);
      }

      const otherExistingFilters = selectedKeys.filter(({ key }) => key !== filterKey);
      /**
       * if every option should be included, don't send a filter (i.e. this
       * is the default), otherwise, set the included options as the filter
       */
      if (allOptions.every(o => includedOptions.includes(o))) {
        setSelectedKeys(otherExistingFilters);
      } else {
        setSelectedKeys([...otherExistingFilters, { key: filterKey, value: includedOptions }]);
      }
    };

  const handleToggleType = useCallback(getToggleHandler('taskType', allDamageTaskTypes), [
    allDamageTaskTypes,
    selectedKeys,
    setSelectedKeys,
  ]);
  const handleToggleStatus = useCallback(getToggleHandler('taskStatus', allTaskStatuses), [
    allTaskStatuses,
    selectedKeys,
    setSelectedKeys,
  ]);

  return (
    <CustomFilterPopover className="ant-table-filter-dropdown" width="340px">
      <CustomFilterContainer>
        <SecondaryFilterLabel>Tasks</SecondaryFilterLabel>
        <Radio.Group
          options={[
            { label: 'Tasks', value: true },
            { label: 'No Tasks', value: false },
            { label: 'All', value: noneValue },
          ]}
          onChange={({ target }: CheckboxChangeEvent) => {
            if (target.value === true) {
              setSelectedKeys([
                ...selectedKeys.filter(({ key }) => key !== 'taskCount'),
                { key: 'taskCount', value: hasTasksValue },
              ]);
            } else if (target.value === false) {
              setSelectedKeys([{ key: 'taskCount', value: noTasksValue }]);
            } else {
              setSelectedKeys([]);
            }
          }}
          value={
            isEqual(taskCountValue, hasTasksValue)
              ? true
              : isEqual(taskCountValue, noTasksValue)
                ? false
                : noneValue
          }
          optionType="button"
        />
      </CustomFilterContainer>

      <AdvancedFiltersCollapse
        defaultActiveKey={
          !!taskTypeValue ||
          !!taskStatusValue ||
          !!hasPrimaryGroupTasksValue ||
          !!hasUnarchivedTasksValue
            ? 1
            : undefined
        }
        items={[
          {
            key: 1,
            label: (
              <>
                <span>Advanced Task Filters</span>{' '}
                <TooltipWithQuestionIcon
                  message={
                    'When "Tasks" is selected above, Advanced Task Filters may be used for further refinement'
                  }
                />
              </>
            ),
            children: (
              <>
                <CustomFilterContainer>
                  <SecondaryFilterLabel>
                    Task Types{' '}
                    {isEqual(taskTypeValue, []) && (
                      <Tooltip title="Turning off all the switches in this section will hide all table entries">
                        <WarningFilled style={{ color: COLORS.SEV_LESS_COLOR }} />
                      </Tooltip>
                    )}
                  </SecondaryFilterLabel>
                  {allDamageTaskTypes.map(type => (
                    <SwitchContainer key={type} disabled={advancedFiltersDisabled}>
                      {startCase(type.toLowerCase())}s:{' '}
                      <Switch
                        onChange={checked => handleToggleType(type, checked)}
                        checked={!taskTypeValue || taskTypeValue.includes(type)}
                        disabled={advancedFiltersDisabled}
                      />
                    </SwitchContainer>
                  ))}
                </CustomFilterContainer>
                <CustomFilterContainer>
                  <SecondaryFilterLabel>
                    Task Status{' '}
                    {isEqual(taskStatusValue, []) && (
                      <Tooltip title="Turning off all the switches in this section will hide all table entries">
                        <WarningFilled style={{ color: COLORS.SEV_LESS_COLOR }} />
                      </Tooltip>
                    )}
                  </SecondaryFilterLabel>
                  {clone(allTaskStatuses)
                    .reverse()
                    .map(status => (
                      <SwitchContainer key={status} disabled={advancedFiltersDisabled}>
                        {startCase(status.toLowerCase())}:{' '}
                        <Switch
                          onChange={checked => handleToggleStatus(status, checked)}
                          checked={!taskStatusValue || taskStatusValue.includes(status)}
                          disabled={advancedFiltersDisabled}
                        />
                      </SwitchContainer>
                    ))}
                </CustomFilterContainer>
                <CustomFilterContainer>
                  <SecondaryFilterLabel>
                    Archived{' '}
                    <TooltipWithQuestionIcon message="Show or hide damages where the only related tasks are archived" />
                  </SecondaryFilterLabel>
                  <Radio.Group
                    options={[
                      { label: 'Active', value: true },
                      { label: 'Archived', value: false },
                      { label: 'Both', value: noneValue },
                    ]}
                    onChange={({ target }: CheckboxChangeEvent) => {
                      if (target.value === true) {
                        setSelectedKeys([
                          ...selectedKeys.filter(({ key }) => key !== 'hasUnarchivedTasks'),
                          { key: 'hasUnarchivedTasks', value: 'true' },
                        ]);
                      } else if (target.value === false) {
                        setSelectedKeys([
                          ...selectedKeys.filter(({ key }) => key !== 'hasUnarchivedTasks'),
                          { key: 'hasUnarchivedTasks', value: 'false' },
                        ]);
                      } else {
                        setSelectedKeys([
                          ...selectedKeys.filter(({ key }) => key !== 'hasUnarchivedTasks'),
                        ]);
                      }
                    }}
                    value={
                      hasUnarchivedTasksValue === 'true'
                        ? true
                        : hasUnarchivedTasksValue === 'false'
                          ? false
                          : noneValue
                    }
                    disabled={advancedFiltersDisabled}
                    optionType="button"
                  />
                </CustomFilterContainer>
                <CustomFilterContainer>
                  <SecondaryFilterLabel>
                    Based on Primary{' '}
                    <TooltipWithQuestionIcon message="Show or hide damages with tasks created against non-Primary Observation Groups" />
                  </SecondaryFilterLabel>
                  <Radio.Group
                    options={[
                      { label: 'Primary', value: true },
                      { label: 'Non-Primary', value: false },
                      { label: 'Both', value: noneValue },
                    ]}
                    onChange={({ target }: CheckboxChangeEvent) => {
                      if (target.value === true) {
                        setSelectedKeys([
                          ...selectedKeys.filter(({ key }) => key !== 'hasPrimaryGroupTasks'),
                          { key: 'hasPrimaryGroupTasks', value: 'true' },
                        ]);
                      } else if (target.value === false) {
                        setSelectedKeys([
                          ...selectedKeys.filter(({ key }) => key !== 'hasPrimaryGroupTasks'),
                          { key: 'hasPrimaryGroupTasks', value: 'false' },
                        ]);
                      } else {
                        setSelectedKeys([
                          ...selectedKeys.filter(({ key }) => key !== 'hasPrimaryGroupTasks'),
                        ]);
                      }
                    }}
                    value={
                      hasPrimaryGroupTasksValue === 'true'
                        ? true
                        : hasPrimaryGroupTasksValue === 'false'
                          ? false
                          : noneValue
                    }
                    disabled={advancedFiltersDisabled}
                    optionType="button"
                  />
                </CustomFilterContainer>
              </>
            ),
          },
        ]}
      />

      <div className="ant-table-filter-dropdown-btns" style={{ borderTop: '1px solid #f0f0f0' }}>
        <Button type="link" size="small" onClick={clearFilters}>
          Reset
        </Button>
        <Button type="primary" size="small" onClick={() => confirm({ closeDropdown: true })}>
          OK
        </Button>
      </div>
    </CustomFilterPopover>
  );
};
