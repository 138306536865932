/**
 * Component for drawing a generic blade edge shape (i.e. leading edge,trailing edge). Takes width/height props
 * to scale appropriately with the plot.
 */

type EdgeShapeProps = { width: number; height: number };

export const EdgeShape: React.FunctionComponent<EdgeShapeProps> = ({ width, height }) => (
  <path
    d="M244.002 17.4312C320.601 22.1278 546.826 26 584.567 26C586.711 26 591 26.8 591 30C591 33.2 586.711 34 584.567 34L584.557 33.9957V34C546.816 34 320.593 37.8722 243.995 42.5688C125.966 49.8058 139.084 59 1 59V1C139.087 1 125.971 10.1943 244.002 17.4312Z"
    fill="#F7F7F7"
    stroke="#0000001F"
    style={{
      transform: `translate(19px, ${height > 200 ? 161 : 61}px) scaleX(${width > 500 ? 1.65 : 0.78})`,
    }}
  />
);
