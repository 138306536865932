import styled from 'styled-components';
import { Button, Menu } from 'antd';

export const CreateTaskContainer = styled.div`
  padding: 0 1em;
`;

export const CreateTaskButton = styled(Button)`
  padding: 0;
`;

export const StyledMenu = styled(Menu)`
  .anticon {
    font-size: 0.75rem;
    margin-left: 0 !important;
  }

  .ant-menu-item {
    font-size: 0.85rem;
    height: 1.35rem;
    line-height: 1.35rem;
    padding: 0 5px;
  }
`;
