import { useState, useEffect } from 'react';

import { Button, Modal } from 'antd';
import { Tooltip } from 'components/Tooltip';

import BulkEditForm from './BulkEditForm';
import { TEditColumnData } from 'components/data/types';

const DISABLED_BULK_EDIT_MESSAGE =
  'The parents of selected work containers must not be archived to enable editing.';

interface Props {
  records: any[];
  columns: TEditColumnData[];
  requiredFields?: string[];
  onSubmit: (data: any) => void;
  btnDisplay?: boolean;
  disabled?: boolean;
  additionalTooltipContent?: string;
  additionalFooterContent?: React.ReactNode;
}

export const BulkEdit: React.FunctionComponent<Props> = ({
  records,
  columns,
  requiredFields = [],
  onSubmit,
  btnDisplay = true,
  disabled = false,
  additionalTooltipContent = '',
  additionalFooterContent,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  function closeModal() {
    setModalVisible(false);
  }

  useEffect(() => {
    return () => {
      setModalVisible(false);
    };
  }, []);

  return (
    <>
      {btnDisplay ? (
        <Tooltip title={disabled ? DISABLED_BULK_EDIT_MESSAGE : ''}>
          <Button
            id="bulk-edit-button"
            data-testid="tasks-bulk-edit-button"
            onClick={() => setModalVisible(true)}
            disabled={disabled}
          >
            Edit Selected
          </Button>
        </Tooltip>
      ) : (
        <div onClick={() => setModalVisible(true)}>Edit Selected</div>
      )}

      <Modal
        title={`Edit ${records.length} items`}
        visible={modalVisible}
        onCancel={closeModal}
        footer={null}
        destroyOnClose
      >
        <BulkEditForm
          columns={columns}
          records={records}
          requiredFields={requiredFields}
          onSubmit={onSubmit}
          onClose={closeModal}
          additionalTooltipContent={additionalTooltipContent}
          additionalFooterContent={additionalFooterContent}
        />
      </Modal>
    </>
  );
};
