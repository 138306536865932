import { AtlasGqlHorizonDamage, AtlasGqlHorizonDamageObservation } from 'types/atlas-graphql';
import { ComparerPane } from '../ComparerPane';
import { ObservationComparerDetail } from './ObservationComparerDetail';

type ObservationComparerPaneProps = {
  paneSide: 'left' | 'right';
  damage: AtlasGqlHorizonDamage;
  observations: AtlasGqlHorizonDamageObservation[];
  selectedObservationId: string;
  setSelectedObservationId: (id: string) => void;
  disabledObservationIds: string[];
  setRemoveConfirmationOpenForId?: (id: string) => void;
};

export const ObservationComparerPane: React.FunctionComponent<ObservationComparerPaneProps> = ({
  paneSide,
  damage,
  observations,
  selectedObservationId,
  setSelectedObservationId,
  disabledObservationIds,
  setRemoveConfirmationOpenForId,
}) => {
  const selectedObservation: AtlasGqlHorizonDamageObservation | undefined = observations.find(
    ({ id }) => id === selectedObservationId
  );

  return (
    <>
      {selectedObservation && (
        <ComparerPane observation={selectedObservation}>
          <ObservationComparerDetail
            paneSide={paneSide}
            damage={damage}
            observations={observations}
            disabledObservationIds={disabledObservationIds}
            selectedObservation={selectedObservation}
            setSelectedObservationId={setSelectedObservationId}
            setRemoveConfirmationOpenForId={setRemoveConfirmationOpenForId}
          />
        </ComparerPane>
      )}
    </>
  );
};
