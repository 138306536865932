import { useState, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';

import {
  AtlasGqlHorizonDamage,
  AtlasGqlHorizonDamageObservationGroup,
  AtlasGqlPicture,
  Maybe,
  useGetHorizonDamageQuery,
} from 'types/atlas-graphql';
import { usePermissions } from 'utils/usePermissions';
import { EDITOR } from 'utils/access-control/roles';
import { DAMAGE_EDIT_ATTRIBUTES } from 'utils/access-control/rules';

import { useAccountContext } from 'utils/account/AccountContext';
import { TASK_LOCKING, CRITICAL_DAMAGE_VISIBILITY } from 'utils/release-toggles';
import { HorizonDamageImage } from 'horizon/components/Damages/HorizonDamageImage';
import { Asset } from './Asset';
import { Controls } from './Controls';
import { Row, Col } from 'antd';
import {
  SpinnerContainer,
  ExpandedRowContainer,
  StyledRow,
  StyledCol,
  AttributesContainer,
  ButtonContainer,
} from './ExpandedRow.style';
import { DamageComments } from 'horizon/components/Damages/DamageStyles.style';
import { EditAttributesForm } from 'horizon/components/Damages/EditAttributesForm/EditAttributesForm';
import { Attributes } from 'horizon/components/Damages/Attributes/Attributes';
import {
  getObservationGroupByInspectionId,
  getPrimaryObservationGroupForDamage,
} from 'horizon/components/Damages/utils';
import { useDamageAttrs } from 'components/damages/withDamages';
import { Spinner } from 'components/Spinner';
import { useIsInView } from 'utils/hooks';
import { CompareMostRecentImage } from 'components/damages/CompareMostRecentImage';

import { UpdateToPrimaryButton } from 'horizon/components/Damages/UpdateToPrimaryModal';

type ExpandedRowProps = {
  damage?: AtlasGqlHorizonDamage;
  damageId?: string;
  selectedSchema?: string;
  hideDetails?: boolean;
  lazyLoad?: boolean;
  taskId?: string;
  inspectionIdOfGroupToRender?: string;
  mergedDamageId?: string;
  setMergedDamageId?: (id: string | undefined) => void;
};

export const DamageList2ExpandedRow: React.FunctionComponent<ExpandedRowProps> = ({
  damage: horizonDamage,
  damageId,
  selectedSchema,
  hideDetails,
  lazyLoad = false,
  taskId,
  inspectionIdOfGroupToRender,
  mergedDamageId,
  setMergedDamageId,
}) => {
  const rowRef = useRef<HTMLDivElement>(null);
  const isInView: boolean = useIsInView(rowRef);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isRowInView, setIsRowInView] = useState<boolean>(false);
  const [commentsKey, setCommentsKey] = useState<string>(uuid());

  const { HasRole, CanAccess } = usePermissions();
  const isEditor = HasRole({ role: EDITOR });
  const isEditable = CanAccess({ rules: [DAMAGE_EDIT_ATTRIBUTES] }) && !hideDetails;

  const { hasReleaseToggle } = useAccountContext();
  const hasTaskLockingReleaseToggle = hasReleaseToggle(TASK_LOCKING);
  const hasCriticalDamageReleaseToggle = hasReleaseToggle(CRITICAL_DAMAGE_VISIBILITY);

  // get damage data for row if only damageId is provided
  const { data: damageData, loading: loadingDamage } = useGetHorizonDamageQuery({
    variables: { input: { id: damageId } },
    skip: !!horizonDamage || !isRowInView,
  });

  const damage =
    horizonDamage ??
    (damageData?.getHorizonDamage && (damageData.getHorizonDamage as AtlasGqlHorizonDamage));
  const { asset, primaryObservationGroupAttrs: attrs = {} } = damage ?? {};

  const { damageColumns, loading: loadingDamageAttrs } = useDamageAttrs(selectedSchema, true);

  const primaryGroup = getPrimaryObservationGroupForDamage(
    damage
  ) as AtlasGqlHorizonDamageObservationGroup;
  const targetGroup = !!inspectionIdOfGroupToRender
    ? getObservationGroupByInspectionId(damage, inspectionIdOfGroupToRender)
    : primaryGroup;
  const areGroupsDifferent = primaryGroup && targetGroup && primaryGroup.id !== targetGroup.id;

  const attrsToRender =
    (inspectionIdOfGroupToRender ? targetGroup?.groupAttrs : primaryGroup?.groupAttrs) ?? attrs;
  const criticalToRender = inspectionIdOfGroupToRender
    ? targetGroup?.groupCritical
    : primaryGroup?.groupCritical;

  const pictureForComparison = targetGroup?.observations?.find(
    o => o?.id === targetGroup.sourceObservationId
  )?.picture;

  const filteredDamageColumns = damageColumns.filter(column => {
    if (hasCriticalDamageReleaseToggle && column.key === 'Severity') {
      // @ts-ignore
      return column.key in attrsToRender || targetGroup?.groupCritical;
    }
    return column.key in attrsToRender;
  });

  // update component when its damage has been merged
  useEffect(() => {
    if (mergedDamageId && setMergedDamageId) {
      if (mergedDamageId === damage?.id) {
        setCommentsKey(uuid());
        setMergedDamageId(undefined);
      }
    }
  }, [mergedDamageId, setMergedDamageId, damage?.id, setCommentsKey]);

  // render component once its in view for the first time
  useEffect(() => {
    if ((!lazyLoad || isInView) && !isRowInView) {
      setIsRowInView(true);
    }
  }, [lazyLoad, isInView, isRowInView]);

  const onCancel = () => {
    setIsEditing(false);
    rowRef.current?.scrollIntoView();
  };

  if (!damage || loadingDamage || loadingDamageAttrs) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );
  }

  return (
    <ExpandedRowContainer gutter={16} ref={rowRef}>
      {isRowInView && (
        <>
          <StyledCol span={6}>
            <HorizonDamageImage
              damageId={damage.id}
              inspectionIdOfGroupToRender={inspectionIdOfGroupToRender}
            />
          </StyledCol>
          <Col span={18}>
            <StyledRow gutter={16}>
              <StyledCol span={hideDetails ? 24 : 16}>
                <StyledRow gutter={16}>
                  <Col
                    span={12}
                    style={
                      isEditing
                        ? { maxHeight: '500px', display: 'flex', flexDirection: 'column' }
                        : {}
                    }
                  >
                    {isEditable && isEditing ? (
                      <EditAttributesForm
                        onCancel={onCancel}
                        damageId={damage.id}
                        selectedSchema={selectedSchema}
                        observationGroup={primaryGroup}
                        backgroundColor="#fbfbfb"
                      />
                    ) : (
                      <AttributesContainer>
                        <Attributes
                          attrs={attrsToRender}
                          critical={criticalToRender}
                          damageColumns={filteredDamageColumns}
                          layout={{ number: 2, threshold: 7 }}
                        />
                        {hasTaskLockingReleaseToggle && !!taskId && (
                          <ButtonContainer>
                            {!!pictureForComparison && !!targetGroup?.sourceObservationId && (
                              <CompareMostRecentImage
                                picture={pictureForComparison}
                                observationId={targetGroup.sourceObservationId}
                              />
                            )}
                            {isEditor && areGroupsDifferent && (
                              <UpdateToPrimaryButton taskId={taskId} />
                            )}
                          </ButtonContainer>
                        )}
                      </AttributesContainer>
                    )}
                  </Col>
                  <Col span={12}>{asset && <Asset asset={asset} />}</Col>
                </StyledRow>
                {!hideDetails && (
                  <Row>
                    <Col span={24}>
                      <Controls onClickEdit={() => setIsEditing(true)} damage={damage} />
                    </Col>
                  </Row>
                )}
              </StyledCol>
              {!hideDetails && (
                <StyledCol span={8}>
                  <DamageComments key={commentsKey} threadId={primaryGroup.id} loading={false} />
                </StyledCol>
              )}
            </StyledRow>
          </Col>
        </>
      )}
    </ExpandedRowContainer>
  );
};
