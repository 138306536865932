import { chunk, isArray } from 'lodash';
import { QuestionCircleOutlined, WarningTwoTone } from '@ant-design/icons';
import { Tooltip } from 'components/Tooltip';
import { renderColumn, renderWorkContainerField } from 'components/data/helpers';
import {
  Attribute,
  Description,
  HelpTooltip,
  Label,
  LabelContainer,
  StyledSkeleton,
  StyledColumn,
  StyledRow,
} from './DataDescription.style';
import COLORS from 'utils/color/definitions';

import { TColumnAttributes } from 'horizon/types/ColumnAttributes';

// how many attributes to show before we overflow to a new column
const DEFAULT_CHUNK_SIZE: number = 5;
// how many columns to display by default
const DEFAULT_COLUMN_COUNT: number = 3;

const LoadingState = (): JSX.Element => (
  <StyledSkeleton loading active paragraph={false} title={{ width: 100 }} />
);

type TAntColumnAttributes = {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
};

interface IDataDescriptionProps {
  data?: any[] | any;
  attributes: any;
  showLabels?: boolean;
  showHelp?: boolean;
  chunkSize?: number;
  columnProps?: TAntColumnAttributes;
  loading: boolean;
  className?: string;
  // If set to true, ignores the value of chunkSize and splits attributes array into columnCount worth of chunks
  autoChunk?: boolean;
  columnCount?: number;
}

export const DataDescription: React.FunctionComponent<IDataDescriptionProps> = ({
  data,
  attributes,
  showLabels = true,
  showHelp = false,
  chunkSize = DEFAULT_CHUNK_SIZE,
  columnProps,
  loading,
  className,
  autoChunk = false,
  columnCount = DEFAULT_COLUMN_COUNT,
}) => {
  const finalChunkSize = autoChunk ? Math.round(attributes?.length / columnCount) : chunkSize;
  const chunkedAttributes = isArray(attributes[0]) ? attributes : chunk(attributes, finalChunkSize);
  // responsive column layout
  const defaultColumnProps = {
    xs: 6,
    lg: Math.round(18 / chunkedAttributes.length), // adjust the first number in the division to widen or restrict
  };

  return (
    <StyledRow type="flex" justify="start" className={className}>
      {chunkedAttributes.map((columnAttributes: TColumnAttributes[], index: number) => (
        <StyledColumn key={index} {...{ ...defaultColumnProps, ...columnProps }}>
          {columnAttributes.map((attr: TColumnAttributes) => {
            const key = attr?.key || attr?.dataIndex || `key-${index}`;
            return (
              <Attribute key={key}>
                {showLabels && (
                  <LabelContainer>
                    {attr.hasError && (
                      <Tooltip title="Field is required.">
                        <WarningTwoTone twoToneColor={COLORS.RED} />{' '}
                      </Tooltip>
                    )}
                    <Label>
                      {attr.title}
                      {showHelp && attr.help && (
                        <HelpTooltip title={attr.help}>
                          <QuestionCircleOutlined />
                        </HelpTooltip>
                      )}
                    </Label>
                  </LabelContainer>
                )}
                {loading ? (
                  <LoadingState />
                ) : (
                  <Description
                    data-testid={
                      // even though `title` is typed as a string, it may be an
                      // object 🤦‍♂️, so we need to validate it's a string
                      typeof attr.title === 'string'
                        ? `data-value-${attr.title.replace(/:$/, '')}`
                        : `data-value-${key}`
                    }
                  >
                    {attr.customField ? renderWorkContainerField(attr) : renderColumn(attr, data)}
                  </Description>
                )}
              </Attribute>
            );
          })}
        </StyledColumn>
      ))}
    </StyledRow>
  );
};
