import { useState, useEffect } from 'react';
import { message } from 'antd';
import moment from 'moment';
import {
  AtlasGqlHorizonDamage,
  AtlasGqlHorizonDamageObservation,
  AtlasGqlInspection,
  useConnectObservationMutation,
} from 'types/atlas-graphql';
import { ComparerPane } from '../ComparerPane';
import { ComparerContainer, ComparerModal } from '../CompareOTron.style';
import { Parent } from './Parent';
import { Suggestions } from './Suggestions';
import { ConnectionModal } from './ConnectionModal';
import {
  getObservationsForLinking,
  getPrimaryObservationForDamage,
  sortSuggestions,
} from '../../utils';

/**
 * This component and its children are deprecated and will be removed during cleanup of
 * rtv1:new_image_viewer
 *
 * Further maintenance should not be needed, check with Riley with any questions.
 */

type ObservationComparerProps = {
  damage: AtlasGqlHorizonDamage;
  observations: AtlasGqlHorizonDamageObservation[];
  inspections: AtlasGqlInspection[];
  inspectionsToCompare: AtlasGqlInspection[];
  isOpen: boolean;
  onClose: () => void;
};

export const ObservationLinker: React.FunctionComponent<ObservationComparerProps> = ({
  damage,
  observations,
  inspections,
  inspectionsToCompare,
  isOpen,
  onClose,
}) => {
  const [parentObservationId, setParentObservationId] = useState<string | undefined>();
  const [suggestedObservationId, setSuggestedObservationId] = useState<string | undefined>();
  const [connectionModalOpen, setConnectionModalOpen] = useState<boolean>(false);

  const [connectObservation, { loading: connectLoading }] = useConnectObservationMutation({
    onCompleted: () => {
      message.success('Observation successfully connected');
      setConnectionModalOpen(false);
    },
    onError: () => {
      message.error('Error connecting observations - please try again.');
    },
  });

  const primaryObservation = getPrimaryObservationForDamage(damage);

  // suggested observations
  const suggestions = getObservationsForLinking(damage, inspections);
  const suggestionsToCompare = sortSuggestions(
    damage,
    getObservationsForLinking(damage, inspectionsToCompare)
  );

  // reset the parentObservationId and suggestedObservationId
  useEffect(() => {
    if (isOpen) {
      setParentObservationId(primaryObservation?.id);
      setSuggestedObservationId(suggestionsToCompare?.[0]?.id);
    }
  }, [observations, inspectionsToCompare]);

  // parent observation (left pane)
  const parentObservation: AtlasGqlHorizonDamageObservation | null | undefined = observations?.find(
    ({ id }: AtlasGqlHorizonDamageObservation) => id === parentObservationId
  );

  // suggested observation (right pane)
  const suggestedObservation: AtlasGqlHorizonDamageObservation | null | undefined =
    suggestions?.find(({ id }: AtlasGqlHorizonDamageObservation) => id === suggestedObservationId);

  const handleConnectObservation = async () => {
    if (damage && suggestedObservation) {
      const newObservationDate = suggestedObservation.inspection?.inspectionDate;
      const isPrimaryObservationGroup: boolean = !!damage.observationGroups?.every(g =>
        moment(newObservationDate).isAfter(moment(g?.observations?.[0]?.inspection?.inspectionDate))
      );

      await connectObservation({
        variables: {
          connectionInput: {
            damageId: damage.id,
            observationId: suggestedObservation.id,
            isPrimaryObservationGroup: isPrimaryObservationGroup ? true : undefined,
          },
        },
      });
    }
  };

  return (
    <>
      <ComparerModal
        visible={isOpen}
        onCancel={onClose}
        footer={null}
        destroyOnClose
        bodyStyle={{ height: '100%', width: '100%' }}
      >
        <ComparerContainer>
          <ComparerPane observation={parentObservation}>
            <Parent
              damage={damage}
              parentObservation={parentObservation}
              setObservationId={setParentObservationId}
            />
          </ComparerPane>
          <ComparerPane observation={suggestedObservation}>
            <Suggestions
              damage={damage}
              inspections={inspections}
              inspectionsToCompare={inspectionsToCompare}
              suggestedObservation={suggestedObservation}
              setObservationId={setSuggestedObservationId}
              onClickConnect={() => setConnectionModalOpen(true)}
            />
          </ComparerPane>
        </ComparerContainer>
      </ComparerModal>
      <ConnectionModal
        damageId={damage.id}
        observationId={suggestedObservation?.id}
        isOpen={connectionModalOpen}
        loading={connectLoading}
        onConfirm={handleConnectObservation}
        onClose={() => setConnectionModalOpen(false)}
      />
    </>
  );
};
