import { isFinite } from 'lodash';
import { LinkOutlined } from '@ant-design/icons';

import SeverityTag from 'components/SeverityTag';
import { AtlasGqlHorizonDamageObservation } from 'types/atlas-graphql';

import { ListItem } from '../ComparerPane/ComparerPane.style';
import { None } from 'components/data/helpers';
import { formatAttribute } from 'horizon/components/Damages/utils';

export enum ObservationListItemTypes {
  Primary = 'PRIMARY',
  Suggestion = 'SUGGESTION',
}

type ObservationListItemProps = {
  observation: AtlasGqlHorizonDamageObservation;
  selected?: boolean;
  disabled?: boolean;
  linked?: boolean;
  type?: ObservationListItemTypes;
  onClick: () => void;
};

export const ObservationListItem: React.FunctionComponent<ObservationListItemProps> = ({
  observation,
  selected = false,
  disabled = false,
  linked = false,
  type = ObservationListItemTypes.Primary,
  onClick,
}) => {
  const {
    'Blade Side': bladeSide,
    Distance: distance,
    Severity: severity,
    Type: typeAttribute,
  }: {
    'Blade Side'?: string;
    Distance?: number;
    Severity?: number;
    Type?: string;
  } = observation.attrs;
  const hasNoMetadata: boolean = !bladeSide && !isFinite(distance) && !severity && !typeAttribute;
  return (
    <ListItem key={observation.id} selected={selected} disabled={disabled} onClick={onClick}>
      {hasNoMetadata ? (
        <None message="No Metadata" />
      ) : (
        <>
          {type === ObservationListItemTypes.Suggestion && (
            <span style={{ width: '20px' }}>{linked && <LinkOutlined />}</span>
          )}
          {/* Deprecated use of SeverityTag - does not need new critical treatment */}
          <SeverityTag style={{ marginRight: '8px' }} value={severity} />
          {type === ObservationListItemTypes.Primary && (
            <div>
              <p>
                {observation.inspection?.inspectionDate ??
                  observation.attrs.Date ??
                  observation.id.split('-')[0]}
              </p>
              <p className="inspection-detail">
                {[observation.inspection?.vendor?.name, observation.inspection?.type?.name]
                  .filter(Boolean)
                  .join(' - ')}
              </p>
            </div>
          )}
          {type === ObservationListItemTypes.Suggestion && (
            <div>
              <p>{!!typeAttribute && typeAttribute}</p>
            </div>
          )}
          <div>
            {type === ObservationListItemTypes.Primary && !!typeAttribute && <p>{typeAttribute}</p>}
            {
              <p>
                {[
                  isFinite(distance) && distance && `${formatAttribute(distance)}m`,
                  !!bladeSide &&
                    bladeSide
                      .split(' ')
                      .map(s => s[0])
                      .join(''),
                ]
                  .filter(Boolean)
                  .join(' • ')}
              </p>
            }
          </div>
        </>
      )}
    </ListItem>
  );
};
