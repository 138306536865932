import { isEqual, isNil, omitBy, pick } from 'lodash';

import { StorageManager } from 'utils/storage';
import { TDataTableContext } from '../types';
import { TExtensionColumn, TSortableColumn, TTableViewFilters } from './types';
import { AtlasGqlTableView } from 'types/atlas-graphql';

export const updateView = (
  view: AtlasGqlTableView | undefined,
  updateTableView: TDataTableContext['updateTableView'],
  tableViewStorageManager: StorageManager
) => {
  if (view) {
    updateTableView(view);
    tableViewStorageManager.set(view);
  } else {
    updateTableView(undefined);
    tableViewStorageManager.remove();
  }
};

export const mapColumns = ({ key, visible }: TExtensionColumn) => ({
  key,
  visible,
});

export const mapMultiSorts = ({ column, sortDirection }: TSortableColumn) => ({
  key: column.key,
  sort: sortDirection,
});

export const isViewAndTableStateEqual = (
  savedView?: TTableViewFilters,
  tableState?: TTableViewFilters
): boolean => {
  const areFiltersEqual = isEqual(
    omitBy(savedView?.filters ?? {}, isNil),
    omitBy(tableState?.filters ?? {}, isNil)
  );
  const areSortsEqual = isEqual(
    pick(savedView?.sorts ?? {}, ['order', 'columnKey']),
    pick(tableState?.sorts ?? {}, ['order', 'columnKey'])
  );
  const areColumnsEqual = isEqual(
    savedView?.columns ?? [],
    (tableState?.columns ?? []).map(mapColumns)
  );
  const areMultiSortsEqual = isEqual(
    savedView?.multiSorts ?? [],
    (tableState?.multiSorts ?? []).map(mapMultiSorts)
  );

  return areFiltersEqual && areSortsEqual && areColumnsEqual && areMultiSortsEqual;
};
