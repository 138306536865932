import styled from 'styled-components';
import { Button, Collapse, List } from 'antd5';
import COLORS from 'utils/color/definitions';

export const StyledRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    button {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .anticon {
    display: flex;
    height: 1rem;
    width: 1rem;
    margin-right: 0.5rem;

    svg {
      height: 1 rem;
      width: 1rem;
    }
  }
`;

export const StyledButton = styled(Button)`
  visibility: hidden;
  opacity: 0;
  border: none;
  box-shadow: none;
  height: 1.25rem;
  width: 1.25rem;
  background: transparent;
  padding: 0;

  &:hover,
  &:focus,
  &:active {
    background: transparent;
  }

  .anticon {
    display: flex;
    color: inherit;
  }

  svg {
    height: 1.25rem;
    width: 1.25rem;
  }
`;

export const StyledCollapse = styled(Collapse)`
  .ant-collapse-content {
    overflow-y: scroll;
    max-height: 50vh;
  }
`;

export const StyledListContainer = styled.div`
  .ant-list + .ant-list {
    margin-top: 1em;
  }
`;

export const StyledList = styled(List)`
  .ant-list-header {
    font-weight: bold;
    border: none !important;
    padding: 0 !important;
    margin-bottom: 0.25em;
  }
  .ant-spin-container {
    padding-left: 16px;
  }
`;

export const StyledListItem = styled(List.Item)`
  border: none !important;
  padding: 0 !important;

  ul {
    list-style: disc;
    padding-left: 2em;
  }
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.labelColor};
  font-weight: 700;
  margin-right: 8px;
`;

export const Description = styled.span`
  color: ${({ theme }) => theme.descriptionColor};
  font-weight: 400;

  > div {
    padding-left: 16px;
  }
`;

export const StyledOperator = styled.span`
  color: ${COLORS.SECONDARY_TEXT_COLOR};
  margin-right: 8px;
  font-style: italic;
`;
