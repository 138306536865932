import { useState, useEffect, useCallback, useRef, RefObject } from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd5';
import queryString from 'query-string';
import { ApolloQueryResult } from '@apollo/client';
import { useAccountContext } from 'utils/account/AccountContext';
import { useUploadContext } from 'components/FileUploader';

export function useKey({ targetKey, downHandler, upHandler }: any) {
  const [pressed, setPressed] = useState(false);

  const onKeyDown = useCallback(
    ({ key }) => {
      if (Array.isArray(targetKey)) {
        if (targetKey.includes(key)) {
          setPressed(true);
          if (typeof downHandler === 'function') downHandler(key);
        }
        return;
      }
      if (key === targetKey) {
        setPressed(true);
        if (typeof downHandler === 'function') downHandler(key);
      }
    },
    [downHandler, targetKey]
  );

  const onKeyUp = useCallback(
    ({ key }) => {
      if (Array.isArray(targetKey)) {
        if (targetKey.includes(key)) {
          setPressed(false);
          if (typeof upHandler === 'function') upHandler(key);
        }
        return;
      }
      if (key === targetKey) {
        setPressed(false);
        if (typeof upHandler === 'function') upHandler(key);
      }
    },
    [upHandler, targetKey]
  );

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup', onKeyUp);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('keyup', onKeyUp);
    };
  }, [targetKey, downHandler, upHandler, onKeyDown, onKeyUp]);

  return pressed;
}

export function useRefMounted() {
  const mountedRef = useRef(true);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return mountedRef;
}

export function useSafeState<T = any>(
  initialState: T | (() => T)
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const mountedRef = useRefMounted();
  const [state, _setState] = useState<T>(initialState);
  const setState = (nextState: T | (() => T)) => {
    if (mountedRef.current) {
      _setState(nextState);
    }
  };

  return [state, setState];
}

export function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

let uniqueId = 0;
const getUniqueId = (prefix: string) => `${prefix}_${uniqueId++}`;

export function useUniqueId(prefix: string = 'uid') {
  const idRef = useRef(getUniqueId(prefix));
  return idRef.current;
}

export function useInterval(callback: () => void, delay?: number | null) {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

/**
 * Hook that takes a react ref for a component and uses the IntersectionObserver
 * API to determine whether the component is in view and returns a boolean accordingly.
 *
 * Adapted from:
 * https://stackoverflow.com/questions/58341787/intersectionobserver-with-react-hooks/67826055#67826055
 */
export function useIsInView(ref: RefObject<HTMLElement>) {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const [isInView, setIsInView] = useState<boolean>(false);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) => setIsInView(entry.isIntersecting));
  }, []);

  useEffect(() => {
    if (!!ref.current) {
      observerRef.current?.observe(ref.current);
    }

    return () => {
      observerRef.current?.disconnect();
    };
  }, [ref]);

  return isInView;
}

/**
 * Hook that takes a react ref for a component and uses the ResizeObserver
 * API to return the element's bounding rectangle on resize.
 */
export function useResizeObserver(ref: RefObject<HTMLElement>) {
  const observerRef = useRef<ResizeObserver | null>(null);
  const [contentRect, setContentRect] = useState<DOMRectReadOnly>();

  useEffect(() => {
    observerRef.current = new ResizeObserver(([entry]) => {
      setContentRect(entry.contentRect);
    });
  }, []);

  useEffect(() => {
    if (!!ref.current) {
      observerRef.current?.observe(ref.current);
    }

    return () => {
      observerRef.current?.disconnect();
    };
  }, [ref.current]);

  return contentRect;
}

/**
 * This hook is used to determine if the horizon application has focus in the
 * current browser tab/window. Checking the `focused` flag will allow to
 * determine if the user is actively using the application.
 */
export function useIsAppFocused() {
  const [focused, setFocused] = useSafeState<boolean>(true);

  useEffect(() => {
    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);

    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, [setFocused]);

  return focused;
}

interface UseFocusedPollingArgs<T> {
  pollInterval: number; // in milliseconds
  refetch?: () => Promise<ApolloQueryResult<T>>;
  startPolling: (pollInterval: number) => void;
  stopPolling: () => void;
}

/**
 * This hook will allow us to only poll a GQL request when the browser tab or
 * window has focus. This will help prevent requests being made when the user
 * is not actively interacting with the application.
 */
export function useFocusedPolling<T>({
  pollInterval,
  refetch,
  startPolling,
  stopPolling,
}: UseFocusedPollingArgs<T>) {
  const isAppFocused = useIsAppFocused();
  const [lastRefetchInMs, setLastRefetchInMs] = useSafeState<number>(Date.now());

  useEffect(() => {
    if (isAppFocused) {
      const now = Date.now();

      // when refetch has been supplied, we only want to manually refetch when
      // the last time we refetched was greater than the poll interval. this
      // will help prevent making a request every time you bring focus to the
      // browser tab, which can be quite regularly, especially if you are
      // switching back and forth between applications.
      if (refetch && now - lastRefetchInMs > pollInterval) {
        refetch();
        setLastRefetchInMs(now);
      }

      startPolling(pollInterval);
    } else {
      stopPolling();
    }
  }, [
    isAppFocused,
    lastRefetchInMs,
    pollInterval,
    refetch,
    setLastRefetchInMs,
    startPolling,
    stopPolling,
  ]);
}

function useRedirectUrls() {
  const { createHref, location } = useHistory();
  const { pathname, search: lsearch } = location;

  // remove the `/o/:orgId` from the path name, if set
  const pathnameSansOrg = pathname.replace(/^(\/o\/[^/?]+)/, '');
  const redirectUrl = `${pathnameSansOrg}${lsearch}`;
  const query = queryString.stringify({ redirectUrl });

  return {
    resetUrl: createHref({ pathname: '/reset', search: query }),
    // the resetUrl ignores any search/query parameters - will redirect to home after reset
    resetUrlWithoutCustomRedirect: createHref({ pathname: '/reset' }),
    sameOrgRedirectUrl: createHref({ pathname: pathnameSansOrg, search: lsearch }),
  };
}

interface UseChangeCustomerArgs {
  resetWithoutCustomRedirect?: boolean;
  showNotificationOnRedirect?: boolean;
}

/**
 * This hook will return a function that can be called to change to a different
 * customer. This takes into account if there's currently files being uploaded
 * and will prompt the user before changing customer.
 *
 *    ```typescript
 *    // will redirect to `/reset` and then the current pathname
 *    const changeCustomer = useChangeCustomer();
 *
 *    // will redirect to `/reset` and then to `/`, ignoring current pathname or query params
 *    const changeCustomer = useChangeCustomer({ resetWithoutCustomRedirect: true });
 *    ```
 */
export function useChangeCustomer({
  resetWithoutCustomRedirect,
  showNotificationOnRedirect,
}: UseChangeCustomerArgs = {}) {
  const { replace } = useHistory();
  const { customer, customers, setCustomerId } = useAccountContext();
  const { uploading, setConfirmProps } = useUploadContext();
  const { resetUrl, resetUrlWithoutCustomRedirect, sameOrgRedirectUrl } = useRedirectUrls();
  const customerRedirectUrl = resetWithoutCustomRedirect ? resetUrlWithoutCustomRedirect : resetUrl;

  const setCustomerAndRedirect = useCallback(
    (newCustomerId: string) => {
      if (newCustomerId === customer?.id) {
        replace(sameOrgRedirectUrl);
        return;
      }

      const newCustomer = customers.find(c => c.id === newCustomerId);

      if (!newCustomer) {
        replace('/');
        message.error({
          content: 'You do not have access to the requested customer',
          duration: 5,
        });
        return;
      }

      setCustomerId(newCustomerId);
      // navigate to reset which will handle the apollo cache clear
      replace(customerRedirectUrl);

      if (showNotificationOnRedirect) {
        message.info({
          content: `You are now viewing customer "${newCustomer.name}"`,
          duration: 7,
        });
      }
    },
    [
      customer?.id,
      customerRedirectUrl,
      customers,
      replace,
      sameOrgRedirectUrl,
      setCustomerId,
      showNotificationOnRedirect,
    ]
  );

  const changeCustomer = useCallback(
    (newCustomerId: string) => {
      if (uploading) {
        setConfirmProps({
          onOk: () => setCustomerAndRedirect(newCustomerId),
          message: 'You have uploads in progress. Are you sure you want to change customers?',
        });
      } else {
        setCustomerAndRedirect(newCustomerId);
      }
    },
    [setConfirmProps, setCustomerAndRedirect, uploading]
  );

  return changeCustomer;
}
