import { Modal } from 'components/Modal';
import { ModalProps } from 'antd5';

interface ConfirmationModalProps extends ModalProps {
  message: string | JSX.Element;
  loading?: boolean;
}

export const ConfirmationModal: React.FunctionComponent<ConfirmationModalProps> = ({
  message,
  title = 'Are you sure?',
  okText = 'Yes',
  cancelText = 'No',
  loading = false,
  ...props
}) => (
  <Modal
    title={title}
    okText={okText}
    okButtonProps={{ loading, 'data-testid': 'confirmation-modal-ok-button' }}
    cancelText={cancelText}
    {...props}
  >
    {message}
  </Modal>
);
