import { Collapse, List } from 'antd';
import { orderBy } from 'lodash';
import {
  useCreateHorizonDamagePropagationMutation,
  AtlasGqlPropagationType,
  AtlasGqlHorizonDamageObservation,
  AtlasGqlHorizonDamage,
  AtlasGqlHorizonDamageObservationGroup,
} from 'types/atlas-graphql';

import {
  DetailCard,
  DetailContainer,
  InspectionCollapse,
} from '../ComparerPane/ComparerPane.style';
import {
  ObservationListItem,
  ObservationListItemTypes,
} from '../ObservationComparer/ObservationListItem';
import { DamageSummary } from '../DamageComparer/DamageSummary';

const { Panel } = Collapse;

type ParentProps = {
  damage: AtlasGqlHorizonDamage;
  parentObservation: AtlasGqlHorizonDamageObservation | undefined;
  setObservationId: (id: string | undefined) => void;
};

export const Parent: React.FunctionComponent<ParentProps> = ({
  damage,
  parentObservation,
  setObservationId,
}) => (
  <DetailContainer>
    <DetailCard title={'Parent Damage Details'} size="small">
      <DamageSummary damage={damage} />
    </DetailCard>
    <DetailCard title={'Observations'} size="small" width="33%">
      <InspectionCollapse>
        {orderBy(
          damage.observationGroups ?? [],
          [og => new Date(og?.observations?.[0]?.inspection?.inspectionDate ?? '')],
          ['desc']
        ).map(({ observations }: AtlasGqlHorizonDamageObservationGroup, key) => {
          const inspection = observations?.[0]?.inspection;
          const sortedObservations = orderBy(
            observations,
            [observation => observation?.attrs?.Severity ?? 0],
            ['desc']
          );

          return (
            <Panel
              header={
                <div>
                  <p>{inspection?.inspectionDate}</p>
                  <p className="inspection-detail">
                    {[inspection?.vendor?.name, inspection?.type?.name].filter(Boolean).join(' - ')}
                  </p>
                </div>
              }
              key={key}
            >
              <List
                size="small"
                dataSource={sortedObservations}
                renderItem={(o: AtlasGqlHorizonDamageObservation) => (
                  <ObservationListItem
                    key={o.id}
                    observation={o}
                    selected={o.id === parentObservation?.id}
                    onClick={() => setObservationId(o.id)}
                    type={ObservationListItemTypes.Suggestion}
                  />
                )}
              />
            </Panel>
          );
        })}
      </InspectionCollapse>
    </DetailCard>
  </DetailContainer>
);
