import { useState } from 'react';

import { identity } from 'lodash';
import { Button } from 'antd';

import { AssetChooser } from 'components/choosers';
import { useManagedAssetChooser } from 'components/choosers/AssetChooser/useManagedAssetChooser';
import {
  numberAndConfirmed,
  isConfirmed,
  taskStatus,
  assetColumn,
  assetTypeColumn,
  siteAtlas,
  assetStatusColumn,
} from 'components/damages/static';
import { DamageTable } from 'components/DamageTable';
import { ExportReport } from 'components/ExportReport';
import { SchemaSelect } from 'components/damages/DamageSchema/SchemaSelect';
import damageListQuery from 'queries/DamageList.atlas.gql';
import { SelectableAction, useSelectableRecords } from 'utils/selectable';
import { FlexContainer } from 'utils/layouts/containers';
import { TABLE_FILTER_OPERATORS } from 'utils/constants';
import { usePermissions } from 'utils/usePermissions';
import { DAMAGE_TABLE_STORAGE_KEY } from 'components/DataTable/Views/helpers/tableStorageKeys';
import { storage } from 'utils/storage';

import { TQueryFilter } from 'components/DataTable/types';
import { TDataQueryChangeArgs } from './types';
import {
  AtlasGqlDamage,
  AtlasGqlDamageListQuery,
  AtlasGqlReportContext,
} from 'types/atlas-graphql';

import { generateDamageTableFilters } from './utils';
import { CreateTasksFromDamagesModal } from './CreateTasksFromDamagesModal';

type IDamageListProps = {
  tableId?: string;
  title: string;
  critical: boolean;
  pagination?: number;
  defaultActiveTabKey?: string;
  damageIds?: string[];
  controls?: boolean;
  externalLoading?: boolean;
};

export const DamageList: React.FunctionComponent<IDamageListProps> = ({
  tableId = 'damage-list-table',
  title = '',
  critical,
  pagination,
  defaultActiveTabKey = critical ? 'UNCONFIRMED' : 'ALL',
  damageIds,
  controls = true,
  externalLoading = false,
}) => {
  const { HasRole } = usePermissions();
  const [filterBy, setFilterBy] = useState<TQueryFilter[]>([]);
  const [totalCount, setTotalCount] = useState<number>();
  const [selectedSchema, setSelectedSchema] = useState<string>();
  const [createTasksFromDamagesModalOpen, setCreateTasksFromDamagesModalOpen] = useState(false);

  const { selected = [], rowSelection, onClearSelection } = useSelectableRecords<AtlasGqlDamage>();
  const {
    onChange: handleAssetChooserChange,
    locationIds,
    turbineIds,
    loadState,
  } = useManagedAssetChooser({
    componentId: `damage-table-asset-picker`,
    storage,
  });

  const canBulkCreateTasks = !HasRole({ role: 'role-readOnly' });

  // NOTE: these are necessary to synchronize the report export params with what is going on in the damage table
  const handleDataQueryChange = (args: TDataQueryChangeArgs) => {
    const { filterBy = [], totalCount = 0 } = args;
    setFilterBy(filterBy);
    setTotalCount(totalCount);
  };

  const allColumns = [
    { ...numberAndConfirmed, defaultFilterOperator: TABLE_FILTER_OPERATORS.CONTAINS },
    isConfirmed,
    taskStatus,
    ...[siteAtlas, assetColumn, assetTypeColumn, assetStatusColumn],
  ].filter(identity);

  const transformQueryResponse = (data: AtlasGqlDamageListQuery) => {
    if (!data.damages) {
      return { totalCount: 0, items: [] };
    }
    const { totalCount, items } = data.damages;
    return { totalCount, items };
  };

  const universalFilters: TQueryFilter[] = generateDamageTableFilters({
    schemaId: selectedSchema,
    critical,
    siteIds: locationIds,
    turbineIds,
    damageIds,
  });

  const createTasksForSelectedDamages = (selected: AtlasGqlDamage[]) => {
    if (selected.length) {
      setCreateTasksFromDamagesModalOpen(true);
    }
  };

  const damageReportArgs = [
    {
      key: 'filterBy',
      value: JSON.stringify([
        ...(filterBy || []),
        ...(universalFilters || []),
        ...(selected?.length
          ? [
              {
                key: 'id',
                operator: 'EQUALS',
                values: selected.map(({ id }) => id),
              },
            ]
          : []),
      ]),
    },
  ];

  // wait until loading is done and schema is selected before querying
  const skipQuery = controls && !selectedSchema;
  const loading = externalLoading || skipQuery;

  return (
    <>
      <DamageTable
        id={tableId}
        title={title}
        loading={loading}
        dataQuery={damageListQuery}
        onDataQueryChange={handleDataQueryChange}
        transform={transformQueryResponse}
        routeProps={{
          universalFilter: universalFilters,
          skip: skipQuery,
        }}
        showColumnPicker={true}
        storageKey={DAMAGE_TABLE_STORAGE_KEY}
        columns={allColumns}
        additionalDropdownActions={
          canBulkCreateTasks
            ? [
                {
                  disabled: selected.length === 0,
                  content: 'Create Tasks',
                  onClick: () => createTasksForSelectedDamages(selected),
                },
              ]
            : []
        }
        actions={
          <FlexContainer>
            <SelectableAction selected={selected} onClear={onClearSelection} />
            <Button.Group>
              {!critical && (
                <ExportReport
                  reportContexts={[
                    {
                      context: AtlasGqlReportContext.Damage,
                      reportArguments: [
                        {
                          args: damageReportArgs,
                          itemCount: selected?.length || totalCount,
                        },
                      ],
                    },
                  ]}
                  disabled={false}
                  cacheKey="damage-list"
                />
              )}
            </Button.Group>
          </FlexContainer>
        }
        controls={
          controls ? (
            <>
              <SchemaSelect
                style={{
                  width: '180px',
                  marginRight: '1em',
                }}
                selected={selectedSchema}
                setSelected={setSelectedSchema}
                storageKey={`${tableId}-schema-select`}
              />
              <AssetChooser
                width={'50%'}
                onChange={handleAssetChooserChange}
                bladeIds={[]}
                turbineIds={turbineIds}
                locationIds={locationIds}
                callerManagedState={true}
                settings={{ blade: { visible: false } }}
                small={false}
                block={false}
                loadState={loadState}
              />
            </>
          ) : undefined
        }
        selected={selected}
        onClearSelection={onClearSelection}
        rowSelection={rowSelection}
        critical={critical}
        pagination={pagination}
        defaultActiveTabKey={defaultActiveTabKey}
        schema={selectedSchema}
      />
      <CreateTasksFromDamagesModal
        open={createTasksFromDamagesModalOpen}
        setOpen={setCreateTasksFromDamagesModalOpen}
        damages={selected}
      />
    </>
  );
};
