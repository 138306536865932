import { useState, useEffect } from 'react';
import { Input, Card } from 'antd';
import { FilterConfirmProps } from 'antd/lib/table/interface';

type IFilterDropDownProps = {
  defaultValue: string;
  confirm: (param?: FilterConfirmProps) => void;
  clearFilters(): void;
  selectedKeys: string[];
  setSelectedKeys(selectedKeys: string[]): any;
  visible?: boolean;
};

export const FilterDropdown: React.FunctionComponent<IFilterDropDownProps> = ({
  defaultValue,
  confirm,
  clearFilters,
  selectedKeys,
  setSelectedKeys,
  visible,
}) => {
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    // When dropdown becomes visible set our value to the selectedKeys.
    if (visible) {
      // If the selectedKeys is empty: either first time it's been opened OR we've reset our filters and need to reset our value
      setValue(selectedKeys[0] ?? '');
    }
  }, [visible, selectedKeys]);

  useEffect(() => {
    // defaultValue is null if HoC queries haven't loaded data yet
    // this ensures that defaultValue is used, even if the component already
    // renders with a null value
    if (defaultValue && !value) {
      setValue(defaultValue);
    }
  }, [defaultValue, value]);

  // e is typed as any here because Antd requires a specific shape for the input but doesn't define a public type for what that shape is.
  const handleChange = (e: any) => {
    const newValue: string = [e.target.value].find(Boolean) ?? '';
    setValue(newValue);
    setSelectedKeys([newValue]);
  };

  const handleExit = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key.match('Escape')) {
      setValue('');
      clearFilters();
    }
  };

  const handleSearch = () => {
    if (value && value.length > 0) {
      confirm({ closeDropdown: true });
    } else {
      setValue('');
      clearFilters();
    }
  };

  return (
    <Card onKeyUp={handleExit} bodyStyle={{ padding: '.75rem' }}>
      <Input.Search
        enterButton
        autoFocus
        onChange={handleChange}
        onSearch={handleSearch}
        placeholder="Search"
        value={value}
      />
    </Card>
  );
};
