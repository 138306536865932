import { useState, FC, ChangeEvent, KeyboardEventHandler } from 'react';
import { Form, Button, Input } from 'antd5';
import styled, { css } from 'styled-components';
import UserAvatar from 'components/UserAvatar';
import Comment from './Comment';
import { useAuthContext } from 'utils/auth';

const AddCommentWrapper = styled.div<{ columnLayout?: boolean }>`
  ${({ columnLayout }) =>
    columnLayout
      ? css`
          order: -1;
          flex: 0 0 50%;
          margin-right: 2rem;
        `
      : ''};
`;

const stopPropagation: KeyboardEventHandler = e => e.stopPropagation();

interface EditorProps {
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onSubmit: () => void;
  submitting?: boolean;
  value: string;
}

const Editor: FC<EditorProps> = ({ onChange, onSubmit, submitting, value }) => (
  <>
    <Form.Item>
      <Input.TextArea
        data-testid="comment-text-input"
        id="comment-text-input"
        rows={2}
        onChange={onChange}
        onKeyDown={stopPropagation}
        onKeyUp={stopPropagation}
        value={value}
      />
    </Form.Item>

    <Form.Item>
      <Button
        id="add-comment-button"
        htmlType="submit"
        loading={submitting}
        onClick={onSubmit}
        disabled={!value.trim().length}
      >
        Add Comment
      </Button>
    </Form.Item>
  </>
);

interface AddCommentProps {
  onSave: (text: string) => void;
  columnLayout?: boolean;
}

const AddComment: FC<AddCommentProps> = ({ onSave, columnLayout }) => {
  const { user } = useAuthContext();
  const [newComment, setNewComment] = useState<string>('');

  const handleSave = () => {
    onSave(newComment);
    setNewComment('');
  };

  return (
    <AddCommentWrapper columnLayout={columnLayout}>
      <Comment
        avatar={user && <UserAvatar user={user} />}
        content={
          <Editor
            onChange={e => setNewComment(e.target.value)}
            onSubmit={handleSave}
            value={newComment}
          />
        }
      />
    </AddCommentWrapper>
  );
};

export default AddComment;
