import { Divider } from 'antd';
import { clone, isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { getUniqueIdentifier } from 'horizon/components/Assets/AssetDetails/helpers';
import { None } from 'components/data/helpers';
import {
  AtlasGqlAsset,
  AtlasGqlAssetFieldValueBool,
  AtlasGqlAssetFieldValueDate,
  AtlasGqlAssetFieldValueFloat,
  AtlasGqlAssetFieldValueMultiSelect,
  AtlasGqlAssetFieldValueNumber,
  AtlasGqlAssetFieldValueSelect,
  AtlasGqlAssetFieldValueText,
} from 'types/atlas-graphql';
import {
  getAssetByType,
  getStrippedUniqueIdLabel,
} from 'horizon/components/Assets/AssetDetails/helpers';
import { ASSET_TYPE_BLADE, ASSET_TYPE_GENERIC } from 'utils/constants';

type AssetDetailsTab =
  | 'components'
  | 'inspections'
  | 'inactivecomponents'
  | 'damages'
  | 'damages2'
  | 'blades'
  | 'tasks'
  | 'attachments'
  | 'details'
  | 'map'
  | 'alerts'
  | 'history';

export const getSiteColumnCopy = (asset: AtlasGqlAsset): string => {
  let site: AtlasGqlAsset | undefined;

  if (asset?.assetType?.name === 'Site') {
    site = asset;
  } else {
    site = asset?.ancestors?.find(a => a?.assetType?.name === 'Site');
  }
  const siteName = site?.uniqueIdLabel ? getStrippedUniqueIdLabel(site) : getUniqueIdentifier(site);

  return siteName ?? '';
};

export const getColumnName = (asset?: AtlasGqlAsset): string => {
  const assetName = asset?.uniqueIdLabel
    ? getStrippedUniqueIdLabel(asset)
    : getUniqueIdentifier(asset);
  return assetName ?? '';
};

export const renderAssetSite = (asset: AtlasGqlAsset): JSX.Element => {
  const siteAsset = getAssetByType(asset, 'Site');
  const siteName = getColumnName(siteAsset);

  return siteAsset && !isEmpty(siteName) ? (
    <Link to={`/assets/${siteAsset.id}`}>{siteName}</Link>
  ) : (
    <None />
  );
};

export const renderAssetTurbine = (asset: any, destinationTab?: AssetDetailsTab): JSX.Element => {
  if (asset?.ancestors) {
    const turbineAsset = getAssetByType(asset, 'Turbine');
    const turbineName = getColumnName(turbineAsset);

    return turbineAsset ? (
      <Link to={`/assets/${turbineAsset.id}/${destinationTab ?? ''}`}>{turbineName}</Link>
    ) : (
      <None />
    );
  }

  let turbineData = null;
  if (asset?.__typename === 'Turbine') {
    turbineData = asset;
  }
  // JD - This resolves data for any asset type whose direct parent is the turbine.
  // It won't resolve properly for anything farther down the heirarchy than a direct child.
  if ([ASSET_TYPE_GENERIC, ASSET_TYPE_BLADE].includes(asset?.__typename)) {
    turbineData = asset?.parent || asset?.turbine;
  }

  return turbineData ? (
    <Link to={`/turbines/${asset.id}/${destinationTab ?? ''}`}>{turbineData.name}</Link>
  ) : (
    <None />
  );
};

export const getOrderedAncestors = (asset: AtlasGqlAsset | null | undefined): AtlasGqlAsset[] => {
  return clone(asset?.ancestors ?? []).reverse();
};

export const getAssetColumnCopy = ({
  asset,
  includeSite = false,
  separatorText,
}: {
  asset: AtlasGqlAsset | null | undefined;
  includeSite?: boolean;
  separatorText?: string;
}): string =>
  getOrderedAncestors(asset)
    .filter(a => (includeSite ? true : a?.assetType?.name !== 'Site'))
    .map(a => getUniqueIdentifier(a))
    .concat(getUniqueIdentifier(asset))
    .join(separatorText ?? ' ');

export const renderAssetName = (asset: AtlasGqlAsset | null | undefined): JSX.Element => {
  return asset ? (
    <Link to={`/assets/${asset?.id}`}>{asset?.uniqueIdLabel ?? getUniqueIdentifier(asset)}</Link>
  ) : (
    <None />
  );
};

export const renderAssetNameWithAncestors = (
  asset: AtlasGqlAsset | null | undefined,
  destinationTab?: AssetDetailsTab
): JSX.Element => {
  if (asset?.assetType?.name === 'Site') {
    return <None />;
  }

  const isTurbine = asset?.assetType?.name === 'Turbine';
  // 'damages' is already the default tab for child components so we
  // don't want to override the default here
  const useDefault = !isTurbine && destinationTab && destinationTab === 'damages';

  return (
    <>
      {getOrderedAncestors(asset)
        .filter(a => a?.assetType?.name !== 'Site')
        .map(a => (
          <span key={a?.id}>
            <Link to={`/assets/${a?.id}/${destinationTab ?? ''}`}>
              {asset && getUniqueIdentifier(a)}
            </Link>
            <Divider type="vertical" />
          </span>
        ))}
      <Link to={`/assets/${asset?.id}/${useDefault ? '' : destinationTab}`}>
        {asset && (asset?.uniqueIdLabel ?? getUniqueIdentifier(asset))}
      </Link>
    </>
  );
};

/**
 * Given an AtlasGqlAsset and the name of an attribute, returns the asset's value for that attribute,
 * if it exists
 *
 * @param asset
 * @param fieldName
 * @returns asset's value for fieldName, if it exists, else undefined
 */
export const getAssetFieldValue = (asset: AtlasGqlAsset, fieldName: string) => {
  const fieldValue = asset.fieldValues?.find(v => v?.fieldDefinition.name === fieldName);
  return (
    (fieldValue as AtlasGqlAssetFieldValueBool)?.boolValue ??
      (fieldValue as AtlasGqlAssetFieldValueDate)?.dateValue ??
      (fieldValue as AtlasGqlAssetFieldValueFloat)?.floatValue ??
      (fieldValue as AtlasGqlAssetFieldValueMultiSelect)?.multiSelectValue,
    (fieldValue as AtlasGqlAssetFieldValueNumber)?.numberValue ??
      (fieldValue as AtlasGqlAssetFieldValueSelect)?.selectValue ??
      (fieldValue as AtlasGqlAssetFieldValueText)?.textValue
  );
};
