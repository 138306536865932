import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { usePermissions } from 'utils/usePermissions';
import {
  DAMAGE_CONFIRM,
  DAMAGE_DELETE,
  DAMAGE_EDIT_ATTRIBUTES,
  TASK_CREATE,
} from 'utils/access-control/rules';

import { CreateTask } from 'components/CreateTask/CreateTask';
import { ConfirmDamage } from 'horizon/components/Damages/ConfirmDamage';
import { DeleteDamage } from 'horizon/components/Damages/DeleteDamage';
import { AtlasGqlHorizonDamage } from 'types/atlas-graphql';
import { ControlsContainer } from './ExpandedRow.style';
import { getPrimaryObservationGroupForDamage } from 'horizon/components/Damages/utils';

type ControlsProps = {
  damage: AtlasGqlHorizonDamage;
  onClickEdit: React.MouseEventHandler<HTMLElement> | undefined;
};

export const Controls: React.FunctionComponent<ControlsProps> = ({ damage, onClickEdit }) => {
  const { CanRender } = usePermissions();

  return (
    <ControlsContainer>
      <CanRender rules={[DAMAGE_EDIT_ATTRIBUTES]}>
        <Button
          data-testid="damages2-table-edit-button"
          icon={<EditOutlined />}
          onClick={onClickEdit}
        >
          Edit
        </Button>
      </CanRender>
      <CanRender rules={[DAMAGE_CONFIRM]}>
        <ConfirmDamage damage={damage} />
      </CanRender>
      <CanRender rules={[TASK_CREATE]}>
        <CreateTask
          horizonDamageId={damage.id}
          inspectionId={getPrimaryObservationGroupForDamage(damage)?.inspectionId}
          assetId={damage.asset?.id}
          useMenuModal
        />
      </CanRender>
      <CanRender rules={[DAMAGE_DELETE]}>
        <DeleteDamage damage={damage} />
      </CanRender>
    </ControlsContainer>
  );
};
