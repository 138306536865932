import { Select } from 'antd';
import { Tooltip } from 'components/Tooltip';

import { ServiceRequest } from './types';
import { useGetTurbineExternalServiceRequestNumbersQuery } from 'types/atlas-graphql';
import { QuestionCircleFilled } from '@ant-design/icons';
import styled from 'styled-components';

const FlexContainer = styled.div`
  display: flex;
`;

interface IExternalServiceRequestChooserProps {
  assetId: string;
  onChange?: any;
  disableForMultipleTurbines?: boolean;
}

export const ExternalServiceRequestChooser: React.FunctionComponent<
  IExternalServiceRequestChooserProps
> = ({ assetId, onChange, disableForMultipleTurbines }) => {
  const placeholder = 'Select Service Request Number';

  const { data, loading } = useGetTurbineExternalServiceRequestNumbersQuery({
    fetchPolicy: 'no-cache',
    skip: !assetId,
    variables: { assetId: assetId ?? '' },
  });

  const tooltip = disableForMultipleTurbines
    ? 'Work Order contains tasks for multiple turbines. Ensure tasks are limited to a single turbine'
    : assetId !== '' && data?.asset?.externalServiceRequestNumbers?.serviceRequests?.length === 0
      ? `No active Service Requests available for ${data?.asset.name}`
      : data?.asset?.externalServiceRequestNumbers?.success === false
        ? data?.asset?.externalServiceRequestNumbers?.message
        : assetId === ''
          ? 'To enable, select tasks for a single turbine'
          : '';

  return (
    <FlexContainer>
      <Select
        loading={loading}
        optionFilterProp="children"
        onChange={value => onChange(value, data?.asset.name)}
        placeholder={placeholder}
        showSearch
        style={{ minWidth: '15rem' }}
        dropdownMatchSelectWidth={false}
        disabled={tooltip !== '' || assetId === ''}
      >
        {!loading && (
          <>
            {data?.asset?.externalServiceRequestNumbers?.serviceRequests?.length > 0 &&
              data?.asset?.externalServiceRequestNumbers?.serviceRequests?.map(
                (serviceRequest: ServiceRequest) => (
                  <Select.Option key={serviceRequest.sr} value={serviceRequest.sr}>
                    {`${serviceRequest.sr} | ${serviceRequest.status} | ${serviceRequest.summary}`}
                  </Select.Option>
                )
              )}
          </>
        )}
      </Select>
      <span style={{ marginRight: '0.4rem' }}></span>
      <Tooltip title={tooltip}>
        <QuestionCircleFilled style={{ marginRight: '0.4rem' }} />
      </Tooltip>
    </FlexContainer>
  );
};
