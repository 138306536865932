import queryString from 'query-string';
import {
  isObject,
  isEmpty,
  isArray,
  isNil,
  isNumber,
  isString,
  pickBy,
  flatten,
  groupBy,
} from 'lodash';
import moment from 'moment';

import { isDate } from 'components/data/helpers';
import { TABLE_FILTER_OPERATORS } from 'utils/constants';
import { formatDate } from 'utils/format';

export const getFilterBy = ({ filters, columns = [] }) => {
  return Object.entries(filters ?? {})
    .filter(([, filter]) => filter?.length)
    .reduce((acc, [key, values]) => {
      /**
       * Some columns control filters for multiple properties, e.g. damage severity and critical
       * status. See ./README for detail.
       *
       * In these cases, the filters are grouped by property here, flattened, and formatted
       * for graphQL queries as normal.
       * Otherwise, filters are parsed and turned into graphQL inputs.
       */
      if (values[0].hasOwnProperty('key')) {
        // group multi-property filters by filter name and flatten for parsing
        const valuesByKey = Object.entries(groupBy(values, 'key')).map(([k, v]) => [
          k,
          flatten(v.map(({ value }) => value)),
        ]);

        return [...acc, ...flatten(valuesByKey.map(([k, v]) => _getFilterBy(k, v, columns)))];
      } else {
        // default case - "normal" filters
        return [...acc, ..._getFilterBy(key, values, columns)];
      }
    }, []);
};

const _getFilterBy = (key, _values, columns) => {
  let values = _values;
  const column = getColumn(key);
  if (column?.serverSideFilterParsers?.[key]) {
    values = _values.map(column.serverSideFilterParsers[key]);
  }

  // will flatten out nested range values
  const parsedValues = flatten(values);

  // handle range filtering
  if (
    parsedValues.every(v => isNumber(v) || isDate(v) || isNil(v) || isEmpty(String(v).trim())) &&
    !parsedValues.every(v => [' ', ''].includes(String(v)))
  ) {
    let [min, max, emptyStringNoneFilter] = parsedValues;
    const dateType = parsedValues.every(v => isDate(v));

    if (dateType) {
      const exclusiveMax = formatDate(moment(String(max))?.add(1, 'day'));
      if (exclusiveMax) {
        max = exclusiveMax;
      }
    }

    return [
      min && {
        key,
        values: [String(min), !isNil(emptyStringNoneFilter) && ''].filter(isString),
        operator: TABLE_FILTER_OPERATORS.GREATER_THAN_OR_EQUAL,
      },
      max && {
        key,
        values: [String(max), !isNil(emptyStringNoneFilter) && ''].filter(isString),
        operator: dateType
          ? TABLE_FILTER_OPERATORS.LESS_THAN
          : TABLE_FILTER_OPERATORS.LESS_THAN_OR_EQUAL,
      },
    ].filter(Boolean);
  }

  function getColumn(key) {
    return columns.find(col => col.key === key || col.allKeys?.includes(key));
  }

  function getFilterValues(filterValues) {
    return filterValues.filter(value => !Object.values(TABLE_FILTER_OPERATORS).includes(value));
  }

  function getFilterOperator(key, filterValues) {
    const [firstFilterValue] = filterValues;
    if (Object.values(TABLE_FILTER_OPERATORS).includes(String(firstFilterValue))) {
      return firstFilterValue;
    }
    const { defaultFilterOperator } = getColumn(key) || {};
    return defaultFilterOperator || TABLE_FILTER_OPERATORS.EQUALS;
  }

  return [
    {
      key,
      values: getFilterValues(values).map(v => v.trim()),
      operator: getFilterOperator(key, values),
    },
  ];
};

export const getSortBy = sorter => {
  return sorter && sorter.columnKey
    ? [
        {
          // only sort generic attrs server-side
          key: sorter.columnKey,
          sort: sorter.order === 'descend' ? 'DESC' : 'ASC',
        },
      ]
    : [];
};

export const getMultiSortBy = multiSorter => {
  return (
    multiSorter?.map(sortOption => {
      return {
        key: sortOption?.column?.key,
        sort: sortOption?.sortDirection,
      };
    }) ?? []
  );
};

export const getUrlParams = location => {
  const [, search] = (location?.search ?? location?.pathname ?? '').split('?');
  const urlParams = queryString.parse(`?${search}`);

  return Object.entries(urlParams).reduce((acc, [key, param]) => {
    if (!param) return acc;
    return { ...acc, [key]: JSON.parse(param) };
  }, {});
};

// recursively removes keys that are empty or falsy
const removeEmptyValues = value => {
  if (isObject(value) && isEmpty(value)) return;
  if (isArray(value)) return value.map(removeEmptyValues);
  if (isObject(value)) {
    return pickBy(
      Object.entries(value).reduce(
        (acc, [key, innerValue]) => ({
          ...acc,
          [key]: removeEmptyValues(innerValue),
        }),
        {}
      )
    );
  }
  return value;
};

export const setUrlParams = (params = {}, location, history) => {
  if (!(params && location && history)) return;

  // remove falsy values and stringify to maintain types
  const cleansedParams = Object.entries(params).reduce((acc, [key, value]) => {
    const cleansedValue = removeEmptyValues(value);

    if (!cleansedValue || (isObject(value) && isEmpty(cleansedValue))) return acc;

    return {
      ...acc,
      [key]: JSON.stringify(cleansedValue),
    };
  }, {});

  history.replace({
    ...location,
    search: queryString.stringify(cleansedParams),
  });
};
