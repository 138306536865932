import { useContext, useEffect, useState } from 'react';
import { DownOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Menu, Dropdown, message } from 'antd';

import { CreateTaskContainer, CreateTaskButton, StyledMenu } from './CreateTask.style';
import { useImageViewerContext } from 'horizon/components/Images/ImageViewer/ImageViewerContext';
import { ImageNavButtonDisabledContext } from 'components/damages/DamageModal/ImageNavButton.Context';

import { DetailedTaskForm } from 'horizon/components/DetailedTaskForm/DetailedTaskForm';
import { REPAIR_TASK, INSPECT_TASK } from 'utils/constants';
import { useFeatures } from 'utils/features';
import { ICreateTaskProps, ISaveTaskInput } from './types';
import { TaskTemplatesChooser } from '../../horizon/routes/WorkOrders/Tasks/TaskTemplates/TaskTemplatesChoosers';
import { getRefetchQueriesForTaskCreation, getTaskTargetInput, messageContent } from './helpers';
import {
  AtlasGqlTaskStatus,
  AtlasGqlWorkContainerTypeOption,
  useCreateTaskFromTemplateMutation,
  useCreateTaskMutation,
} from 'types/atlas-graphql';
import { getTaskKeyFromType } from 'utils/helpers';
import { IMenuClickevent } from 'utils/types';
import { TaskFromTemplateForm } from 'horizon/routes/WorkOrders/Tasks/TaskTemplates/TaskFromTemplateForm/TaskFromTemplateForm';

const DETAIL = 'DETAIL';
const REPAIR = 'REPAIR';
const INSPECT = 'INSPECT';

export const CreateTask: React.FunctionComponent<ICreateTaskProps> = ({
  className = '',
  damageId,
  forOrganization,
  damageBranchId,
  horizonDamageId,
  inspectionId,
  assetId = '',
  type = 'button',
  useMenuModal,
  ...props
}) => {
  const { setNavigationDisabled } = useImageViewerContext();
  const { setDisableShortcutKeys } = useContext(ImageNavButtonDisabledContext);

  const queryRefetch = getRefetchQueriesForTaskCreation({ horizonDamageId, damageBranchId });

  const [createTask] = useCreateTaskMutation({
    //@ts-ignore JTZ: createTask can be null
    onCompleted({ createTask: { id, number, __typename } }) {
      message.success(messageContent({ id, number: number ?? 0, type: __typename ?? 'Task' }));
      setSaving(false);
    },
    onError: () => {
      setSaving(false);
    },
    refetchQueries: queryRefetch,
  });

  const [createTaskFromTemplate] = useCreateTaskFromTemplateMutation({
    onCompleted({ createTaskFromTemplate: { id, number, __typename } }) {
      message.success(messageContent({ id, number: number ?? 0, type: __typename ?? 'Task' }));
    },
    refetchQueries: queryRefetch,
  });

  const [saving, setSaving] = useState(false);
  const [dropDownMenu, setDropDownMenu] = useState<JSX.Element>();
  const [isTemplate, setIsTemplate] = useState(false);
  const [templateId, setTemplateId] = useState('');
  const [visible, setVisible] = useState(false);
  const [modalType, setModalType] = useState<undefined | string>(undefined);

  const { WORK_ORDERS, TASK_TEMPLATES } = useFeatures().features;

  const saveTask = async (input: ISaveTaskInput) => {
    const { key } = input;
    setSaving(true);
    // @ts-ignore
    const taskKey: AtlasGqlWorkContainerTypeOption = getTaskKeyFromType(key);
    await createTask({
      variables: {
        input: {
          damageId,
          damageBranchId,
          ...(forOrganization && { forOrganization }),
          ...(horizonDamageId ? { horizonDamageId } : {}),
          assetId,
          type: taskKey,
          status: AtlasGqlTaskStatus.Pending,
          ...(inspectionId || horizonDamageId
            ? { targets: getTaskTargetInput({ inspectionId, horizonDamageId }) }
            : {}),
        },
      },
    });
  };

  function handleClose() {
    setNavigationDisabled(false);
    setDisableShortcutKeys(false);
    setIsTemplate(false);
    setVisible(false);
  }

  function handleTemplatesMenuItemClick(e: IMenuClickevent) {
    setIsTemplate(true);
    // Assume a key format of GROUP-NAME_TEMPLATE-ID
    const id = e.key.toString().split('_')[1];
    setTemplateId(id);
    if (!assetId) {
      setVisible(true);
    } else {
      createTaskFromTemplate({
        variables: {
          input: {
            id: id,
            damageBranchId,
            damageId,
            ...(horizonDamageId ? { horizonDamageId } : {}),
            assetId,
            ...(inspectionId || horizonDamageId
              ? { targets: getTaskTargetInput({ inspectionId, horizonDamageId }) }
              : {}),
          },
        },
      });
    }
  }

  function handleRootMenuItemClick(e: IMenuClickevent) {
    const splitKey = e?.key?.toString().split('-');
    if (splitKey[0] === DETAIL) {
      setNavigationDisabled(true);
      setDisableShortcutKeys(true);
      setModalType(splitKey[1]);
      setVisible(true);
    } else {
      saveTask({ key: e?.key });
    }
  }

  useEffect(() => {
    if (type === 'menu') {
      setDropDownMenu(
        <StyledMenu onClick={e => handleRootMenuItemClick(e)}>
          <Menu.Item {...props} id="create-unplanned-task-menu-repair" key="REPAIR">
            <PlusOutlined />
            Repair Task
          </Menu.Item>
          {WORK_ORDERS && (
            <Menu.Item {...props} id="create-unplanned-task-menu-repair" key="DETAIL-REPAIR_TASK">
              <PlusOutlined />
              Detailed Repair Task
            </Menu.Item>
          )}
          <Menu.Item {...props} id="create-unplanned-task-menu-inspect" key="INSPECT">
            <PlusOutlined />
            Inspect Task
          </Menu.Item>
          {WORK_ORDERS && (
            <Menu.Item {...props} id="create-unplanned-task-menu-inspect" key="DETAIL-INSPECT_TASK">
              <PlusOutlined />
              Detailed Inspect Task
            </Menu.Item>
          )}
          {TASK_TEMPLATES && (
            <TaskTemplatesChooser
              displayType={'menu'}
              onClickHandler={handleTemplatesMenuItemClick}
              workContainerTypes={[
                AtlasGqlWorkContainerTypeOption.RepairTask,
                AtlasGqlWorkContainerTypeOption.InspectTask,
              ]}
            />
          )}
        </StyledMenu>
      );
    } else {
      setDropDownMenu(
        <CreateTaskButton
          id="create-unplanned-task-button"
          title="Create New Task"
          className={className}
          onClick={e => e.stopPropagation()}
        >
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu onClick={e => handleRootMenuItemClick(e)}>
                <Menu.Item id="create-unplanned-task-dropdown-repair" key={REPAIR}>
                  Repair
                </Menu.Item>
                {WORK_ORDERS && (
                  <Menu.Item
                    id="create-unplanned-task-dropdown-repair"
                    key={`${DETAIL}-${REPAIR_TASK}`}
                  >
                    Detailed Repair
                  </Menu.Item>
                )}
                <Menu.Item id="create-unplanned-task-dropdown-inspect" key={INSPECT}>
                  Inspect
                </Menu.Item>
                {WORK_ORDERS && (
                  <Menu.Item
                    id="create-unplanned-task-dropdown-inspect"
                    key={`${DETAIL}-${INSPECT_TASK}`}
                  >
                    Detailed Inspect
                  </Menu.Item>
                )}
                {TASK_TEMPLATES && (
                  <TaskTemplatesChooser
                    displayType={'menu'}
                    onClickHandler={handleTemplatesMenuItemClick}
                    workContainerTypes={[
                      AtlasGqlWorkContainerTypeOption.RepairTask,
                      AtlasGqlWorkContainerTypeOption.InspectTask,
                    ]}
                  />
                )}
              </Menu>
            }
          >
            <CreateTaskContainer>
              Create Task&nbsp;
              <DownOutlined />
            </CreateTaskContainer>
          </Dropdown>
        </CreateTaskButton>
      );
    }
  }, [type, saving, WORK_ORDERS, TASK_TEMPLATES, horizonDamageId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (saving) {
    return type === 'menu' ? (
      <Menu.Item disabled {...props}>
        Creating... <LoadingOutlined />
      </Menu.Item>
    ) : (
      <Button disabled>
        Creating... <LoadingOutlined />
      </Button>
    );
  }

  return (
    <>
      {dropDownMenu}
      {modalType &&
        useMenuModal &&
        (isTemplate ? (
          <TaskFromTemplateForm
            title="Create Templated Task"
            visible={visible}
            handleClose={handleClose}
            templateId={templateId}
            parentId={damageId}
            type={modalType}
            damageBranchId={damageBranchId}
            horizonDamageId={horizonDamageId}
            inspectionId={inspectionId}
            assetId={assetId}
          />
        ) : (
          <DetailedTaskForm
            title={`Create ${modalType === REPAIR_TASK ? 'Repair' : 'Inspect'} Task`}
            visible={visible}
            handleClose={handleClose}
            parentId={damageId}
            type={modalType}
            damageBranchId={damageBranchId}
            horizonDamageId={horizonDamageId}
            inspectionId={inspectionId}
            assetId={assetId}
          />
        ))}
    </>
  );
};
