import { Button } from 'antd';
import { ArrowsAltOutlined } from '@ant-design/icons';

import {
  ProgressElement,
  ActionButtons,
} from 'horizon/components/GlobalProgressIndicator/GenericProgress/GenericProgressToast.style';
import { FileUploadProgressSummary } from '../FileUploadProgressSummary';

export function FileUploadToast({ showModal }: { showModal: () => void }) {
  return (
    <ProgressElement>
      <ActionButtons>
        <Button
          icon={<ArrowsAltOutlined />}
          size="small"
          title="view file list"
          onClick={showModal}
        />
      </ActionButtons>
      <span>Files uploaded:</span>
      <FileUploadProgressSummary />
    </ProgressElement>
  );
}
