import { useState } from 'react';

import { StyledAlert, StyledTitle } from './EditAttributesForm.style';
import { Tooltip } from 'components/Tooltip';
import { DamageEditPayload } from 'components/damages/EditableDamageAttributes/types';
import EditableDamageAttributes from 'components/damages/EditableDamageAttributes';
import { usePotentialDamageAttrs } from 'utils/usePotentialDamageAttrs';
import {
  AtlasGqlHorizonDamageObservationGroup,
  useUpdateHorizonDamageObservationGroupMutation,
} from 'types/atlas-graphql';
import { getPrimaryObservationForObservationGroup } from 'horizon/components/Damages/utils';

type EditAttributesFormProps = {
  backgroundColor?: string;
  damageId: string;
  selectedSchema?: string;
  observationGroup: AtlasGqlHorizonDamageObservationGroup;
  onCancel: () => void;
};

export const EditAttributesForm: React.FunctionComponent<EditAttributesFormProps> = ({
  backgroundColor,
  damageId,
  selectedSchema,
  observationGroup,
  onCancel,
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const groupAttrs = observationGroup?.groupAttrs as any;
  const groupCritical = observationGroup?.groupCritical as boolean | undefined;

  const observationsInGroup = observationGroup.observations ?? [];
  const primaryObservation = getPrimaryObservationForObservationGroup(observationGroup);

  const { potentialAttrs, loading: potentialAttrsLoading } = usePotentialDamageAttrs({
    pictureId: primaryObservation?.picture?.id,
    coordinates: primaryObservation?.coordinates,
  });

  const [updateObservationGroup] = useUpdateHorizonDamageObservationGroupMutation({
    onCompleted: () => {
      setSubmitting(false);
      onCancel();
    },
    update: (cache, { data }) => {
      const updatedGroup = data?.updateHorizonDamageObservationGroup;
      if (
        updatedGroup?.isPrimaryObservationGroup === true &&
        updatedGroup?.damageId &&
        updatedGroup?.groupAttrs
      ) {
        cache.modify({
          id: `HorizonDamage:${updatedGroup.damageId}`,
          fields: {
            primaryObservationGroupAttrs(currentAttrs) {
              return updatedGroup.groupAttrs;
            },
          },
        });
      }
    },
  });

  const handleSubmit = (payload: DamageEditPayload) => {
    const { attrs = [], critical } = payload;

    setSubmitting(true);
    updateObservationGroup({
      variables: {
        input: {
          id: observationGroup.id,
          groupAttrs: Object.fromEntries(
            attrs.map(({ name, string, number, date }) => [name, string ?? number ?? date])
          ),
          groupCritical: critical,
        },
      },
    });
  };

  return (
    <>
      {observationsInGroup.length > 1 && (
        <Tooltip
          title={
            <StyledTitle>
              <>
                <div>
                  Observation Group attributes cover all Observations linked from one inspection.
                  Editing Observation Group attributes will not change Observation attributes.
                </div>
                <div>
                  Example: A Damage captured on adjoining photos as two Severity 3 Observations may
                  warrant a Severity of 4 when considered together.
                </div>
              </>
            </StyledTitle>
          }
        >
          <StyledAlert message={'Editing Observation Group Attributes'} type="warning" showIcon />
        </Tooltip>
      )}
      <EditableDamageAttributes
        damage={{ attrs: groupAttrs }}
        potentialAttrs={potentialAttrs}
        canChangeSchema={false}
        schemaId={selectedSchema}
        critical={groupCritical}
        loading={submitting || potentialAttrsLoading}
        shouldHideRealtimePotentialAttrs={observationsInGroup.length > 1}
        shapeDrawn
        shapeChanged={false}
        onCancel={onCancel}
        onSave={handleSubmit}
        showComment={false}
        ignoreWorkInProgress
        backgroundColor={backgroundColor}
      />
    </>
  );
};
