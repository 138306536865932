import { useState, useMemo, FC, useCallback } from 'react';
import { Button } from 'antd5';
import styled from 'styled-components';

import { SelectableAction, useSelectableRecords } from 'utils/selectable';
import { TABLE_SCROLL_WIDTH_SMALL } from 'utils/constants';

import DataTable from 'components/DataTable';
import { location, turbine, label, serial } from 'components/data/blades';
import { LocationChooser } from 'components/choosers';
import { TurbineChooser } from 'components/choosers';
import {
  AtlasGqlGetBladeListForChooserQuery,
  useGetBladeListForChooserLazyQuery,
} from 'types/atlas-graphql';

const Control = styled.div`
  .ant-select {
    min-width: 200px;
  }
`;

const StyledTurbineChooser = styled(TurbineChooser)`
  margin-left: 1rem;
`;

const columns = [location, turbine, label, serial];

interface BulkBladeChooserProps {
  onChange: (blades: any[]) => void;
}

const BulkBladeChooser: FC<BulkBladeChooserProps> = ({ onChange }) => {
  const [locationId, setLocationId] = useState<string | undefined>(undefined);
  const [turbineId, setTurbineId] = useState<string | undefined>(undefined);
  const [blades, setBlades] = useState<AtlasGqlGetBladeListForChooserQuery['blades']>([]);

  const { selected, rowSelection, onClearSelection } = useSelectableRecords();
  const [getBladesFromSite, { loading }] = useGetBladeListForChooserLazyQuery();

  const filteredBlades = useMemo(
    () =>
      blades?.filter(blade => {
        if (locationId && blade?.turbine?.location?.id !== locationId) return false;
        if (turbineId && blade?.turbine?.id !== turbineId) return false;
        return true;
      }),
    [blades, locationId, turbineId]
  );

  const handleAdd = useCallback(() => {
    onChange(selected);
  }, [selected]);

  const handleLocationChange = useCallback(
    async (value: string | string[]) => {
      const selectedValue = value instanceof Array ? value[0] : value;
      if (selectedValue && selectedValue !== locationId) {
        setLocationId(selectedValue);
        const results = await getBladesFromSite({
          variables: {
            locationId: selectedValue,
          },
        });
        setBlades(results.data?.blades ?? []);
      }
    },
    [locationId, getBladesFromSite]
  );

  const handleTurbineChange = useCallback((value: string | string[]) => {
    setTurbineId(value instanceof Array ? value[0] : value);
  }, []);

  const handleClear = () => {
    setLocationId(undefined);
    setTurbineId(undefined);
    setBlades([]);
  };

  return (
    <DataTable
      loading={loading}
      scrollWidth={TABLE_SCROLL_WIDTH_SMALL}
      size="small"
      columns={columns}
      data={filteredBlades}
      rowSelection={rowSelection}
      controls={
        <Control>
          <LocationChooser
            value={locationId}
            onChange={handleLocationChange}
            onClear={handleClear}
            disabled={loading}
          />
          {locationId && (
            <StyledTurbineChooser
              locationId={locationId}
              value={turbineId}
              onChange={handleTurbineChange}
              disabled={loading}
            />
          )}
        </Control>
      }
      actions={
        <SelectableAction selected={selected} onClear={onClearSelection}>
          <Button type="primary" disabled={!selected.length} onClick={handleAdd}>
            Add
          </Button>
        </SelectableAction>
      }
    />
  );
};

export default BulkBladeChooser;
