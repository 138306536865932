import { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Select, Spin } from 'antd5';
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import { groupBy, isEqual } from 'lodash';
import { DataTableContext } from 'components/DataTable';
import { getFilterBy } from 'components/DataTable/paging';
import { PlotContainer, PlotGrid, PlotHeader } from './BladeDamagePlot.style';
import {
  AtlasGqlBlade,
  AtlasGqlHorizonDamage,
  useGetBladeShapeQuery,
  useGetBladeWithShapeQuery,
  useGetHorizonDamagesForPlotQuery,
} from 'types/atlas-graphql';
import { TTableColumnDef } from 'horizon/types/TableColumnDef';
import { BladeDamagePlot } from './BladeDamagePlot';
import { getPlotDimensions } from './utils';

const PAGE_SIZE = 100;

export enum BladeSide {
  LeadingEdge = 'Leading Edge',
  PressureSide = 'Pressure Side',
  SuctionSide = 'Suction Side',
  TrailingEdge = 'Trailing Edge',
}
const BLADE_SIDES = Object.values(BladeSide);

type BladeDamagePlotContainerProps = {
  isOpen: boolean;
  onClose: () => void;
  columns: TTableColumnDef[];
  assetId?: string;
  make?: string;
  model?: string;
};

export const BladeDamagePlotContainer: React.FunctionComponent<BladeDamagePlotContainerProps> = ({
  isOpen,
  onClose,
  columns,
  assetId,
  make,
  model,
}) => {
  const [selectedBladeSides, setSelectedBladeSides] = useState<BladeSide[]>(BLADE_SIDES);

  // Pull filters from damage table context for querying the same damages here
  const { filteredInfo } = useContext(DataTableContext);
  const filterBy = useMemo(
    () => getFilterBy({ filters: filteredInfo, columns }),
    [filteredInfo, columns]
  );

  /**
   * Get blade shape data by make/model, if provided. Otherwise, get blade shape
   * data as a child of the asset with id props.assetId. This will fall back on
   * empty string make/model, which will return the generic shape.
   *
   * Only one of these two queries will run
   */
  const { data: bladeShapeData } = useGetBladeShapeQuery({
    variables: { input: { make: make ?? '', model: model ?? '' } },
    skip: !!assetId && !make && !model,
  });
  const { data: assetBladeShapeData } = useGetBladeWithShapeQuery({
    variables: { id: assetId },
    skip: !assetId || (!!make && !!model),
  });

  // Fetch first PAGE_SIZE damages; see useEffect below for automatic pagination
  const {
    data,
    fetchMore,
    loading: _loading,
    previousData,
  } = useGetHorizonDamagesForPlotQuery({
    variables: {
      input: {},
      filterBy,
      limit: PAGE_SIZE,
      offset: 0,
    },
    skip: !isOpen,
  });

  /**
   * Automatically fetch damages in pages of PAGE_SIZE. Then render data as it is loaded,
   * reducing the time until the graph becomes usable.
   */
  useEffect(() => {
    if (
      data?.getHorizonDamages?.items &&
      data.getHorizonDamages.items.length !== previousData?.getHorizonDamages?.items?.length
    ) {
      fetchMore({
        variables: {
          offset: data.getHorizonDamages.items.length,
        },
        updateQuery: (previousData, { fetchMoreResult }) => ({
          getHorizonDamages: {
            items: [
              ...(previousData.getHorizonDamages?.items ?? []),
              ...(fetchMoreResult.getHorizonDamages?.items ?? []),
            ],
            totalCount: previousData.getHorizonDamages?.totalCount,
          },
        }),
      });
    }
  }, [data, fetchMore, previousData]);

  // Loading state that will be true until all damages are fetched
  const loading: boolean = useMemo(
    () =>
      _loading ||
      (data?.getHorizonDamages?.totalCount ?? 0) > (data?.getHorizonDamages?.items?.length ?? 0),
    [_loading, data?.getHorizonDamages]
  );

  const damagesByBladeSide: { [Property in BladeSide]: AtlasGqlHorizonDamage[] } = useMemo(
    () =>
      groupBy(data?.getHorizonDamages?.items, 'primaryObservationGroupAttrs["Blade Side"]') as {
        [Property in BladeSide]: AtlasGqlHorizonDamage[];
      },
    [data?.getHorizonDamages?.items]
  );

  const { bladeShapeWidths, bladeLength }: { bladeShapeWidths: number[]; bladeLength: number } =
    useMemo(
      () => ({
        bladeShapeWidths:
          bladeShapeData?.bladeCrossSectionWidthsByMakeModel.widths ??
          (assetBladeShapeData?.assetWithMetadata as AtlasGqlBlade)?.crossSections?.widths ??
          [],
        bladeLength:
          bladeShapeData?.bladeCrossSectionWidthsByMakeModel.length ??
          (assetBladeShapeData?.assetWithMetadata as AtlasGqlBlade)?.crossSections?.length ??
          47,
      }),
      [bladeShapeData, assetBladeShapeData]
    );
  return (
    <PlotContainer className={isOpen ? 'open' : ''}>
      <PlotHeader>
        <div>
          <span>Blade Side:</span>
          <Select
            options={BLADE_SIDES.map(side => ({
              value: side,
              disabled: isEqual(selectedBladeSides, [side]),
            }))}
            value={selectedBladeSides}
            onChange={sides =>
              setSelectedBladeSides(BLADE_SIDES.filter(side => sides.includes(side)))
            }
            showSearch={true}
            mode="multiple"
          />
          {loading && (
            <Spin
              indicator={
                <LoadingOutlined
                  spin
                  style={{
                    color: `${({ theme }: { theme: any }) => theme.blue}`,
                    fontSize: '2rem',
                    marginLeft: '1rem',
                  }}
                />
              }
            />
          )}
        </div>

        <div>
          <Button icon={<DownOutlined />} onClick={onClose} />
        </div>
      </PlotHeader>
      <PlotGrid plotCount={selectedBladeSides.length}>
        {selectedBladeSides.map((bladeSide, i) => (
          <BladeDamagePlot
            key={bladeSide}
            bladeSide={bladeSide}
            damages={damagesByBladeSide[bladeSide] as AtlasGqlHorizonDamage[]}
            shapeWidths={bladeShapeWidths}
            bladeLength={bladeLength}
            {...getPlotDimensions(selectedBladeSides.length, i)}
          />
        ))}
      </PlotGrid>
    </PlotContainer>
  );
};
