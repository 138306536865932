import { Button, Modal } from 'antd';
import { DamageLink, formatObservationId } from 'horizon/components/Damages/utils';

type ConnectionModalProps = {
  damageId?: string;
  observationId?: string;
  isOpen: boolean;
  loading?: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

export const ConnectionModal: React.FunctionComponent<ConnectionModalProps> = ({
  damageId,
  observationId,
  isOpen,
  loading = false,
  onConfirm,
  onClose,
}) => {
  return (
    <Modal
      title={'Connect Observation'}
      visible={isOpen}
      onCancel={onClose}
      destroyOnClose={true}
      footer={
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onConfirm} loading={loading} type="primary">
            Connect
          </Button>
        </>
      }
    >
      {damageId && observationId && (
        <>
          Observation {formatObservationId({ id: observationId })} will be connected to Damage{' '}
          <DamageLink damageId={damageId} target={'_blank'} />
          {'.'}
        </>
      )}
    </Modal>
  );
};
