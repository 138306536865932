import { getNumberPicker, getNumberPickerProps } from 'components/data/helpers/filters';
import { isEmpty } from 'lodash';
import { Slider } from 'antd';
import { InputNumber, Radio, Checkbox, Button } from 'antd5';
import {
  CustomFilterPopover,
  CustomFilterContainer,
  InputNumberContainer,
  InputNumberLabel,
  ListItem,
  NoneText,
  NoneCheckboxContainer,
  SecondaryFilterLabel,
} from 'components/data/helpers/filters.style';
import { useAccountContext } from 'utils/account/AccountContext';
import { CRITICAL_DAMAGE_VISIBILITY } from 'utils/release-toggles';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useEffect } from 'react';

/**
 * Constructs filter dropdown component for damage severity column, which renders, sorts,
 * and filters by 'Severity' and 'critical'.
 *
 * Because of how antd's table and filters are written, all filters for a given column
 * must be passed under a single key (i.e. 'Severity' here). To get around this, this
 * component passes filters in objects with `key` properties indicating the property
 * they apply to
 * e.g. [
 *        [{ key: 'Severity', value: [3, 5] }],
 *        [{ key: 'critical', value: 'true' }]
 *      ]
 *
 * These are then grouped by `key` and formatted as graphQL inputs in
 * `src/components/DataTable/paging`
 */

type SimpleFilterValue = number | number[] | string;
type MultiFilterValue = { key: string; value: SimpleFilterValue[] | string };

// Given an array of legacy severity filters, maps to an array in the new multi-filter format
const mapLegacyFilterToMultiFilter = (selectedKeys: SimpleFilterValue[][]): MultiFilterValue[] =>
  selectedKeys.map(value => ({
    key: 'Severity',
    value,
  }));

// Given an array of multi-filters, filters out critical filters and maps severity filters to legacy format
const mapMultiFilterToLegacyFilter = (selectedKeys: MultiFilterValue[]): SimpleFilterValue[][] =>
  selectedKeys
    .filter(({ key }) => key === 'Severity')
    .map(({ value }) => value as SimpleFilterValue[]);

export const getSeverityAndCriticalFilter =
  (
    values: object,
    roundValue: boolean,
    step: number,
    includeSlider: boolean,
    includeNoneOption: boolean,
    noneOptionValue: string,
    criticalOnly: boolean
  ) =>
  ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }: {
    setSelectedKeys: (k: (MultiFilterValue | SimpleFilterValue[])[]) => void;
    selectedKeys: MultiFilterValue[];
    confirm: (options: object) => void;
    clearFilters: () => {};
  }) => {
    const { hasReleaseToggle } = useAccountContext();
    const hasCriticalVisibilityReleaseToggle = hasReleaseToggle(CRITICAL_DAMAGE_VISIBILITY);

    /**
     * return generic number filter on the attention required table since filtering by
     * critical doesn't make sense there (only critical damages are returned anyway)
     *
     * during rollout, also return generic filter if user does not have critical release toggle
     */
    if (!hasCriticalVisibilityReleaseToggle || criticalOnly) {
      let _selectedKeys: (MultiFilterValue | SimpleFilterValue[])[] = selectedKeys;
      /**
       * Map multi-filter to legacy format before passing to simple number filter, and save
       * so changes are reflected in the filters passed to the query.
       */
      if (!criticalOnly && selectedKeys.length && selectedKeys[0].hasOwnProperty('key')) {
        _selectedKeys = mapMultiFilterToLegacyFilter(selectedKeys as MultiFilterValue[]);
        setSelectedKeys(_selectedKeys);
        confirm({ closeDropdown: false });
      }

      return getNumberPicker(
        values,
        roundValue,
        step,
        includeSlider,
        includeNoneOption,
        noneOptionValue
      )({
        setSelectedKeys,
        selectedKeys: _selectedKeys,
        confirm,
        clearFilters,
      });
    }

    /**
     * This effect translates old filters (i.e. just Severity) from localStorage into the new
     * format. This is so filters the user previously used are applied correctly
     * when the release toggle is enabled.
     *
     * Note: it may look like an anti-pattern to have this hook after a conditional early return,
     * however since the above short circuit returns a different component, it needs to be after.
     */
    useEffect(() => {
      /**
       * Attention Required table (which passes `criticalOnly`) never uses the advanced filters,
       * so short circuit there
       */
      if (criticalOnly) {
        return;
      }

      if (
        hasCriticalVisibilityReleaseToggle &&
        selectedKeys.length &&
        !selectedKeys[0].hasOwnProperty('key')
      ) {
        setSelectedKeys(
          mapLegacyFilterToMultiFilter(selectedKeys as unknown as SimpleFilterValue[][])
        );
        confirm({ closeDropdown: false });
      }
    }, []);

    const noneValue = noneOptionValue ?? ' ';
    const isNull = selectedKeys.some(({ value }) => value === noneValue);

    const {
      min,
      max,
      value,
      ...rest
    }: { min: number | undefined; max: number | undefined; value: number[]; step: number } =
      getNumberPickerProps(
        values,
        step,
        selectedKeys.filter(({ key }) => key === 'Severity').map(({ value }) => value),
        isNull
      );

    const handleInputChange = ({ updated, isMin }: { updated: number | null; isMin: boolean }) => {
      if (!updated || isNaN(updated)) {
        return;
      }

      if (isMin) {
        setSelectedKeys([
          ...selectedKeys.filter(({ key }) => key !== 'Severity'),
          { key: 'Severity', value: [updated, value[1]] },
        ]);
      } else {
        setSelectedKeys([
          ...selectedKeys.filter(({ key }) => key !== 'Severity'),
          { key: 'Severity', value: [value[0], updated] },
        ]);
      }
    };

    const handleConfirm = () => {
      if (isEmpty(selectedKeys) && value !== undefined) {
        setSelectedKeys([{ key: 'Severity', value: [value[0], value[1]] }]);
      }
      confirm({ closeDropdown: true });
    };

    const handleToggleNone = () => {
      if (isNull) {
        setSelectedKeys(selectedKeys.filter(({ value }) => value !== noneValue));
      } else {
        setSelectedKeys([
          ...selectedKeys.filter(({ key }) => key !== 'Severity'),
          { key: 'Severity', value: noneValue },
        ]);
      }
    };

    return (
      <CustomFilterPopover className="ant-table-filter-dropdown">
        <CustomFilterContainer>
          {includeSlider && (
            <Slider
              range
              onChange={value =>
                setSelectedKeys([
                  ...selectedKeys.filter(({ key }) => key !== 'Severity'),
                  { key: 'Severity', value },
                ])
              }
              min={min}
              max={max}
              value={value as [number, number]}
              disabled={isNull}
              {...rest}
            />
          )}
          <InputNumberContainer>
            <div>
              <InputNumberLabel>Minimum</InputNumberLabel>
              <InputNumber
                placeholder={'Min'}
                min={min}
                max={value[1] ? value[1] : max}
                value={value[0]}
                onChange={updated => handleInputChange({ updated, isMin: true })}
                style={{ width: '75px' }}
                disabled={isNull}
                {...rest}
              />
            </div>
            <div>
              <InputNumberLabel>Maximum</InputNumberLabel>
              <InputNumber
                placeholder={'Max'}
                min={value[0] ? value[0] : min}
                max={max}
                value={value[1]}
                onChange={updated => handleInputChange({ updated, isMin: false })}
                style={{ width: '75px' }}
                disabled={isNull}
                {...rest}
              />
            </div>
          </InputNumberContainer>
        </CustomFilterContainer>
        <NoneCheckboxContainer>
          {' '}
          <ListItem onClick={handleToggleNone} checked={isNull}>
            <Checkbox onChange={handleToggleNone} checked={isNull} />
            <NoneText />
          </ListItem>
        </NoneCheckboxContainer>
        <CustomFilterContainer>
          <SecondaryFilterLabel>Criticality</SecondaryFilterLabel>
          <Radio.Group
            options={[
              { label: 'Critical', value: 'true' },
              { label: 'Non-Critical', value: 'false' },
              { label: 'Both', value: noneValue },
            ]}
            onChange={({ target }: CheckboxChangeEvent) => {
              if (target.value === noneValue) {
                setSelectedKeys(selectedKeys.filter(({ key }) => key !== 'critical'));
              } else {
                setSelectedKeys([
                  ...selectedKeys.filter(({ key }) => key !== 'critical'),
                  { key: 'critical', value: target.value },
                ]);
              }
            }}
            value={selectedKeys.find(({ key }) => key === 'critical')?.value ?? noneValue}
            optionType="button"
          />
        </CustomFilterContainer>

        <div className="ant-table-filter-dropdown-btns" style={{ borderTop: '1px solid #f0f0f0' }}>
          <Button type="link" size="small" onClick={clearFilters}>
            Reset
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={handleConfirm}
            disabled={value.some(v => typeof v !== 'number' && v !== noneValue)}
          >
            OK
          </Button>
        </div>
      </CustomFilterPopover>
    );
  };
