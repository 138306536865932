import { useEffect, useState } from 'react';
import { isEqual, isEmpty, omitBy, pick } from 'lodash';

import { Button } from 'antd5';

import { Views, ExtensionsOLD } from '../Views';
import { AdditionalActions } from './AdditionalActions';
import { PrioritizeButtons } from '../PrioritizeButtons';
import { ActionContainer } from './Actions.style';
import { TDisplayColumnData } from 'components/data/types';
import {
  EFilterSortClearType,
  TSorter,
  TDropdownAction,
  TDataTableStorageKey,
} from 'components/DataTable/types';
import { TabBarExtraContent } from 'rc-tabs/lib/interface';
import { ExpandAllButton } from 'components/SharedTaskActions/ExpandAllButton';
import { useAccountContext } from 'utils/account/AccountContext';
import { DAMAGE_SORT_FILTER_IMPROVEMENTS } from 'utils/release-toggles';
import { AtlasGqlTableView } from 'types/atlas-graphql';

interface IActionsProps {
  tableId?: string;
  actions?: any;
  priorityContent?: TabBarExtraContent;
  additionalContent?: TabBarExtraContent;
  additionalDropdownActions?: TDropdownAction[];
  additionalDropdownViews?: TDropdownAction[];
  // Filters will be in the shape of { fieldName: [filter_values: string]}
  filters?: {
    [key: string]: any[] | null;
  };
  sorters?: TSorter[];
  columnDefs: TDisplayColumnData[];
  clearSortAndFilterType?: EFilterSortClearType;
  initialPresetFilter?: { value?: { sortOrder: { columnKey: string; order: string } } } & {
    value?: { filters?: { [x: string]: any } };
  } & { key?: string };
  disableUserSort?: boolean;
  hasSelectedRows?: boolean | number;
  onClearSortAndFilter: () => void;
  onTableChange: (pagination: any, filters: any, sorter: any, extra?: any) => void;
  rowReordering?: any;
  showSavedViews?: boolean;
  showColumnPicker?: boolean;
  showMultiSorter?: boolean;
  savedViewCustomFields?: any;
  onSavedViewChange?: (tableView: AtlasGqlTableView) => void;
  storageKey?: TDataTableStorageKey;
  isChangePriorityVisible?: boolean;
  isExpandAllVisible?: boolean;
  handleExpandAll?: () => void;
  isExpandAllEnabled?: boolean;
  allExpanded?: boolean;
}

export const Actions: React.FunctionComponent<IActionsProps> = ({
  tableId,
  actions = undefined,
  priorityContent,
  additionalContent,
  additionalDropdownActions = [],
  additionalDropdownViews = [],
  filters,
  sorters,
  columnDefs,
  clearSortAndFilterType,
  initialPresetFilter,
  disableUserSort = false,
  hasSelectedRows,
  onClearSortAndFilter,
  onTableChange,
  rowReordering,
  showSavedViews = false,
  showColumnPicker = false,
  showMultiSorter = false,
  savedViewCustomFields,
  onSavedViewChange,
  storageKey,
  isChangePriorityVisible = true,
  isExpandAllVisible = false,
  isExpandAllEnabled = false,
  handleExpandAll,
  allExpanded = false,
}) => {
  const { hasReleaseToggle } = useAccountContext();
  const hasSortFilterImprovementsReleaseToggle = hasReleaseToggle(DAMAGE_SORT_FILTER_IMPROVEMENTS);

  const [sortFilterText, setSortFilterText] = useState('Filters');
  const showClearSortAndFilter = Boolean(!hasSelectedRows && onClearSortAndFilter);

  const isClearAction = clearSortAndFilterType === EFilterSortClearType.CLEAR;
  const initialSorters = isClearAction ? {} : initialPresetFilter?.value?.sortOrder ?? {};
  const initialFilters = isClearAction ? {} : initialPresetFilter?.value?.filters ?? {};

  const hasSorters = sorters?.some(sorter => !isEmpty(sorter));
  const hasFilters = Object.values(filters || {}).some(filter => !isEmpty(filter));

  const isClearSortAndFilterDisabled = disableUserSort
    ? false
    : isEqual(omitBy(initialFilters, isEmpty), omitBy(filters, isEmpty)) &&
      isEqual(
        pick(initialSorters, ['columnKey', 'order']),
        pick(sorters?.[0] ?? {}, ['columnKey', 'order'])
      );

  useEffect(() => {
    // When disabled, leave our button text alone
    if (!isClearSortAndFilterDisabled) {
      return setSortFilterText(hasFilters ? 'Filters' : 'Sorters');
    }
  }, [isClearSortAndFilterDisabled, hasFilters, hasSorters, setSortFilterText]);

  const { isPrioritizing } = rowReordering || {};

  return (
    <ActionContainer>
      {priorityContent}
      {hasSortFilterImprovementsReleaseToggle ? (
        <>
          {(showSavedViews ||
            showColumnPicker ||
            showMultiSorter ||
            showClearSortAndFilter ||
            additionalDropdownViews.length > 0) && (
            <Views
              tableId={tableId}
              columnDefs={columnDefs}
              storageKey={storageKey}
              showSavedViews={showSavedViews}
              showColumnPicker={showColumnPicker}
              showMultiSorter={showMultiSorter}
              savedViewCustomFields={savedViewCustomFields}
              onSavedViewChange={onSavedViewChange}
              isPrioritizing={isPrioritizing}
              showClearSortAndFilter={showClearSortAndFilter}
              onClearSortAndFilter={onClearSortAndFilter}
              isClearSortAndFilterDisabled={isClearSortAndFilterDisabled}
              onTableChange={onTableChange}
              additionalItems={additionalDropdownViews}
            />
          )}
          {additionalDropdownActions.length > 0 && (
            <AdditionalActions additionalItems={additionalDropdownActions} />
          )}
        </>
      ) : (
        <>
          {(showColumnPicker || showMultiSorter || additionalDropdownActions.length > 0) && (
            <ExtensionsOLD
              columnDefs={columnDefs}
              storageKey={storageKey}
              showColumnPicker={showColumnPicker}
              showMultiSorter={showMultiSorter}
              isPrioritizing={isPrioritizing}
              additionalDropdownActions={additionalDropdownActions}
            />
          )}
          {showClearSortAndFilter && (
            <Button
              data-testid="clear-filters-btn"
              onClick={onClearSortAndFilter}
              disabled={isClearSortAndFilterDisabled}
            >
              {`Clear ${sortFilterText}`}
            </Button>
          )}
        </>
      )}
      {isExpandAllVisible && (
        <ExpandAllButton
          handleExpandAll={handleExpandAll}
          isExpandAllEnabled={isExpandAllEnabled}
          allExpanded={allExpanded}
        />
      )}
      {additionalContent}
      {!hasSelectedRows && isChangePriorityVisible && (
        <PrioritizeButtons rowReordering={rowReordering} />
      )}
      {isPrioritizing ? null : actions}
    </ActionContainer>
  );
};
