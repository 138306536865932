import { Button } from 'antd5';

interface IExpandAllButtonProps {
  handleExpandAll?: () => void;
  isExpandAllEnabled?: boolean;
  allExpanded?: boolean;
}

export const ExpandAllButton: React.FunctionComponent<IExpandAllButtonProps> = ({
  isExpandAllEnabled = false,
  handleExpandAll,
  allExpanded = false,
}) => {
  const buttonText = `${allExpanded ? 'Collapse' : 'Expand'} All`;

  return (
    <Button
      data-testid="expand-all-button"
      onClick={handleExpandAll}
      disabled={!isExpandAllEnabled}
    >
      {buttonText}
    </Button>
  );
};
