import styled from 'styled-components';
import { Layout, Menu } from 'antd5';

import { FlexContainer } from 'utils/layouts/containers';

import COLORS from 'utils/color/definitions';

const { Sider } = Layout;

export const Logo = styled(FlexContainer)`
  background: ${({ theme }) => theme.lightGray};
  text-align: center;
  overflow: hidden;
  height: 65px;
  justify-content: center;
  border-bottom: 1px solid ${COLORS.BORDER_COLOR};
`;

export const Image = styled.img<{ collapsed?: boolean }>`
  width: ${({ collapsed }) => (collapsed ? '64px' : '100%')};
  padding: 0.5em 1em;
`;

// !important for overriding antd
// Trevor: Not sure where the "margin-right: 10px" is coming from on the icon property
// for the Menu, but it causes a larger space between the icon and title than what we want.
// When inspecting the antd menu on their examples on the antd site. It does not have
// the "margin-right: 10px" property.
export const StyledMenu = styled(Menu)`
  width: 100%;
  .ant-menu-item {
    font-size: 16px !important;
  }
  .ant-menu-item .ant-menu-item-icon {
    margin-right: 0px;
  }
`;

export const StyledSider = styled(Sider)`
  border-right: 1px solid ${COLORS.BORDER_COLOR};
`;
