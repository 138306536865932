import { useState, useEffect, useMemo } from 'react';

import { Row, Col, Collapse, Button, List } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { groupBy, uniqBy, isString, orderBy } from 'lodash';

import {
  AtlasGqlHorizonDamage,
  AtlasGqlHorizonDamageObservation,
  AtlasGqlInspection,
} from 'types/atlas-graphql';

import {
  ObservationListItem,
  ObservationListItemTypes,
} from '../ObservationComparer/ObservationListItem';
import { ObservationSummary } from '../ObservationComparer/ObservationSummary';
import {
  DetailCard,
  DetailContainer,
  InspectionCollapse,
} from '../ComparerPane/ComparerPane.style';
import {
  getAttrsDistance,
  getAttrsSide,
  getObservationsForLinking,
  getPictureTargetDistance,
  getPictureTargetSide,
  getPrimaryObservationForDamage,
  sortSuggestions,
} from '../../utils';

const { Panel } = Collapse;

type SuggestionsProps = {
  damage: AtlasGqlHorizonDamage;
  inspections: AtlasGqlInspection[];
  inspectionsToCompare: AtlasGqlInspection[];
  suggestedObservation: AtlasGqlHorizonDamageObservation | undefined;
  setObservationId: (id: string | undefined) => void;
  onClickConnect: () => void;
};

export const Suggestions: React.FunctionComponent<SuggestionsProps> = ({
  damage,
  inspections,
  inspectionsToCompare: _inspectionsToCompare,
  suggestedObservation,
  setObservationId,
  onClickConnect,
}) => {
  const [otherObservationsOpen, setOtherObservationsOpen] = useState<boolean>(false);
  const [collapseActiveKeys, setCollapseActiveKeys] = useState<string[]>([]);

  const primaryObservation = getPrimaryObservationForDamage(damage);

  // get observations to compare
  const observations = getObservationsForLinking(damage, inspections);
  const observationsToCompare = sortSuggestions(
    damage,
    getObservationsForLinking(damage, _inspectionsToCompare)
  );

  // Get an object of observations grouped by damageId as the key
  const observationsGroupedByDamage = groupBy(observations, 'damageId');

  // Get an array of observations unique by damageId
  const observationsUniqueByDamage = uniqBy(observationsToCompare, 'damageId');

  // Map over the inspections, giving them the observations filtered down by damageId from above
  const inspectionsToCompare: AtlasGqlInspection[] = useMemo(
    () =>
      _inspectionsToCompare.map(inspection => ({
        ...inspection,
        horizonDamageObservations: (observationsUniqueByDamage ?? []).filter(
          (observation: AtlasGqlHorizonDamageObservation) =>
            observation.id !== primaryObservation?.id &&
            observation.inspection?.id === inspection.id
        ),
      })),
    [_inspectionsToCompare]
  );

  // collapse empty inspection groups
  useEffect(() => {
    const emptyInspectionIds = inspectionsToCompare
      .filter(inspection => inspection?.horizonDamageObservations?.length === 0)
      .map(inspection => inspection.id);

    if (emptyInspectionIds.length) {
      setCollapseActiveKeys(collapseActiveKeys.filter(key => !emptyInspectionIds.includes(key)));
    }
  }, [inspectionsToCompare]);

  // Get other observations on the same damage as the selected one (excluding the unique observations)
  const otherDamageObservations = orderBy(
    (observationsGroupedByDamage?.[suggestedObservation?.damageId] ?? []).filter(
      otherObservation =>
        !observationsUniqueByDamage
          .map(uniqueObservation => uniqueObservation.id)
          .includes(otherObservation.id)
    ),
    [
      observation => observation.attrs?.Severity ?? 0,
      observation => observation.inspection?.inspectionDate,
    ],
    ['desc', 'desc']
  );

  const hasOtherObservations: boolean = otherDamageObservations?.length > 0;

  return (
    <DetailContainer>
      <DetailCard title="Suggestions" width="33%" size="small">
        <InspectionCollapse
          activeKey={collapseActiveKeys}
          onChange={key => {
            setCollapseActiveKeys(isString(key) ? [key] : key);
          }}
        >
          {inspectionsToCompare.map(inspection => {
            const sortedObservations = orderBy(
              inspection.horizonDamageObservations,
              [
                observation =>
                  getAttrsDistance(observation?.attrs) ??
                  getPictureTargetDistance(observation?.picture?.target),
                observation =>
                  getAttrsSide(observation?.attrs) ??
                  getPictureTargetSide(observation?.picture?.target),
              ],
              ['asc', 'asc']
            );

            return (
              <Panel
                {...(sortedObservations.length === 0
                  ? { collapsible: 'disabled', showArrow: false }
                  : {})}
                header={
                  <div>
                    <p>{inspection.inspectionDate}</p>
                    <p className="inspection-detail">
                      {[inspection.vendor?.name, inspection.type?.name].filter(Boolean).join(' - ')}
                    </p>
                  </div>
                }
                key={inspection.id}
              >
                <List
                  size="small"
                  dataSource={sortedObservations}
                  renderItem={(o: AtlasGqlHorizonDamageObservation) => (
                    <ObservationListItem
                      key={o.id}
                      observation={o}
                      selected={o.id === suggestedObservation?.id}
                      linked={observationsGroupedByDamage?.[o.damageId].length > 1}
                      onClick={() => setObservationId(o.id)}
                      type={ObservationListItemTypes.Suggestion}
                    />
                  )}
                />
              </Panel>
            );
          })}
        </InspectionCollapse>
      </DetailCard>
      <DetailCard
        title="Observation Details"
        size="small"
        actions={[
          ...(suggestedObservation
            ? [
                <Button key={'add-button'} onClick={onClickConnect} type="primary">
                  Link to Parent
                </Button>,
              ]
            : []),
          ...(hasOtherObservations
            ? [
                <Button
                  key={'collapse-button'}
                  className="collapse-button"
                  onClick={() => setOtherObservationsOpen(!otherObservationsOpen)}
                  icon={otherObservationsOpen ? <RightOutlined /> : <LeftOutlined />}
                />,
              ]
            : []),
        ]}
        $hasCollapseAction={!!hasOtherObservations}
      >
        {suggestedObservation ? (
          <>
            <ObservationSummary
              observation={suggestedObservation}
              observationsOpen={otherObservationsOpen}
            />
            {!!hasOtherObservations && (
              <DetailCard
                title={otherObservationsOpen ? 'Linked Observations' : ''}
                size="small"
                width={otherObservationsOpen ? '50%' : '54px'}
                style={{ position: 'sticky', top: 0 }}
              >
                {otherObservationsOpen && (
                  <List
                    style={{ width: '100%' }}
                    size="small"
                    dataSource={otherDamageObservations}
                    renderItem={(o: AtlasGqlHorizonDamageObservation) => (
                      <ObservationListItem
                        key={o.id}
                        observation={o}
                        selected={o.id === suggestedObservation.id}
                        onClick={() => setObservationId(o.id)}
                      />
                    )}
                  />
                )}
              </DetailCard>
            )}
          </>
        ) : (
          <Row style={{ flex: '1', padding: '1rem' }}>
            <Col>No Observation Selected</Col>
          </Row>
        )}
      </DetailCard>
    </DetailContainer>
  );
};
