import { useContext, useMemo, useState } from 'react';
import { Tag, Divider } from 'antd5';
import { CaretUpOutlined, CaretDownOutlined, UndoOutlined } from '@ant-design/icons';

import { useAccountContext } from 'utils/account/AccountContext';
import { Tooltip } from 'components/Tooltip';
import {
  StyledBanner,
  StyledTitle,
  StyledButton,
  StyledLink,
  ItalicText,
} from './CustomizedDataBanner.style';

import { TDisplayColumnData } from 'components/data/types';
import { DataTableContext } from '../index';
import { TDataTableStorageKey } from '../types';
import { tableStorage } from '../utils';

import { updateView, isViewAndTableStateEqual, mapColumns, mapMultiSorts } from '../Views/utils';
import { SaveViewModal, SaveViewState, ESaveOptions } from '../Views/SaveView';
import { columnPickerStorage, updateCustomCols } from '../Views/ColumnPicker/utils';
import { multiSorterStorage, updateSort } from '../Views/MultiSorter/utils';
import { AtlasGqlTableView } from 'types/atlas-graphql';

type CustomizedDataBannerProps = {
  tableId?: string;
  storageKey?: TDataTableStorageKey;
  columnDefs: TDisplayColumnData[];
  onTableChange: (pagination: any, filters: any, sorter: any, extra?: any) => void;
  onClearSortAndFilter: () => void;
  savedViewCustomFields?: any;
  onSavedViewChange?: (tableView: AtlasGqlTableView) => void;
};

export const CustomizedDataBanner = ({
  tableId,
  storageKey,
  columnDefs,
  onTableChange,
  onClearSortAndFilter,
  savedViewCustomFields,
  onSavedViewChange,
}: CustomizedDataBannerProps) => {
  const [saveViewState, setSaveViewState] = useState<SaveViewState>({
    isOpen: false,
  });

  const { user } = useAccountContext();

  const tableViewStorageManager = tableStorage({ storageKey: `${tableId}_VIEW`, user });
  const columnPickerStorageManager = columnPickerStorage({ storageKey: storageKey?.COLUMN_PICKER });
  const multiSorterStorageManager = multiSorterStorage({ storageKey: storageKey?.MULTI_SORT });

  const {
    pagination,
    filteredInfo,
    sortedInfo,
    tableView,
    updateTableView,
    customizedColumnSet,
    updateCustomizedColumnSet,
    multiSortDef,
    updateMultiSortDef,
  } = useContext(DataTableContext) || {};

  // checks to see if the view and the table state are different
  const isViewSaved = useMemo(() => {
    return isViewAndTableStateEqual(tableView, {
      filters: filteredInfo,
      sorts: sortedInfo,
      columns: customizedColumnSet,
      multiSorts: multiSortDef,
    });
  }, [tableView, filteredInfo, sortedInfo, customizedColumnSet, multiSortDef]);

  // sets table to given view OR to its default state
  const applyViewToTable = (tableViewToApply?: AtlasGqlTableView) => {
    if (tableViewToApply) {
      const { filters, sorts, columns, multiSorts } = tableViewToApply;

      const sortKey = sorts?.columnKey;
      const appliedSort = sortKey ? { ...sorts, column: { sortKey } } : {};

      // set filters/sorters
      onTableChange({ ...pagination, current: 1 }, filters, appliedSort);
      // set customized columns
      updateCustomCols(columnDefs, columns, updateCustomizedColumnSet, columnPickerStorageManager);
      // set multisort
      updateSort(columnDefs, multiSorts, updateMultiSortDef, multiSorterStorageManager);

      // update view
      updateView(tableViewToApply, updateTableView, tableViewStorageManager);
      onSavedViewChange && onSavedViewChange(tableViewToApply);
    } else {
      // clears filters/sorts
      onClearSortAndFilter();
      // reset customized columns
      updateCustomizedColumnSet(undefined);
      columnPickerStorageManager.remove();
      // reset multisort
      updateMultiSortDef(undefined);
      multiSorterStorageManager.remove();

      // reset table view
      updateTableView(undefined);
      tableViewStorageManager.remove();
    }
  };

  // saves changes to the current view OR copy of the current view
  const handleSave = (type: ESaveOptions) => {
    setSaveViewState({
      isOpen: true,
      tableId,
      tableView: {
        ...tableView,
        filters: filteredInfo,
        sorts: sortedInfo,
        columns: (customizedColumnSet ?? []).map(mapColumns),
        multiSorts: (multiSortDef ?? []).map(mapMultiSorts),
        customFields: savedViewCustomFields,
      },
      columnDefs,
      type,
    });
  };

  return (
    <>
      <StyledBanner>
        <div>
          {tableView ? (
            <>
              <StyledTitle>{tableView.name}</StyledTitle>
              {!isViewSaved && (
                <>
                  <ItalicText>(Unsaved)</ItalicText>
                  <Tooltip title="Reset current View to the saved version.">
                    <StyledButton
                      icon={<UndoOutlined />}
                      onClick={() => applyViewToTable(tableView)}
                    />
                  </Tooltip>
                </>
              )}
            </>
          ) : (
            <>
              {customizedColumnSet && multiSortDef ? (
                <StyledTitle>
                  Visible columns are customized and multi-column sort applied:
                </StyledTitle>
              ) : customizedColumnSet ? (
                <StyledTitle>Visible columns are customized</StyledTitle>
              ) : multiSortDef ? (
                <StyledTitle>Multi-column sort applied:</StyledTitle>
              ) : null}
              {multiSortDef && (
                <>
                  {multiSortDef.map(({ column: { title }, sortDirection }, index) => (
                    <Tag key={index}>
                      {title}
                      {sortDirection === 'ASC' ? <CaretUpOutlined /> : <CaretDownOutlined />}
                    </Tag>
                  ))}
                </>
              )}
            </>
          )}
        </div>
        <div>
          {tableView && !isViewSaved && (
            <>
              <Tooltip title="Save changes to current View.">
                <StyledLink type="link" onClick={() => handleSave(ESaveOptions.UPDATE)}>
                  Save
                </StyledLink>
              </Tooltip>
              <Divider type="vertical" />
              <Tooltip title="Save a new copy.">
                <StyledLink type="link" onClick={() => handleSave(ESaveOptions.COPY)}>
                  Save As
                </StyledLink>
              </Tooltip>
              <Divider type="vertical" />
            </>
          )}
          <Tooltip title="Resets the table to its default state.">
            <StyledLink type="link" onClick={() => applyViewToTable(undefined)}>
              Reset Table
            </StyledLink>
          </Tooltip>
        </div>
      </StyledBanner>
      {!!tableView && (
        <SaveViewModal
          saveViewState={saveViewState}
          onClose={() => setSaveViewState({ isOpen: false })}
          onSuccess={savedView => {
            if (savedView) {
              applyViewToTable(savedView);
            }
          }}
        />
      )}
    </>
  );
};
