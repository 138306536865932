import { useState, useReducer, useMemo } from 'react';
import {
  AtlasGqlHorizonDamage,
  AtlasGqlHorizonDamageObservation,
  AtlasGqlHorizonDamageObservationGroup,
  useGetHorizonDamageQuery,
  useRemoveObservationFromDamageMutation,
} from 'types/atlas-graphql';

import { message } from 'antd';
import { ComparerContainer, ComparerModal } from '../CompareOTron.style';
import { ObservationComparerPane } from './ObservationComparerPane';
import { RemoveConfirmationModal } from './RemoveConfirmationModal';
import { formatDamageId, formatObservationId } from '../../utils';
import { extractObservationIdsFromDamage } from '../../usePollForHorizonDamageUpdates/utils';

/**
 * This component and its children are deprecated and will be removed during cleanup of
 * rtv1:new_image_viewer
 *
 * Further maintenance should not be needed, check with Riley with any questions.
 */

type ObservationComparerProps = {
  damage: AtlasGqlHorizonDamage;
  observations: AtlasGqlHorizonDamageObservation[];
  observationIds: string[];
  isOpen: boolean;
  onClose: (removed?: string[]) => void;
};

const observationComparerReducer = (
  {
    leftObservationId,
    rightObservationId,
  }: { leftObservationId: string; rightObservationId: string },
  action: Partial<{ leftObservationId: string; rightObservationId: string }>
): { leftObservationId: string; rightObservationId: string } => {
  if (action.leftObservationId && action.leftObservationId !== rightObservationId) {
    return { leftObservationId: action.leftObservationId, rightObservationId };
  }
  if (action.rightObservationId && action.rightObservationId !== leftObservationId) {
    return { leftObservationId, rightObservationId: action.rightObservationId };
  }
  return { leftObservationId, rightObservationId };
};

export const ObservationComparer: React.FunctionComponent<ObservationComparerProps> = ({
  damage,
  observations,
  observationIds,
  isOpen,
  onClose,
}) => {
  const [removeConfirmationOpenForId, setRemoveConfirmationOpenForId] = useState<string>();
  const [removedObservation, setRemovedObservation] = useState<AtlasGqlHorizonDamageObservation>();

  const groupForObservationToRemove: AtlasGqlHorizonDamageObservationGroup | undefined =
    useMemo(() => {
      const inspectionId = observations.find(({ id }) => id === removeConfirmationOpenForId)
        ?.inspection?.id;

      if (inspectionId) {
        return damage.observationGroups?.find(g => g?.inspectionId === inspectionId) ?? undefined;
      }

      return undefined;
    }, [removeConfirmationOpenForId]);

  const [{ leftObservationId, rightObservationId }, dispatch] = useReducer(
    observationComparerReducer,
    {
      leftObservationId: observationIds[0],
      rightObservationId: observationIds[1],
    }
  );

  const getRemoveObservationMessage = () => {
    const damageLinkUrl = `/damages2/${removedObservation?.damageId}`;
    const observationLinkUrl = `/pictures/${removedObservation?.picture?.id}?observation=${removedObservation?.id}&version=2`;

    return removedObservation ? (
      <span>
        Observation{' '}
        <a href={observationLinkUrl} target="_blank" rel="noreferrer">
          {formatObservationId(removedObservation)}
        </a>{' '}
        successfully removed and added to Damage{' '}
        <a href={damageLinkUrl} target="_blank" rel="noreferrer">
          {formatDamageId({ id: removedObservation?.damageId })}
        </a>
      </span>
    ) : (
      <span>Observation successfully removed.</span>
    );
  };

  const { startPolling, stopPolling } = useGetHorizonDamageQuery({
    variables: { input: { id: damage.id } },
    fetchPolicy: 'network-only',
    skip: !removedObservation,
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      const damage = data?.getHorizonDamage && (data.getHorizonDamage as AtlasGqlHorizonDamage);
      if (damage) {
        // check to see if observation is removed from the returned damage
        const allObservations: string[] = extractObservationIdsFromDamage(damage);
        const isObservationRemoved: boolean = !allObservations.includes(
          removeConfirmationOpenForId ?? ''
        );

        // check to see if a primary observation group has been set
        const isPrimaryGroupUpdated: boolean = !!damage.observationGroups?.some(
          og => og?.isPrimaryObservationGroup
        );

        // check to see if all groups have a valid source observation
        const areGroupsValid: boolean = !!damage.observationGroups?.every(og =>
          og?.observations?.map(o => o?.id).includes(og.sourceObservationId)
        );

        const isDamageUpdated = isObservationRemoved && isPrimaryGroupUpdated && areGroupsValid;

        // update selected observations
        if (isObservationRemoved) {
          const unselectedObservations = observationIds.filter(
            id => id !== leftObservationId && id !== rightObservationId
          );
          if (removeConfirmationOpenForId === leftObservationId) {
            dispatch({ leftObservationId: unselectedObservations[0] });
          } else if (removeConfirmationOpenForId === rightObservationId) {
            dispatch({ rightObservationId: unselectedObservations[0] });
          }
        }

        // update UI once fully updated damage returns
        if (isDamageUpdated) {
          handleDamageUpdate();
        }
      }
    },
  });

  const [removeObservation, { loading: removeLoading }] = useRemoveObservationFromDamageMutation({
    onCompleted: data => {
      const { id: observationId, damageId } = data.removeObservationFromDamage ?? {};
      const _removedObservation = observations.find(o => o.id === observationId);

      if (_removedObservation && damageId) {
        // set the removed observation and its new damageId
        setRemovedObservation({ ..._removedObservation, damageId });

        // start polling for updated current damage
        startPolling(2000);
      }
    },
  });

  const handleConfirmRemoveObservation = () => {
    if (removeConfirmationOpenForId) {
      removeObservation({
        variables: { input: { observationId: removeConfirmationOpenForId } },
      });
    }
  };

  const handleDamageUpdate = () => {
    // display removal message
    if (removedObservation) {
      message.success(getRemoveObservationMessage(), 5);
    }

    // close comparer if there are less than two observations remaining
    if (observationIds.length < 2) {
      onClose();
    }

    // clear state values for removed observation
    setRemoveConfirmationOpenForId(undefined);
    setRemovedObservation(undefined);

    // stop polling for updated damage
    stopPolling();
  };

  return (
    <>
      <ComparerModal
        visible={isOpen}
        onCancel={() => onClose()}
        footer={null}
        destroyOnClose
        bodyStyle={{ height: '100%', width: '100%' }}
      >
        <ComparerContainer>
          <ObservationComparerPane
            paneSide="left"
            damage={damage}
            observations={observations}
            selectedObservationId={leftObservationId}
            setSelectedObservationId={id => dispatch({ leftObservationId: id })}
            disabledObservationIds={[rightObservationId]}
            setRemoveConfirmationOpenForId={setRemoveConfirmationOpenForId}
          />
          <ObservationComparerPane
            paneSide="right"
            damage={damage}
            observations={observations}
            selectedObservationId={rightObservationId}
            setSelectedObservationId={id => dispatch({ rightObservationId: id })}
            disabledObservationIds={[leftObservationId]}
            setRemoveConfirmationOpenForId={setRemoveConfirmationOpenForId}
          />
        </ComparerContainer>
      </ComparerModal>
      <RemoveConfirmationModal
        damageId={damage.id}
        observationId={removeConfirmationOpenForId ?? ''}
        groupForObservation={groupForObservationToRemove}
        isOpen={!!removeConfirmationOpenForId}
        loading={removeLoading || !!removedObservation}
        onConfirm={handleConfirmRemoveObservation}
        onClose={() => setRemoveConfirmationOpenForId(undefined)}
      />
    </>
  );
};
