import { isNumber } from 'lodash';
import { TagProps } from 'antd5';
import { getSeverityColor } from 'utils/color/helpers';
import { useAccountContext } from 'utils/account/AccountContext';
import { CRITICAL_DAMAGE_VISIBILITY } from 'utils/release-toggles';
import SeverityTag from 'components/SeverityTag';
import { Container, StyledTag, CriticalIcon } from './SeverityAndCriticalIndicator.style';
import { None } from 'components/data/helpers';
import { Tooltip } from 'components/Tooltip';

export enum CriticalLevel {
  Group,
  Observation,
  Image,
}
type SeverityAndCriticalIndicatorProps = TagProps &
  React.RefAttributes<HTMLElement> & {
    value?: number;
    maxValue?: number;
    isCritical?: boolean;
    criticalLevel?: CriticalLevel;
    margin?: string;
  };

/**
 * This component replaces <SeverityTag /> with the release toggle 'critical_damage_visibility'.
 * Unlike most release toggle conditional renders, this component itself is handling the conditional
 * rendering. This is because the component is used in the damage column renderer functions, where we
 * cannot easily check toggles. Rather than getting deep in the table logic to pass toggles or user objects
 * around, we can just handle the conditional here.
 *
 * All legacy uses of <SeverityTag /> in deprecated code that is not ready to be removed yet,
 * but does not need to support the new critical work have not been replaced.
 */

export const SeverityAndCriticalIndicator: React.FunctionComponent<
  SeverityAndCriticalIndicatorProps
> = ({ value, maxValue, isCritical, criticalLevel = CriticalLevel.Group, margin, ...props }) => {
  const { hasReleaseToggle } = useAccountContext();

  if (hasReleaseToggle(CRITICAL_DAMAGE_VISIBILITY)) {
    return (
      <Container margin={margin}>
        {
          <StyledTag
            data-testid="severity-tag"
            color={getSeverityColor(value, maxValue) ?? undefined}
            $borderStyle={!value ? '1px dashed #00000055' : undefined}
            {...props}
          >
            {value ?? '?'}
          </StyledTag>
        }
        {isCritical && <CriticalIndicator criticalLevel={criticalLevel} />}
      </Container>
    );
  }

  // Without the release toggle, render the old severity tag
  return isNumber(value) ? (
    <SeverityTag style={{ margin }} value={value} maxValue={maxValue} {...props} />
  ) : (
    <None />
  );
};

export const CriticalIndicator: React.FunctionComponent<{ criticalLevel?: CriticalLevel }> = ({
  criticalLevel = CriticalLevel.Group,
}) => (
  <Tooltip
    title={
      {
        Group: 'This Damage contains a critical Observation',
        Observation: 'This is a critical Observation',
        Image: 'One of the Observations on this image is critical',
      }[CriticalLevel[criticalLevel]]
    }
  >
    <CriticalIcon />
  </Tooltip>
);
