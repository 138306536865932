import {
  standardInput,
  numberInput,
  dateInput,
  selectInput,
  timeInput,
  multiSelectInput,
} from 'utils/editable';
import { NUMBER, DATE, TIME_RANGE, INSPECTION_TASK, numberCommaRegex } from 'utils/constants';
import { None } from 'components/data/helpers';
import { inlineEditableHeaderField } from 'utils/editable/InlineEditable';

import moment from 'moment';
import { Tag } from 'antd';
import { isNull } from 'lodash';
import {
  AtlasGqlConfiguredField,
  AtlasGqlInspectionTask,
  AtlasGqlOrganization,
  AtlasGqlTask,
} from 'types/atlas-graphql';
import {
  createdFrom,
  deliveryStatus,
  deliveryDate,
  getForOrganization,
} from 'components/data/tasks/tasks';
import { maintenanceActivityNameHeader } from 'components/data/tasks/maintenanceActivity';
import { TDisplayColumnData, TEditColumnData } from 'components/data/types';

const valueDict = {
  TEXT: 'textValue',
  NUMBER: 'numberValue',
  DATE: 'dateValue',
  SELECT: 'selectValue',
  MULTI_SELECT: 'multiSelectValue',
};

const inputDict = {
  TEXT: standardInput,
  SELECT: selectInput,
  MULTI_SELECT: multiSelectInput,
  DATE: dateInput,
  NUMBER: numberInput,
  TIME_RANGE: timeInput,
};

type TFieldShape = {
  value: {
    timeRangeStartValue?: string;
    timeRangeEndValue?: string;
  };
  type: 'TEXT' | 'NUMBER' | 'DATE' | 'SELECT' | 'MULTI_SELECT' | 'TIME_RANGE';
};

export function getDefaultValue(field: TFieldShape) {
  const { value, type } = field;
  if (!value) return null;

  if (type !== TIME_RANGE) {
    //@ts-ignore TS doesn't like indexing with a non-number
    return value[valueDict[type]];
  }

  return value?.timeRangeStartValue && value?.timeRangeEndValue
    ? [value?.timeRangeStartValue, value?.timeRangeEndValue]
    : null;
}

export function renderValues(value: any, column: any) {
  const { type, key } = column;

  if (typeof value !== 'number' && !value) {
    return <None key={key} />;
  }

  if (type === 'TIME_RANGE' && Array.isArray(value) && value.length === 2) {
    const [startValue, endValue] = value;
    // Issues with startValue or endValue should display 0-0 instead of breaking
    if (!startValue || !endValue) {
      return <div key={key}>0:00 - 0:00</div>;
    }
    const [startHours, startMinutes] = startValue.split(':');
    const [endHours, endMinutes] = endValue.split(':');
    return (
      <div key={key}>
        {`${startHours}:${startMinutes}`} - {`${endHours}:${endMinutes}`}
      </div>
    );
  }

  if (type === 'MULTI_SELECT' && Array.isArray(value)) {
    if (value.length === 0) {
      return <None key={key} />;
    }
    return (
      <div key={key}>
        {value.map(v => (
          <Tag key={v}>{v}</Tag>
        ))}
      </div>
    );
  }

  if (type === 'DATE') {
    return <div key={key}>{moment(value).format('YYYY-MM-DD')}</div>;
  }

  return <div key={key}>{value}</div>;
}

export function formatTime(time: any) {
  if (!time) return null;

  if (typeof time === 'string') return time;

  if (moment.isMoment(time)) return time.format('HH:mm');
}

export function getFieldValue(value: any, column: any) {
  if (column.type === TIME_RANGE) {
    return {
      //@ts-ignore
      timeRangeStartValue: formatTime(Object.values(value)[0][0]),
      //@ts-ignore
      timeRangeEndValue: formatTime(Object.values(value)[0][1]),
    };
  }

  if (column.type === DATE) {
    return {
      //@ts-ignore
      [valueDict[column.type]]: Object.values(value)[0]?.format('YYYY-MM-DD') || null,
    };
  }

  if (column.type === NUMBER) {
    return {
      //@ts-ignore
      [valueDict[column.type]]:
        typeof Object.values(value)[0] === 'number' ? Object.values(value)[0] : null,
    };
  }

  return {
    //@ts-ignore
    [valueDict[column.type]]: Object.values(value)[0] ? Object.values(value)[0] : null,
  };
}

export function getEditInput(field: any, column: any) {
  const { type, id } = field;
  const { defaultValue } = column;

  //@ts-ignore
  const renderInput = inputDict[type];
  return renderInput.length === 2 ? renderInput(id, defaultValue) : renderInput(column);
}

/**
 * Helper to return additional columns that are not configured, but should be displayed in certain circumstances
 * @param task - The Task that may or may not require additional header columns
 * @param isEditor - Does the user have the editor role
 * @param features - The users feature flags
 * @param organizations - The organizations the user has access to
 * @param onSave - A callback to handle saving the task
 * @returns A (potentially empty) array of additional header column definitions.
 */
export function getContextualColumns(
  task: AtlasGqlTask,
  isEditor: boolean,
  features: Record<string, boolean>,
  organizations?: AtlasGqlOrganization[],
  onSave?: (input: any) => Promise<void> | undefined
): any[] {
  // Used to determine whether the user can edit the For Org field
  const { INSPECTION_DELIVERY } = features;

  const taskTypeName = task?.type?.type ?? '';
  const inspectionDeliveryEnabled: boolean =
    INSPECTION_DELIVERY && [INSPECTION_TASK].includes(taskTypeName);
  const taskHasForOrg = 'forOrganization' in task && task.forOrganization;

  // Normally, if any organizations are present, we'll want to display forOrg.
  // Also account for edge case where no orgs are present, but a ForOrg has previously been set.
  // We want to display that historic data, so check for that here.
  const shouldForOrgFieldBeDisplayed = organizations?.length || taskHasForOrg;

  // FIXME CRS: getContextualColumns is starting to get overloaded. If we have to add more to this we should refactor
  const additionalColumns: (TEditColumnData | TDisplayColumnData)[] = [
    // used to show alert if we have one
    ...(task && 'alert' in task && task.alert ? [createdFrom] : []),
    // used to show maintenance activity for a task
    ...(task && 'maintenanceActivity' in task && task?.maintenanceActivity
      ? [maintenanceActivityNameHeader()]
      : []),
    // used to show to target organization for inspection delivery
    ...(task && inspectionDeliveryEnabled && shouldForOrgFieldBeDisplayed
      ? [
          getForOrganization(
            organizations ?? [],
            isEditor,
            features,
            task as AtlasGqlInspectionTask,
            onSave
          ),
        ]
      : []),
    // used to show delivery status and date for inspection delivery
    ...(inspectionDeliveryEnabled && task && 'inspection' in task && task.inspection
      ? [deliveryStatus, deliveryDate]
      : []),
  ];

  return additionalColumns;
}

export function resolveStandardField(
  standardFields: any,
  type: string,
  name: string
): TEditColumnData | TDisplayColumnData | undefined {
  if (type in standardFields && name in standardFields[type]) {
    return standardFields[type][name];
  }
}

export function sortAndMapFieldsToColumns(
  fields: any[],
  type: string,
  // Can be an empty array if you KNOW the fields are non-standard
  standardFields: [] | any,
  containerId: string,
  saveWorkContainerFieldValues: Function,
  isEditButtonVisible: boolean | undefined
): any {
  return fields
    .sort(
      (valA, valB) =>
        valA?.configured?.find((c: AtlasGqlConfiguredField) => c?.associatedType?.type === type)
          ?.displayOrder -
        valB?.configured?.find((c: AtlasGqlConfiguredField) => c?.associatedType?.type === type)
          ?.displayOrder
    )
    .map(field => {
      const standardField = resolveStandardField(standardFields, type, field?.name);
      if (standardField) {
        return standardField;
      }

      const defaultValue = getDefaultValue(field);
      const displayValue =
        typeof defaultValue === 'number'
          ? `${defaultValue}`.replace(numberCommaRegex, ',')
          : defaultValue;
      const columnDef = {
        key: field.id,
        title: field.name,
        defaultValue: displayValue,
        options: field?.options,
        dataIndex: field.name,
        type: field.type,
        render: renderValues,
        edit: getEditInput(field, { defaultValue, options: field?.options }),
        customField: true,
        editRule: null,
      };

      return inlineEditableHeaderField(
        columnDef,
        {
          onSave: (value: string, column: any) => {
            if (isNull(value)) return;
            const fieldValue = getFieldValue(value, column);

            saveWorkContainerFieldValues({
              variables: {
                id: containerId,
                input: {
                  additionalFields: [
                    {
                      fieldId: column.key,
                      ...fieldValue,
                    },
                  ],
                },
              },
            });
          },
        },
        isEditButtonVisible
      );
    });
}
