import { useMemo } from 'react';
import * as d3 from 'd3';
import { AtlasGqlHorizonDamage } from 'types/atlas-graphql';
import { BladeSide } from './BladeDamagePlotContainer';
import { Axes } from './Axes';
import { EdgeShape } from './EdgeShape';
import { SideShape } from './SideShape';
import { mapEdgeDamagesToCoordinates, mapSideDamagesToCoordinates } from './utils';

type BladeDamagePlotProps = {
  bladeSide: BladeSide;
  damages: AtlasGqlHorizonDamage[];
  shapeWidths: number[];
  bladeLength: number;
  width: number;
  height: number;
};

const [marginTop, marginRight, marginBottom, marginLeft] = [2, 2, 20, 20];

export const BladeDamagePlot: React.FunctionComponent<BladeDamagePlotProps> = ({
  bladeSide,
  damages = [],
  shapeWidths,
  bladeLength,
  width,
  height,
}) => {
  // Projects a set of blade shape widths by percent radial distance to [x,y] coordinate pairs
  const { shapePoints, maxBladeWidth }: { shapePoints: [number, number][]; maxBladeWidth: number } =
    useMemo(() => {
      const maxBladeWidth = Math.max(...shapeWidths);
      return {
        shapePoints: [BladeSide.LeadingEdge, BladeSide.TrailingEdge].includes(bladeSide)
          ? []
          : shapeWidths.map((w, i) => [(i * bladeLength) / 100, (100 * w) / maxBladeWidth]),
        maxBladeWidth,
      };
    }, [shapeWidths, bladeSide]);

  /**
   * Project damages with distance and chord attributes to [x,y] coordinate pairs. Any damages without
   * distance, and any PS/SS damages without chord will be omitted. (see ./utils for detail)
   */
  const damagePoints: [number, number][] = useMemo(
    () =>
      damages
        .map(
          [BladeSide.LeadingEdge, BladeSide.TrailingEdge].includes(bladeSide)
            ? mapEdgeDamagesToCoordinates
            : mapSideDamagesToCoordinates(shapeWidths, maxBladeWidth, bladeLength)
        )
        .filter((point): point is [number, number] => !!point),
    [bladeSide, damages, shapeWidths]
  );

  // Create d3 scales for radial distance (x) and chord % (y)
  const xScale = useMemo(
    () =>
      d3
        .scaleLinear()
        .domain([0, bladeLength])
        .range([marginLeft, width - marginRight]),
    [bladeLength, width]
  );
  const yScale = useMemo(
    () =>
      d3
        .scaleLinear()
        .domain([0, 100])
        .range(
          bladeSide === BladeSide.SuctionSide
            ? [marginTop, height - marginBottom]
            : [height - marginBottom, marginTop]
        ),
    [bladeSide, height]
  );

  return (
    <svg key={`${bladeSide}${width}${height}`} viewBox={`0 0 ${width} ${height}`}>
      <Axes
        xScale={xScale}
        yScale={yScale}
        bladeSide={bladeSide}
        maxWidth={maxBladeWidth}
        height={height}
      />
      {!!shapePoints.length &&
      [BladeSide.PressureSide, BladeSide.SuctionSide].includes(bladeSide) ? (
        <SideShape xScale={xScale} yScale={yScale} points={shapePoints} />
      ) : (
        <EdgeShape width={width} height={height} />
      )}
      <g fill="none" stroke="currentColor" strokeWidth="1.5">
        {damagePoints.map((point, index) => (
          <circle key={index} cx={xScale(point[0])} cy={yScale(point[1])} r="2.5" />
        ))}
      </g>
      <g transform={`translate(${xScale.range()[1] - 30}, 12)`} fill="#0000008C">
        <text
          style={{
            fontSize: '10px',
            textAnchor: 'middle',
          }}
        >
          {bladeSide}
        </text>
      </g>
    </svg>
  );
};
