import { useEffect, useState } from 'react';

import { Button, Collapse, List } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { orderBy } from 'lodash';

import {
  AtlasGqlHorizonDamage,
  AtlasGqlHorizonDamageObservation,
  AtlasGqlHorizonDamageObservationGroup,
  AtlasGqlHorizonDamagePropagation,
  useGetHorizonDamagePropagationsLazyQuery,
} from 'types/atlas-graphql';

import { DamageListItem } from './DamageListItem';
import { DamageSummary } from './DamageSummary';
import { DamageDetailObservationListItem } from './DamageDetailObservationListItem';
import {
  DetailCard,
  DetailContainer,
  InspectionCollapse,
} from '../ComparerPane/ComparerPane.style';

const { Panel } = Collapse;

type DamageComparerDetailProps = {
  paneSide: 'left' | 'right';
  damages: AtlasGqlHorizonDamage[];
  disabledDamageIds: string[];
  selectedDamage: AtlasGqlHorizonDamage;
  selectedObservation: AtlasGqlHorizonDamageObservation;
  setSelectedDamageId: (id: string) => void;
  setSelectedObservation: (o: AtlasGqlHorizonDamageObservation) => void;
};

export const DamageComparerDetail: React.FunctionComponent<DamageComparerDetailProps> = ({
  paneSide,
  damages,
  disabledDamageIds,
  selectedDamage,
  selectedObservation,
  setSelectedDamageId,
  setSelectedObservation,
}) => {
  const [observationsOpen, setObservationsOpen] = useState<boolean>(true);

  const [getPropagationsForDamage, { data: propagationData }] =
    useGetHorizonDamagePropagationsLazyQuery();
  const propagations: AtlasGqlHorizonDamagePropagation[] = (
    propagationData?.getHorizonDamage?.propagations ?? []
  )
    .filter(Boolean)
    .map(p => p as AtlasGqlHorizonDamagePropagation);

  useEffect(() => {
    getPropagationsForDamage({
      variables: { input: { id: selectedDamage.id } },
    });
    setObservationsOpen(true);
  }, [selectedDamage]);

  return (
    <DetailContainer>
      <DetailCard title={'Damages'} size="small" width="33%">
        <List
          style={{ width: '100%' }}
          size="small"
          dataSource={damages}
          renderItem={(d: AtlasGqlHorizonDamage) => (
            <DamageListItem
              key={d.id}
              damage={d}
              disabled={disabledDamageIds.includes(d.id)}
              selected={d.id === selectedDamage.id}
              onClick={() => setSelectedDamageId(d.id)}
            />
          )}
        />
      </DetailCard>
      <DetailCard
        title={'Damage Details'}
        size="small"
        actions={[
          <Button
            key={'collapse-button'}
            className="collapse-button"
            onClick={() => setObservationsOpen(!observationsOpen)}
            icon={observationsOpen ? <RightOutlined /> : <LeftOutlined />}
          />,
        ]}
        $hasCollapseAction={true}
      >
        <DamageSummary damage={selectedDamage} observationsOpen={observationsOpen} />
        {selectedDamage.observationGroups && (
          <DetailCard
            title={observationsOpen ? 'Observations' : ''}
            size="small"
            width={observationsOpen ? '50%' : '54px'}
            style={{ position: 'sticky', top: 0 }}
          >
            {observationsOpen && (
              <InspectionCollapse small>
                {orderBy(
                  selectedDamage.observationGroups ?? [],
                  [og => new Date(og?.observations?.[0]?.inspection?.inspectionDate ?? '')],
                  ['desc']
                ).map(({ observations }: AtlasGqlHorizonDamageObservationGroup, key) => {
                  const sortedObservations = orderBy(
                    observations,
                    [observation => observation?.attrs?.Severity ?? 0],
                    ['desc']
                  );

                  const inspection = observations?.[0]?.inspection;
                  const panelHeader = `Inspection Date: ${inspection?.inspectionDate ?? 'Unknown'}`;

                  return (
                    <Panel key={key} header={panelHeader}>
                      <List
                        size="small"
                        dataSource={sortedObservations}
                        renderItem={(o: AtlasGqlHorizonDamageObservation) => (
                          <DamageDetailObservationListItem
                            key={o.id}
                            observation={o}
                            propagation={propagations?.find(
                              ({ targetObservation }: AtlasGqlHorizonDamagePropagation) =>
                                targetObservation?.id === o.id
                            )}
                            selected={o.id === selectedObservation.id}
                            onClick={() => setSelectedObservation(o)}
                          />
                        )}
                      />
                    </Panel>
                  );
                })}
              </InspectionCollapse>
            )}
          </DetailCard>
        )}
      </DetailCard>
    </DetailContainer>
  );
};
