import { createContext, useState, useEffect, useMemo } from 'react';

import { isEmpty } from 'lodash';
import type { DocumentNode } from '@apollo/client';
import { TableProps } from 'antd/lib/table/Table';
import { ColumnGroupType, ColumnType } from 'antd/lib/table';
import { CardType, CardTabListType } from 'antd/lib/card';
import { TabBarExtraContent } from 'rc-tabs/lib/interface';

import { useDefaultState } from './useDefaultState';
import { DataTable } from './DataTable';
import { genericClientTableStorageIds, tableStorage } from './utils';

import { ESortOptions, EFilterSortClearType, TDataTableContext, TPersistedState } from './types';
import { AtlasGqlTableView } from 'types/atlas-graphql';

type ColumnsType = ((ColumnGroupType<unknown> | ColumnType<unknown>) & {
  additionalFilterFunctions?: {
    [key: string]: (value: string | any[], record: unknown) => boolean;
  };
})[];

export type PresetFilterType = { value?: { sortOrder?: { columnKey: string; order: string } } } & {
  value?: { filters?: { [x: string]: any } };
} & { key?: string };

export interface DataTableProps extends Omit<TableProps<any>, 'title' | 'columns'> {
  actions?: JSX.Element;
  additionalDropdownActions?: any[];
  additionalDropdownViews?: any[];
  cardType?: CardType;
  columns?: ColumnsType;
  controls?: JSX.Element;
  dataQuery?: DocumentNode;
  setLoading?: (loading: boolean) => void;
  setTotalCount?: (totalCount: number) => void;
  data?: TableProps<any>['dataSource'];
  clearSortAndFilterType?: EFilterSortClearType;
  disableUserSort?: boolean;
  fillWidth?: boolean;
  hasServerDataTableContextProviderParent?: boolean; // pass as true when ServerDataTableContext is already provided in the component tree
  id?: string;
  initialPresetFilter?: PresetFilterType;
  initialSort?: { key: string; sort: ESortOptions };
  onClick?: (record: any) => void;
  onDataQueryChange?: (args: any) => void;
  onSavePriority?: (prioritizedItems: any[]) => void;
  onTabChange?: (key: string) => void;
  persistTableState?: boolean;
  renderInContext?: React.ReactNode; // render the node within the `DataTableContext.Provider` context
  renderInServerContext?: React.ReactNode; // render the node within the `ServerDataTableContext.Provider` context
  routeProps?: any;
  rowExpandable?: (record: any) => boolean; // Used by antd to determine if you can expand a row or not.
  setShouldShowMultisort?: React.Dispatch<React.SetStateAction<boolean>>;
  scrollWidth?: number;
  showSavedViews?: boolean;
  showColumnPicker?: boolean;
  showMultiSorter?: boolean;
  savedViewCustomFields?: any;
  onSavedViewChange?: (tableView: AtlasGqlTableView) => void;
  storageKey?: { COLUMN_PICKER: string; MULTI_SORT: string };
  tabList?: CardTabListType[];
  tabBarExtraContent?: TabBarExtraContent;
  tabBarPriorityContent?: TabBarExtraContent;
  title?: React.ReactNode;
  transform?: (data: any) => { items?: any[]; totalCount?: number };
  isChangePriorityVisible?: boolean;
  // Input type has to be any, because the fields depend on the columns defined for the table
  onFiltersChange?: (filters: any) => void;
  isExpandAllVisible?: boolean;
  handleExpandAll?: () => void;
  isExpandAllEnabled?: boolean;
  allExpanded?: boolean;
}

const NOOP = () => {};
const GENERIC_HANDLER = (arg: any) => {};

export const DataTableContext = createContext<TDataTableContext>({
  initialRouteVariables: {},
  initialPagination: {},
  pagination: { pageSize: 10, current: 1 },
  updatePagination: GENERIC_HANDLER,
  filteredInfo: undefined,
  updateFilteredInfo: GENERIC_HANDLER,
  sortedInfo: undefined,
  updateSortedInfo: GENERIC_HANDLER,
  customizedColumnSet: undefined,
  updateCustomizedColumnSet: GENERIC_HANDLER,
  multiSortDef: undefined,
  updateMultiSortDef: GENERIC_HANDLER,
  tableView: undefined,
  updateTableView: GENERIC_HANDLER,
  setPersistedState: GENERIC_HANDLER,
});

export const DataTableContextProvider: React.FunctionComponent<DataTableProps> = props => {
  const { defaultPagination, defaultFilteredInfo, defaultSortOrder, defaultRouteVariables } =
    useDefaultState(props);
  const { id: tableId } = props;

  const {
    get: getPersistedState = NOOP,
    set: setPersistedState = NOOP,
  }: {
    get: typeof NOOP | (() => TPersistedState);
    set: typeof NOOP | ((value: TPersistedState) => void);
  } = tableStorage({ storageKey: tableId });

  // FIXME: Certain tables include different sub-records depending on the active parent record.
  // We need more sophisticated storage management to persist pagination correctly for each parent.
  // For now we can correctly persist initial pagination for generic client tables in the list. - AN 8/20/24
  const shouldPersistInitialPagination = tableId
    ? genericClientTableStorageIds.includes(tableId)
    : undefined;

  const persistedState = getPersistedState();

  const [tableView, setTableView] = useState<any | undefined>();
  const [customizedColumnSet, setCustomizedColumnSet] = useState<unknown[] | undefined>();
  const [multiSortDef, setMultiSortDef] = useState<unknown[] | undefined>();

  const [filteredInfo, setFilteredInfo] = useState(
    persistedState?.filteredInfo ?? defaultFilteredInfo
  );
  const [sortedInfo, setSortedInfo] = useState(persistedState?.sortedInfo ?? defaultSortOrder);
  const [initialPagination] = useState(
    shouldPersistInitialPagination && persistedState?.pagination
      ? persistedState.pagination
      : defaultPagination
  );
  const [pagination, setPagination] = useState({
    // since persisted state (which comes from localStorage) cannot persist
    // functions and pagination options may contain functions (e.g. showTotal),
    // we need to spread the default options and let persisted options override
    // where necessary
    ...defaultPagination,
    ...persistedState?.pagination,
  });
  // FIXME ARS - really hope this can go away after the AssetChooser is included in the DataTabe
  const [initialRouteVariables] = useState(defaultRouteVariables);

  // Some values in pagination get updated async, specifically total, which we only know once we've received the query response.
  // So we need another callback to make sure we save the most recent version of everything to state.
  useEffect(() => {
    setPersistedState({ filteredInfo, sortedInfo, pagination });
  }, [pagination]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (persistedState?.filteredInfo && props?.onFiltersChange) {
      props.onFiltersChange(persistedState.filteredInfo);
    }
    // We only care about persistedState and props' initial values,
    // but we need to re-run this if the data changes in case onFilters change expects to operate on the most current data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  const contextValue = useMemo(
    () => ({
      initialRouteVariables,
      initialPagination,
      pagination,
      updatePagination: (newPagination: any) => {
        if (isEmpty(newPagination)) {
          setPagination(false);
        } else {
          setPagination((prevState: any) => {
            return {
              ...prevState,
              ...newPagination,
            };
          });
        }
      },
      filteredInfo,
      updateFilteredInfo: setFilteredInfo,
      sortedInfo,
      updateSortedInfo: setSortedInfo,
      customizedColumnSet,
      updateCustomizedColumnSet: setCustomizedColumnSet,
      multiSortDef,
      updateMultiSortDef: setMultiSortDef,
      tableView,
      updateTableView: setTableView,
      setPersistedState,
    }),
    [customizedColumnSet, tableView, multiSortDef, filteredInfo, sortedInfo, pagination]
  );

  return (
    <DataTableContext.Provider value={contextValue}>
      {props.children}
      {props.renderInContext}
    </DataTableContext.Provider>
  );
};

export default function DataTableWithContext(props: DataTableProps) {
  return (
    <DataTableContextProvider {...props}>
      <DataTable {...props} />
      {props.renderInContext}
    </DataTableContextProvider>
  );
}

export { DataTable as DataTableWithoutContext };
