import { useReducer } from 'react';

import { Spinner } from 'components/Spinner';
import { AtlasGqlHorizonDamage, useGetHorizonDamagesWithPicturesQuery } from 'types/atlas-graphql';
import { DamageComparerPane } from './DamageComparerPane';
import { ComparerContainer, ComparerModal } from '../CompareOTron.style';

/**
 * This component is deprecated and will be removed during cleanup of
 * rtv1:new_image_viewer
 *
 * Further maintenance should not be needed, check with Riley with any questions.
 */

type DamageComparerProps = {
  damageIds: string[];
  isOpen: boolean;
  onClose: () => void;
};

const damageComparerReducer = (
  { leftDamageId, rightDamageId }: { leftDamageId: string; rightDamageId: string },
  action: Partial<{ leftDamageId: string; rightDamageId: string }>
): { leftDamageId: string; rightDamageId: string } => {
  if (action.leftDamageId && action.leftDamageId !== rightDamageId) {
    return { leftDamageId: action.leftDamageId, rightDamageId };
  }
  if (action.rightDamageId && action.rightDamageId !== leftDamageId) {
    return { leftDamageId, rightDamageId: action.rightDamageId };
  }
  return { leftDamageId, rightDamageId };
};

export const DamageComparer: React.FunctionComponent<DamageComparerProps> = ({
  damageIds,
  isOpen,
  onClose,
}) => {
  const { data: damageData, loading } = useGetHorizonDamagesWithPicturesQuery({
    variables: { input: { ids: damageIds } },
    skip: !isOpen,
  });

  const damages: AtlasGqlHorizonDamage[] = (damageData?.getHorizonDamages?.items ?? []).filter(
    Boolean
  ) as AtlasGqlHorizonDamage[];

  const [{ leftDamageId, rightDamageId }, dispatch] = useReducer(damageComparerReducer, {
    leftDamageId: damageIds[0],
    rightDamageId: damageIds[1],
  });

  return (
    <ComparerModal
      visible={isOpen}
      onCancel={onClose}
      footer={null}
      destroyOnClose
      bodyStyle={{ height: '100%', width: '100%' }}
    >
      {loading ? (
        <Spinner />
      ) : (
        <ComparerContainer>
          <DamageComparerPane
            paneSide="left"
            damages={damages}
            selectedDamageId={leftDamageId}
            setSelectedDamageId={id => dispatch({ leftDamageId: id })}
            disabledDamageIds={[rightDamageId]}
          />
          <DamageComparerPane
            paneSide="right"
            damages={damages}
            selectedDamageId={rightDamageId}
            setSelectedDamageId={id => dispatch({ rightDamageId: id })}
            disabledDamageIds={[leftDamageId]}
          />
        </ComparerContainer>
      )}
    </ComparerModal>
  );
};
