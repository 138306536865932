import { useState } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { usePermissions } from 'utils/usePermissions';
import { DAMAGE_EDIT_ATTRIBUTES } from 'utils/access-control/rules';

import DashboardCard from 'components/DashboardCard';
import { DamageAttributesController } from './DamageAttributesController';
import { MODES } from './DamageAttributes';

const StyledDashboardCard = styled(DashboardCard)`
  height: 100%;
`;

const StyledButton = styled(Button)`
  padding: 0 0.5rem !important;
`;

// since this Card needs a header with actions (and state) we extend upon on it here
export function DamageAttributesCard({
  damage,
  schemaId,
  potentialAttrs,
  className = null,
  title = 'Detail',
  hideDetails,
}) {
  const [mode, setMode] = useState(MODES.VIEW);
  const { CanRender } = usePermissions();

  const CardActions = () => {
    return mode === MODES.VIEW && !hideDetails ? (
      <StyledButton
        data-testid="attr-edit-button"
        icon={<EditOutlined />}
        onClick={() => setMode(MODES.EDIT)}
      >
        Edit
      </StyledButton>
    ) : null;
  };

  return (
    <StyledDashboardCard
      className={className}
      title={title}
      actions={
        <CanRender rules={[DAMAGE_EDIT_ATTRIBUTES]}>
          <CardActions />
        </CanRender>
      }
    >
      <DamageAttributesController
        loading={!damage?.id}
        damage={damage}
        schemaId={schemaId}
        potentialAttrs={potentialAttrs}
        mode={mode}
        setMode={setMode}
        hideDetails={hideDetails}
      />
    </StyledDashboardCard>
  );
}
