import { AtlasGqlHorizonDamage } from 'types/atlas-graphql';
import { isFinite } from 'lodash';

export const getPlotDimensions = (
  plotCount: number,
  index: number
): { width: number; height: number } => {
  if ((plotCount === 3 && index === 2) || plotCount === 2) {
    return { width: 1000, height: 200 };
  }
  if (plotCount === 1) {
    return { width: 1000, height: 400 };
  }
  return { width: 500, height: 200 };
};

/**
 * Map function for getting coordinates for edge damages: in these cases, this is simply
 * radial distance (x) and 50% chord (y) to show all damages along a single line in the y axis.
 * Damages without distance will return null.
 */
export const mapEdgeDamagesToCoordinates = ({
  primaryObservationGroupAttrs,
}: AtlasGqlHorizonDamage) =>
  isFinite(primaryObservationGroupAttrs['Distance'])
    ? [primaryObservationGroupAttrs['Distance'], 50]
    : null;

/**
 * Map function for getting coordinates for side damages: given distance, chord, max blade width,
 * and blade length, returns coordinates of radial distance (x) and chord position (y), projected
 * from the distance and relative blade width at that distance.
 * Damages without distance or chord will return null.
 */
export const mapSideDamagesToCoordinates =
  (widths: number[], maxWidth: number, length: number) =>
  ({ primaryObservationGroupAttrs }: AtlasGqlHorizonDamage): [number, number] | null => {
    const { Distance: distance, Chord: chord } = primaryObservationGroupAttrs;

    if (isFinite(distance) && isFinite(chord)) {
      const widthAtDistance = widths[Math.round((100 * distance) / length)];
      const projectedChord = (widthAtDistance / maxWidth) * chord;

      return [distance, projectedChord];
    }
    return null;
  };
