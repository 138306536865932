import { useCallback } from 'react';
import { HomeOutlined } from '@ant-design/icons';
import { Button } from 'antd';

export const ResetControl = ({ onChange }) => {
  const handleReset = useCallback(() => {
    onChange({ reset: true });
  }, [onChange]);

  return (
    <Button id="ol-control-reset" title="Reset" onClick={handleReset}>
      <HomeOutlined />
    </Button>
  );
};
