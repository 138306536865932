import React, { useCallback, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { doesUserHaveReleaseToggle } from '@skyspecs/release-utils-client';
import { useAtom } from 'jotai';
import { useAtomCallback } from 'jotai/utils';
import { currentCustomerAtom, activeCustomerAtom, releaseTogglesAtom, featuresAtom } from './atoms';
import { CI_MERGE_REQUEST_IID, DEPLOY_ENV } from '../env';
import {
  AtlasGqlOrgFragmentFragment,
  useGetFeatureFlagsQuery,
  useUserDetailsQuery,
} from '../../types/atlas-graphql';
import { Spinner } from 'components/Spinner';
import { AccountContext } from './AccountContext';
import { useCurrentCustomerId } from './hooks';
import { Features, RefetchUserParams } from './types';
import { Customer, User } from 'utils/types';
import { useApolloContext } from 'utils/apollo';
import { PapiGqlCustomerFieldsFragment } from 'types/papi-graphql';
import { notifyBugsnag } from 'utils/bugsnag';
import { setHeapOrganization } from 'utils/heap';
import { setTitle } from 'utils/document';
import { removeItem } from 'utils/storage';
import { AccountDataMessageTypes, channel } from './channels';

export function AccountContextProvider({ children }: React.PropsWithChildren<void>) {
  const [currentCustomerId] = useCurrentCustomerId();
  const {
    customers,
    releaseToggles,
    loading: userLoading,
    user,
    refetchUser,
  } = useUser(currentCustomerId);
  const { hasReleaseToggle } = useReleaseToggles(releaseToggles);
  const { features, loading: featuresLoading } = useFeatures(!currentCustomerId || !user);
  const { customer, setCustomerId } = useCustomer(user);
  const loading = featuresLoading || userLoading;

  const providerValue = useMemo(() => {
    return {
      customer,
      customers,
      features,
      hasReleaseToggle,
      loading,
      setCustomerId,
      // even though this cast may be a lie, the `user` is checked in `useUser`
      // after the query has run. when the `user` is not set (for whatever
      // reason) they will be redirected, so we can safely assume the `user`
      // will be non-null when put in context
      user: user as User,
      refetchUser,
    };
  }, [customer, customers, features, hasReleaseToggle, loading, setCustomerId, user, refetchUser]);

  return (
    <AccountContext.Provider value={providerValue}>
      {providerValue.loading ? <Spinner /> : children}
    </AccountContext.Provider>
  );
}

const scopes: string[] = [DEPLOY_ENV];

if (CI_MERGE_REQUEST_IID) {
  scopes.push(`horizon-${CI_MERGE_REQUEST_IID}`);
}

function useReleaseToggles(toggles?: string[]) {
  const [, setReleaseToggles] = useAtom(releaseTogglesAtom);

  useEffect(() => {
    if (!toggles) return;

    setReleaseToggles(toggles);
  }, [setReleaseToggles, toggles]);

  const hasReleaseToggle = useAtomCallback(
    useCallback((get, _set, releaseToggle: string) => {
      const releaseToggles = get(releaseTogglesAtom);
      return doesUserHaveReleaseToggle({ releaseToggles }, releaseToggle);
    }, [])
  );

  return { hasReleaseToggle };
}

function useFeatures(skip: boolean) {
  // @todo determine if we actually need to store the features in atom since
  // they never change once loaded
  const [, setFeatures] = useAtom(featuresAtom);
  const { data, loading } = useGetFeatureFlagsQuery({ skip });
  const features = useMemo<Features>(
    () => ({
      AUTONOMOUS_DRONE_UPLOAD: ['stage', 'dev'].includes(DEPLOY_ENV),
      ...data?.features,
    }),
    [data]
  );

  useEffect(() => {
    if (!loading) {
      setFeatures(features);
    }
  }, [features, loading, setFeatures]);

  return { features, loading };
}

function useUser(currentCustomerId: string | undefined): {
  customers: (AtlasGqlOrgFragmentFragment | PapiGqlCustomerFieldsFragment)[];
  releaseToggles: string[];
  loading: boolean;
  user: User | null | undefined;
  refetchUser: (params: RefetchUserParams) => void;
} {
  const { replace } = useHistory();
  const { resetCache } = useApolloContext();
  const { data, error, loading, refetch } = useUserDetailsQuery({
    skip: !currentCustomerId,
    variables: { scopes },
  });

  // This useMemo handles determining the user data to return. It also handles
  // redirecting the user to the login page if there is an error with the user
  // query.
  const { customerIds, ...content } = useMemo(() => {
    const customerNameSort = (a: { name?: string | null }, b: { name?: string | null }) => {
      if (a.name && b.name) {
        return a.name.localeCompare(b.name);
      }
      return 0;
    };

    if (!currentCustomerId || loading) {
      return {
        customerIds: [] as string[],
        customers: [] as Customer[],
        loading: true,
        user: undefined,
        releaseToggles: [] as string[],
      };
    }

    // to avoid infinite redirects due to invalid data or user, simply log the
    // user out when there's an error with the user query
    if ((error && !data?.me) || !data?.me) {
      console.error('Problem retrieving user data: ', { error });
      notifyBugsnag(`Problem retrieving user data: ${error?.message}`);
      replace('/logout');
      // This error shouldn't get thrown, because the user should be redirected
      // to the login page before this point.
      throw new Error('Error retrieving user data.');
    }

    const releaseToggles = data?.me?.releaseToggles ?? [];
    const user: User = {
      ...data.me,
      roles: [],
    };
    const customerIds = data.me.organizations.map(o => o.id);
    const customers = [...data.me.organizations]
      .sort(customerNameSort)
      .map(x => ({ ...x, __typename: 'Customer' as 'Customer' }));
    const role = data.userOrganization?.roles?.horizon;

    if (role) {
      user.roles.push(role);
    }

    return { customerIds, customers, loading, user: { ...user, customers }, releaseToggles };
  }, [currentCustomerId, loading, error, replace, data]);

  const { user } = content;

  // This useEffect handles redirecting the user to the login page if they
  // don't have access to the current customer.
  useEffect(() => {
    if (!currentCustomerId || loading || !user) return;

    // validate that the user has access to the specified customer. when access
    // is not available, reset the user to view their base organization
    if (customerIds.length > 0 && !customerIds.includes(currentCustomerId)) {
      message.error('Access to customer is not allowed. Redirecting...');
      removeItem('customerId');
      resetCache();

      const timeout = setTimeout(() => {
        // since the customerId has been removed from local storage, we are
        // resetting to the default customer.
        // note that we want to use the `replace` off the window.location
        // directly and not the one from `useHistory` to get a full page reload.
        window.location.replace('/reset');
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [customerIds, currentCustomerId, error, loading, replace, resetCache, user]);

  // used in situations where we want to update the user data after app load
  // publish = true will publish a message to other open tabs and windows causing them to refetch
  const refetchUser = useCallback(
    ({ publish = false }: RefetchUserParams) => {
      refetch();
      if (publish) {
        channel.postMessage({
          type: AccountDataMessageTypes.USER_DATA_REFETCH,
        });
      }
    },
    [refetch]
  );

  return { ...content, refetchUser };
}

export function useCustomer(user: User | null | undefined): {
  customer: Customer | null | undefined;
  setCustomerId: (customerId: string, setActive?: boolean) => void;
} {
  const [currentCustomerId] = useCurrentCustomerId();

  const findCustomer = useCallback(
    (id: string | undefined) => (id ? user?.customers?.find(c => c.id === id) : undefined),
    [user?.customers]
  );

  const setCustomerId = useAtomCallback(
    useCallback(
      (_get, set, newCustomerId: string) => {
        const selectedCustomer = findCustomer(newCustomerId);

        if (selectedCustomer?.id !== newCustomerId) {
          console.error('Unable to update selected customer.');
          return;
        }

        set(currentCustomerAtom, newCustomerId);
        set(activeCustomerAtom, newCustomerId);
        setHeapOrganization({ orgId: newCustomerId, orgName: selectedCustomer.name });
        setTitle(selectedCustomer?.name ?? null);
      },
      [findCustomer]
    )
  );

  const customer = useMemo(
    () => findCustomer(currentCustomerId),
    [currentCustomerId, findCustomer]
  );

  useEffect(() => {
    if (currentCustomerId && user) {
      // the `currentCustomerId` (in local storage) is initially set in Session,
      // but we still need to sync session storage with local storage plus
      // initialize heap and set the browser title (in `setCustomerId`)
      setCustomerId(currentCustomerId);
    }
    // we only want to initialize the customer on initial user load, so we only
    // need to add the user to the dependency array. adding `currentCustomerId`
    // would cause this to get run each time the current customer ID changes,
    // which we don't want, especially when the user is switching customers.
    // eslint-disable-next-line
  }, [user?.id]);

  return { customer, setCustomerId };
}
