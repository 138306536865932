import { Fragment, useMemo } from 'react';
import { TeamOutlined } from '@ant-design/icons';
import { Dropdown, Button, Spin } from 'antd5';
import type { MenuProps } from 'antd5';
import { MenuInfo } from 'rc-menu/lib/interface';
import { useAccountContext } from 'utils/account/AccountContext';
import { useChangeCustomer } from 'utils/hooks';

export const CustomerSwitcher: React.FunctionComponent = () => {
  const { customer, customers, loading, refetchUser } = useAccountContext();
  const changeCustomer = useChangeCustomer({ resetWithoutCustomRedirect: true });

  const customerName = (
    <Fragment>
      <TeamOutlined />
      &nbsp;
      {loading ? <Spin size="small" style={{ marginLeft: 8 }} /> : customer?.name}
    </Fragment>
  );

  const customerChoices: MenuProps['items'] = useMemo(() => {
    // this early return is just so we don't have to assert that customer id and name info will be available.
    if (!customer) return [{ key: '0', label: 'no customer data' }];

    const handleClick = ({ key }: MenuInfo) => {
      const customerId = key as string;

      if (customer && customer.id === customerId) {
        // ignore, we already have this one selected
        return;
      }

      changeCustomer(customerId);
    };

    const currentCustomer = { key: customer.id, label: customer.name, onClick: handleClick };
    const additionalCustomers = customers
      .filter(c => c.id !== customer.id)
      .map(({ name, id }) => {
        return {
          key: id,
          label: name,
          onClick: handleClick,
        };
      });

    if (additionalCustomers.length) {
      return [currentCustomer, { type: 'divider' }, ...additionalCustomers];
    } else {
      return [currentCustomer];
    }
  }, [changeCustomer, customer, customers]);

  // hide switcher if loading or only one customer
  return (
    <span style={{ marginRight: '1em' }}>
      {loading || customers.length === 1 ? (
        customerName
      ) : (
        <Dropdown
          className="testid-customer-switcher"
          menu={{ items: customerChoices }}
          trigger={['hover']}
          onOpenChange={open => {
            if (open) {
              // publish the refetch so other windows have fresh user data
              // this avoids issues with auth changes while multiple windows are open
              refetchUser({ publish: true });
            }
          }}
        >
          <Button type="primary" ghost style={{ border: 'none' }}>
            {customerName}
          </Button>
        </Dropdown>
      )}
    </span>
  );
};
