import { Dispatch, SetStateAction, useMemo, useEffect } from 'react';
import { Select } from 'antd';
import { sortBy } from 'lodash';
import { storage } from 'utils/storage';
import { useAllDamageSchemasQuery } from 'types/atlas-graphql';

const { Option } = Select;

type SchemaSelectProps = {
  selected?: string;
  setSelected: (s: string) => void;
  style?: object;
  alwaysVisible?: boolean;
  storageKey?: string; // Providing storageKey will result in persistence of the selection on an instance of this component
  setSchemaVisible?: Dispatch<SetStateAction<boolean>>; // Used for rendering on parent component
};

export const SchemaSelect: React.FunctionComponent<SchemaSelectProps> = ({
  selected,
  setSelected,
  style,
  alwaysVisible,
  storageKey: _storageKey,
  setSchemaVisible,
}) => {
  const { data, loading } = useAllDamageSchemasQuery();

  const storageKey = _storageKey && `${_storageKey}::${storage.getItem('customerId')}`;

  const damageSchemas = useMemo(() => {
    return data?.damageSchemas || [];
  }, [data]);

  const schemaOptions = useMemo(() => {
    return sortBy(damageSchemas, 'name').map(({ id, name, isDefault }) => (
      <Option key={id} value={id} name={name}>
        {`${name && name.length ? name : 'Damage Schema'}${isDefault ? ' (Default)' : ''}`}
      </Option>
    ));
  }, [damageSchemas]);

  const defaultValue = useMemo(() => {
    if (storageKey) {
      const storedValue = storage.getItem(storageKey);
      if (storedValue) {
        return storedValue;
      }
    }
    return damageSchemas.find(({ isDefault }: any) => {
      return isDefault;
    })?.id;
  }, [damageSchemas, storageKey]);

  // sets the default value into state
  useEffect(() => {
    setSelected(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  // when schema is updated, save it to storage
  useEffect(() => {
    if (storageKey && selected) {
      storage.setItem(storageKey, selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const hasSchemas = !loading && Boolean(damageSchemas.length);

  useEffect(() => {
    if (setSchemaVisible && (alwaysVisible || (hasSchemas && damageSchemas.length > 1)))
      setSchemaVisible(true);
  }, [setSchemaVisible, alwaysVisible, hasSchemas, damageSchemas]);

  return (
    <>
      {(alwaysVisible || (hasSchemas && damageSchemas.length > 1)) && (
        <>
          <Select
            showSearch
            filterOption={(value, option) =>
              value.length > 2 ? option?.name?.toLowerCase().includes(value?.toLowerCase()) : true
            }
            disabled={loading || !hasSchemas}
            style={style || { width: '25em', marginRight: '1em' }}
            defaultValue={defaultValue}
            onSelect={setSelected}
            loading={loading}
            placeholder="Select a damage schema"
            value={selected}
            dropdownMatchSelectWidth={false}
          >
            {schemaOptions}
          </Select>
        </>
      )}
    </>
  );
};
