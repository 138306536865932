import { useState } from 'react';
import { useApolloContext, useMutation } from 'utils/apollo';
import gql from 'graphql-tag';
import { LoadingOutlined } from '@ant-design/icons';
import { message, Menu, Button } from 'antd';

import { DAMAGE_CONFIRM as DAMAGE_CONFIRM_RULE } from 'utils/access-control/rules';
import { usePermissions } from 'utils/usePermissions';

import { CheckIcon, CloseIcon } from 'components/Icons';
import { MenuButton } from 'components/MenuButton';

const SET_DAMAGE_CONFIRMED = gql`
  mutation ConfirmDamage($branchGlobalId: UUID!, $confirm: Boolean!) {
    confirmDamage(branchGlobalId: $branchGlobalId, confirm: $confirm) {
      id
      globalId
      branchGlobalId
      branchSlug
      version
      confirmed
    }
  }
`;

// For now have to support both PAPI & Atlas patterns.
export const ConfirmDamageToggle = ({
  damage: { id, isConfirmed: papiConfirmed = false, confirmed: atlasConfirmed = false },
  type,
  addLeftMargin = true,
  className = '',
}) => {
  const { papiClient } = useApolloContext();
  const isConfirmed = papiConfirmed || atlasConfirmed;

  const [saving, setSaving] = useState(false);
  const { CanRender } = usePermissions();

  const [setConfirmDamage] = useMutation(SET_DAMAGE_CONFIRMED, {
    onCompleted({ confirmDamage: { branchSlug, version, confirmed } }) {
      message.success(
        `Damage ${branchSlug.toUpperCase()}-${version} ${confirmed ? 'confirmed' : 'unconfirmed'}.`
      );
    },
    update(
      _,
      {
        data: {
          confirmDamage: { confirmed },
        },
      }
    ) {
      // Have to reach over to the other apollo client cache for now since
      // this mutation happens on atlasClient but damages live in papiClient
      papiClient.writeFragment({
        id: `Damage:${id}`,
        fragment: gql`
          fragment DamageConfirmed on Damage {
            __typename
            isConfirmed
          }
        `,
        data: {
          isConfirmed: confirmed,
          __typename: 'Damage',
        },
      });
    },
  });

  const handleSetConfirm = async confirm => {
    setSaving(true);
    await setConfirmDamage({
      variables: {
        branchGlobalId: id,
        confirm,
      },
    });
    setSaving(false);
  };

  function render() {
    if (saving) {
      return type === 'menu' ? (
        <Menu.Item disabled>
          <LoadingOutlined style={{ marginRight: '0.5rem' }} />
          Saving...
        </Menu.Item>
      ) : (
        <Button disabled>
          Saving... <LoadingOutlined />
        </Button>
      );
    }

    if (type === 'menu') {
      return isConfirmed ? (
        <MenuButton icon={<CloseIcon />} type="link" onClick={() => handleSetConfirm(false)}>
          Unconfirm Damage
        </MenuButton>
      ) : (
        <MenuButton
          icon={<CheckIcon addLeftMargin={addLeftMargin} className={className} />}
          type="link"
          onClick={() => handleSetConfirm(true)}
        >
          Confirm Damage
        </MenuButton>
      );
    }

    return isConfirmed ? (
      <Button onClick={() => handleSetConfirm(false)}>
        <CloseIcon />
        Unconfirm Damage
      </Button>
    ) : (
      <Button onClick={() => handleSetConfirm(true)} className={className}>
        <CheckIcon addLeftMargin={addLeftMargin} />
        Confirm Damage
      </Button>
    );
  }

  // FIXME: Update all instances of this component to remove `<CanRender .../>` wrapper
  return <CanRender rules={[DAMAGE_CONFIRM_RULE]}>{render()}</CanRender>;
};
