import { useEffect } from 'react';

import { isEmpty } from 'lodash';
import { Button, Input } from 'antd5';
import { Form, message } from 'antd';

import { Modal, ActionButtons } from 'components/Modal';
import { ViewSummary } from './ViewSummary';
import { StyledCollapse } from '../Views.style';

import { ApolloError } from '@apollo/client';
import {
  AtlasGqlTableView,
  useTableViewsQuery,
  useCreateTableViewMutation,
  useUpdateTableViewMutation,
  TableViewsDocument,
  AtlasGqlTableViewsQuery,
} from 'types/atlas-graphql';
import { TDisplayColumnData } from 'components/data/types';

const { Panel } = StyledCollapse;

export enum ESaveOptions {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  COPY = 'COPY',
}

export type SaveViewState = {
  isOpen: boolean;
  tableId?: string;
  tableView?: Partial<AtlasGqlTableView>;
  columnDefs?: TDisplayColumnData[];
  type?: ESaveOptions;
};

type SaveViewModalProps = {
  saveViewState: SaveViewState;
  onClose: () => void;
  onSuccess?: (view: AtlasGqlTableView) => void;
  onError?: (error: ApolloError) => void;
};

export const SaveViewModal: React.FunctionComponent<SaveViewModalProps> = ({
  saveViewState,
  onClose,
  onSuccess,
  onError,
}) => {
  const { isOpen, tableId, tableView, columnDefs, type = ESaveOptions.CREATE } = saveViewState;

  const [form] = Form.useForm();

  // load existing views
  const { data: tableViewsData, loading: tableViewsLoading } = useTableViewsQuery({
    variables: { tableName: tableId },
  });

  // create table view mutation
  const [createTableView, { loading: createTableViewLoading }] = useCreateTableViewMutation({
    onCompleted: data => {
      const createdTableView = data.createTableView;
      if (createdTableView) {
        message.success(`${createdTableView.name} saved successfully`);
        onSuccess && onSuccess(createdTableView);
      }

      form.resetFields();
      onClose();
    },
    onError: error => {
      message.error(`There was an error saving the view. Please try again.`);
      onError && onError(error);

      form.resetFields();
      onClose();
    },
    update: (cache, { data }) => {
      const createdTableView = data?.createTableView;
      if (createdTableView) {
        cache.updateQuery(
          {
            query: TableViewsDocument,
            variables: { tableName: tableId },
          },
          (data: AtlasGqlTableViewsQuery | undefined) => {
            return data
              ? {
                  ...data,
                  tableViews: [...(data.tableViews ?? []), createdTableView],
                }
              : data;
          }
        );
      }
    },
  });

  // update table view mutation
  const [updateTableView, { loading: updateTableViewLoading }] = useUpdateTableViewMutation({
    onCompleted: data => {
      const updatedTableView = data.updateTableView;
      if (updatedTableView) {
        message.success(`${updatedTableView.name} saved successfully`);
        onSuccess && onSuccess(updatedTableView);
      }

      form.resetFields();
      onClose();
    },
    onError: error => {
      message.error(`There was an error saving the view. Please try again.`);
      onError && onError(error);

      form.resetFields();
      onClose();
    },
  });

  // handle submit logic
  const handleSubmit = ({ name: _name }: { name: string }) => {
    const name = _name.trim();
    const { id: viewId, filters, sorts, columns, multiSorts, customFields } = tableView ?? {};

    const viewToSave = {
      ...(!isEmpty(filters) ? { filters } : {}),
      ...(!isEmpty(sorts) ? { sorts } : {}),
      ...(!isEmpty(columns) ? { columns } : {}),
      ...(!isEmpty(multiSorts) ? { multiSorts } : {}),
      ...(!isEmpty(customFields) ? { customFields } : {}),
    };

    if ([ESaveOptions.CREATE, ESaveOptions.COPY].includes(type) && !!tableId) {
      createTableView({
        variables: {
          input: {
            tableName: tableId,
            name,
            ...viewToSave,
          },
        },
      });
    } else if ([ESaveOptions.UPDATE].includes(type) && !!viewId) {
      updateTableView({
        variables: {
          input: {
            id: viewId,
            ...viewToSave,
          },
        },
      });
    }
  };

  // handler to close and reset form
  const onFormClose = () => {
    form.resetFields();
    onClose();
  };

  // update name field
  useEffect(() => {
    if (isOpen && form) {
      form.setFieldsValue({
        name: !!tableView?.name
          ? type === ESaveOptions.UPDATE
            ? tableView.name
            : type === ESaveOptions.COPY
              ? `${tableView.name} - Copy`
              : undefined
          : undefined,
      });
    }
  }, [isOpen, form, tableView?.name, type]);

  return (
    <Modal
      open={isOpen && !!tableView}
      title="Save View"
      onCancel={onClose}
      destroyOnClose
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name="name"
          label="View Name"
          rules={[
            {
              message: 'Input is required.',
              required: true,
              whitespace: true,
              validateTrigger: 'onFinish',
            },
            {
              message: 'Input must be between 3 and 40 characters.',
              min: 3,
              max: 40,
              transform: value => value?.trim(),
              validateTrigger: 'onFinish',
            },
            {
              message: 'There is already a View with this name.',
              transform: value => value?.trim(),
              validator(_, value) {
                if (
                  type !== ESaveOptions.UPDATE &&
                  (tableViewsData?.tableViews ?? [])
                    .map(tableView => tableView.name?.toLowerCase())
                    .includes(value?.toLowerCase())
                ) {
                  return Promise.reject();
                } else {
                  return Promise.resolve();
                }
              },
              validateTrigger: 'onFinish',
            },
          ]}
        >
          <Input disabled={type === ESaveOptions.UPDATE} placeholder="Enter a view name" />
        </Form.Item>
        <StyledCollapse>
          <Panel key={'view_conditions'} header="View Conditions">
            {tableView && columnDefs && (
              <ViewSummary tableView={tableView} columnDefs={columnDefs} />
            )}
          </Panel>
        </StyledCollapse>
        <ActionButtons>
          <Button onClick={onFormClose}>Cancel</Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={tableViewsLoading || createTableViewLoading || updateTableViewLoading}
          >
            Save
          </Button>
        </ActionButtons>
      </Form>
    </Modal>
  );
};
