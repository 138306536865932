import { useState } from 'react';
import { CloseOutlined, CloudUploadOutlined } from '@ant-design/icons';

import { PulseButton } from './GlobalProgressButton.style';
import { FileUploadToast } from 'components/FileUploader/Modal/FileUploadToast';
import { useUploadContext } from 'components/FileUploader';
import { useGenericProgressContext } from './GenericProgress/GenericProgressContext';
import { GenericProgressToast } from './GenericProgress/GenericProgressToast';
import { ProgressContainer } from './GenericProgress/GenericProgressToast.style';
import { Button } from 'antd';

export function GlobalProgressButton() {
  const { progressDetails } = useGenericProgressContext();
  const { setModalVisible, uploading } = useUploadContext();
  const [progressVisible, setProgressVisible] = useState<boolean>(false);

  return (
    <>
      <PulseButton
        viewingProgress={progressVisible}
        icon={<CloudUploadOutlined />}
        shape="circle"
        title="Uploads in progress"
        onClick={() => setProgressVisible(!progressVisible)}
      />
      {progressVisible && (
        <ProgressContainer>
          <Button
            icon={<CloseOutlined />}
            size="small"
            title="close"
            onClick={() => setProgressVisible(false)}
          />
          {Object.entries(progressDetails).map(([_, d], i) => (
            <GenericProgressToast key={i} {...d} />
          ))}
          {uploading && <FileUploadToast showModal={() => setModalVisible(true)} />}
        </ProgressContainer>
      )}
    </>
  );
}
