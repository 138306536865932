import { Fragment } from 'react';
import { Button } from 'antd';

export function PrioritizeButtons({ rowReordering }) {
  const {
    rowCount,
    priorityLoading,
    disableSavePriority,
    onSavePriority,
    onCancelPriority,
    onOpenPriority,
    isPrioritizing,
  } = rowReordering || {};

  return onSavePriority && rowCount > 1 ? (
    isPrioritizing ? (
      <Fragment>
        <Button onClick={onCancelPriority} disabled={priorityLoading}>
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={onSavePriority}
          loading={priorityLoading}
          disabled={disableSavePriority}
        >
          Save Priority
        </Button>
      </Fragment>
    ) : (
      <Button onClick={onOpenPriority}>Change Priority</Button>
    )
  ) : null;
}
