import { Fragment, useState } from 'react';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Form, Button, Select, Radio } from 'antd';
import { FormActions, FormActionsRight, StyledSelectWrapper } from './MultiSorter.style';

export function MultiSorter({ columnDefs = [], multiSortDef, onClose }) {
  const [sorts, setSorts] = useState(getInitialSort());
  const sortedKeys = sorts.map(s => s.key).filter(Boolean);
  const availableColumns = columnDefs.filter(
    col => col.sorter && col.get && !sortedKeys.includes(col.key)
  );

  function getSortDefault() {
    return [...Array(6)].map(_ => ({ key: undefined, sort: 'ASC' }));
  }

  function getInitialSort() {
    const sortDefaults = getSortDefault();
    return multiSortDef
      ? sortDefaults.map((sort, idx) => {
          if (multiSortDef[idx]) {
            return { key: multiSortDef[idx].column.key, sort: multiSortDef[idx].sortDirection };
          }
          return sort;
        })
      : sortDefaults;
  }

  function handleStateUpdate(value, index, key) {
    setSorts(prevSorts =>
      prevSorts.map((sort, idx) => {
        return idx === index
          ? {
              ...sort,
              [key]: value,
            }
          : sort;
      })
    );
  }

  function handleSave() {
    const sortedColumns = sorts.filter(sort => sort.key);
    onClose({ sortedColumns });
  }

  function handleReset() {
    setSorts(getSortDefault());
  }

  const columnLabels = columnDefs.reduce((obj, col) => {
    if (col.key) {
      obj[col.key] = col.title;
    }

    return obj;
  }, {});

  return (
    <Fragment>
      <Form>
        {sorts.map((s, index) => {
          return (
            <StyledSelectWrapper key={index}>
              <label htmlFor={`sort-col-${index}`}>Sort by:</label>
              <Select
                id={`sort-col-${index}`}
                allowClear={index === sorts.length - 1 || !sorts[index + 1].key}
                placeholder="Column"
                style={{ width: '100%' }}
                value={columnLabels[sorts[index].key]}
                onChange={value => handleStateUpdate(value, index, 'key')}
                disabled={index > 0 && !sorts[index - 1].key}
              >
                {availableColumns.map(col => (
                  <Select.Option key={col.key} value={col.key} label={col.title}>
                    {col.title}
                  </Select.Option>
                ))}
              </Select>
              <Radio.Group
                style={{ width: '120px' }}
                disabled={!s.key}
                onChange={e => handleStateUpdate(e.target.value, index, 'sort')}
                defaultValue={'ASC'}
                value={sorts[index].sort}
              >
                <Radio.Button value="ASC">
                  <CaretUpOutlined />
                </Radio.Button>
                <Radio.Button value="DESC">
                  <CaretDownOutlined />
                </Radio.Button>
              </Radio.Group>
            </StyledSelectWrapper>
          );
        })}
        <FormActions>
          <Button onClick={handleReset}>Reset</Button>
          <FormActionsRight>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={handleSave} data-testid="multi-sort-btn">
              Save
            </Button>
          </FormActionsRight>
        </FormActions>
      </Form>
    </Fragment>
  );
}
