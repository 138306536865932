import {
  AtlasGqlConfirmationStatus,
  AtlasGqlHorizonDamage,
  useSetHorizonDamageConfirmationStatusMutation,
} from 'types/atlas-graphql';

import { Button, message } from 'antd';
import { CheckIcon, CloseIcon } from 'components/Icons';
import { formatDamageId } from 'horizon/components/Damages/utils';

interface ConfirmDamageProps {
  damage: AtlasGqlHorizonDamage;
}

export const ConfirmDamage: React.FunctionComponent<ConfirmDamageProps> = ({ damage }) => {
  const [setConfirmationStatus, { loading }] = useSetHorizonDamageConfirmationStatusMutation({
    onCompleted: data => {
      const damageId = data.setHorizonDamageConfirmationStatus?.id;
      const confirmationStatus = data.setHorizonDamageConfirmationStatus?.confirmationStatus;

      message.success(
        `Damage ${formatDamageId({ id: damageId })} ${
          confirmationStatus === AtlasGqlConfirmationStatus.Confirmed ? 'confirmed' : 'unconfirmed'
        }.`
      );
    },
  });

  const handleSetConfirmationStatus = (confirmationStatus: AtlasGqlConfirmationStatus) => {
    setConfirmationStatus({
      variables: {
        input: {
          id: damage.id,
          confirmationStatus,
        },
      },
    });
  };

  return (
    <>
      {damage.confirmationStatus === AtlasGqlConfirmationStatus.Confirmed ? (
        <Button
          icon={<CloseIcon />}
          onClick={() => handleSetConfirmationStatus(AtlasGqlConfirmationStatus.NotConfirmed)}
          loading={loading}
        >
          Unconfirm Damage
        </Button>
      ) : (
        <Button
          icon={<CheckIcon />}
          onClick={() => handleSetConfirmationStatus(AtlasGqlConfirmationStatus.Confirmed)}
          loading={loading}
        >
          Confirm Damage
        </Button>
      )}
    </>
  );
};
