import { Button, List } from 'antd';

import { AtlasGqlHorizonDamage, AtlasGqlHorizonDamageObservation } from 'types/atlas-graphql';

import { usePermissions } from 'utils/usePermissions';
import { DAMAGE_EDIT_ATTRIBUTES } from 'utils/access-control/rules';

import { ObservationListItem, ObservationListItemTypes } from './ObservationListItem';
import { ObservationSummary } from './ObservationSummary';
import { DetailCard, DetailContainer } from '../ComparerPane/ComparerPane.style';

type ObservationComparerDetailProps = {
  paneSide: 'left' | 'right';
  damage: AtlasGqlHorizonDamage;
  observations: AtlasGqlHorizonDamageObservation[];
  disabledObservationIds: string[];
  selectedObservation: AtlasGqlHorizonDamageObservation;
  setSelectedObservationId: (id: string) => void;
  setRemoveConfirmationOpenForId?: (id: string) => void;
};

export const ObservationComparerDetail: React.FunctionComponent<ObservationComparerDetailProps> = ({
  paneSide,
  damage,
  observations,
  disabledObservationIds,
  selectedObservation,
  setSelectedObservationId,
  setRemoveConfirmationOpenForId,
}) => {
  const { CanAccess } = usePermissions();

  return (
    <DetailContainer>
      <DetailCard title={'Observations'} size="small" width="33%">
        <List
          style={{ width: '100%' }}
          size="small"
          dataSource={observations}
          renderItem={(o: AtlasGqlHorizonDamageObservation) => (
            <ObservationListItem
              key={o.id}
              observation={o}
              disabled={disabledObservationIds.includes(o.id)}
              selected={o.id === selectedObservation.id}
              type={ObservationListItemTypes.Primary}
              onClick={() => setSelectedObservationId(o.id)}
            />
          )}
        />
      </DetailCard>
      <DetailCard
        title={'Observation Details'}
        size="small"
        actions={
          CanAccess({ rules: [DAMAGE_EDIT_ATTRIBUTES] }) && setRemoveConfirmationOpenForId
            ? [
                <Button
                  key={'remove-button'}
                  onClick={() => setRemoveConfirmationOpenForId(selectedObservation.id)}
                  type="primary"
                >
                  Remove from Damage
                </Button>,
              ]
            : []
        }
      >
        <ObservationSummary observation={selectedObservation} />
      </DetailCard>
    </DetailContainer>
  );
};
