import styled from 'styled-components';
import COLOR from 'utils/color/definitions';

export const PlotContainer = styled.div`
  background: ${COLOR.WHITE};
  max-height: 0;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  width: 100%;

  &.open {
    height: min-content;
    max-height: 70vh;
    opacity: 1;
  }
`;

export const PlotHeader = styled.div`
  align-items: center;
  background: ${COLOR.WHITE};
  box-shadow: 0px 2px 1px 0px #00000033;
  display: flex;
  height: 64px;
  justify-content: space-between;
  padding: 1rem;
  width: 100%;

  > div:first-of-type {
    flex-grow: 1;
  }

  .ant-select {
    margin-left: 0.5rem;
    width: 480px;
  }

  button {
    border: none;
  }
`;

export const PlotGrid = styled.div<{ plotCount: number }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > svg {
    display: flex;
    flex-grow: 1;
    width: ${({ plotCount }) => (plotCount > 2 ? '50%' : '100%')};

    align-items: center;
    justify-content: center;
  }
`;
