import styled, { StyledComponent } from 'styled-components';
import { Button, Carousel, Tag } from 'antd5';
import LazyLoad, { LazyLoadProps } from 'react-lazyload';
import SeverityTag from 'components/SeverityTag';
import COLORS from 'utils/color/definitions';

export const ThumbnailContainer = styled.div<{ aspectRatio?: string }>`
  position: relative;
  overflow: hidden;
  display: block;
  aspect-ratio: ${({ aspectRatio }) => aspectRatio ?? '4/3'};
  width: 100%;
  height: 100%;
`;

export const ThumbnailImage = styled.img<{ scaleFilter: number | undefined }>`
  width: ${({ scaleFilter }) => (scaleFilter ? '100%' : 'auto')};
  max-width: ${({ scaleFilter }) => (scaleFilter ? 'none' : '100%')};
  max-height: 100%;
  object-fit: contain;
`;

export const StyledSvg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;

  &:hover {
    polygon {
      stroke-width: 0.006;
    }
  }
`;

export const StyledPolygon = styled.polygon<{ selected?: boolean }>`
  fill: none;
  stroke-width: 0.003;
  stroke: ${({ theme, selected }) => (selected ? theme.blue : theme.red)};
  ${({ onClick }) => (onClick ? 'cursor: pointer;' : '')};
`;

export const AttributesContainer = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
`;

export const AttributesContainerZoomable = styled.span`
  align-items: center;
  display: flex;
`;

// TODO: swap this for new component
export const StyledSeverity = styled(SeverityTag)`
  margin-right: 0.25rem !important;
`;

export const DistanceTag = styled(Tag)`
  margin: 0 !important;
  color: white !important;
  background: rgba(0, 0, 0, 0.65) !important;
`;

export const CarouselContainer = styled.div`
  height: 100%;
  position: relative;

  .ant-carousel {
    height: 100%;
  }

  > button {
    height: 49px;
    position: absolute;
    top: calc(50% - 24px);
    width: 14px !important;
    z-index: 1;

    &.next-button {
      right: 0;
    }

    .anticon {
      font-size: 10px !important;
      vertical-align: 0 !important;
    }
  }
`;

export const StyledCarousel = styled(Carousel)`
  height: 100%;

  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide,
  .slick-slide > div,
  .slick-slide > div > div {
    height: 100%;
  }

  .slick-dots {
    align-items: center;
    background: #ffffffd9;
    border-top: 1px solid #d9d9d9;
    bottom: 0;
    display: flex;
    flex-direction: row;
    height: 24px;
    justify-content: center;
    margin: 0;
    width: 100%;

    li {
      align-items: center;
      display: flex;
      justify-content: center;
      height: 12px;
      margin: 0;
      width: 12px;

      button {
        background: ${COLORS.TEAL} !important;
        border-radius: 8px;
        height: 8px;
        opacity: 0.4;
        width: 8px;
      }

      &.slick-active {
        button {
          border-radius: 12px;
          height: 12px;
          opacity: 1;
          width: 12px;
        }
      }
    }
  }
`;

export const EmptyImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const RotationContainer = styled.div<{ degrees: number | false | undefined }>`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  transition: transform 0.5s;
  transform: ${({ degrees }) => !!degrees && `rotateZ(${degrees}deg)`};
`;

export const ExpandButton = styled(Button)<{ $isInCarousel: boolean }>`
  bottom: ${({ $isInCarousel }) => ($isInCarousel ? 'calc(24px + 0.5rem)' : '0.5rem')};
  left: 0.5rem;
  position: absolute;
`;

export const ExpandButtonContainer = styled.div<{ height: string; width: string }>`
  position: absolute;
  height: ${({ height }) => height};
  top: 0;
  width: ${({ width }) => width};
`;

export const LazyLoadContainer: StyledComponent<React.FC<LazyLoadProps>, any, {}, never> = styled(
  LazyLoad
)`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
`;
