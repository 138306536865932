import { Any, List, Object } from 'ts-toolbelt';
import { PapiGqlGetUserQuery } from 'types/papi-graphql';

export interface IMenuClickevent {
  key: React.Key;
  keyPath: React.Key[];
  item: React.ReactInstance;
  domEvent: React.MouseEvent<HTMLElement>;
}

export type TSelectableActionInput = {
  selected?: any[];
  showSelected?: boolean;
  onClear?: React.MouseEventHandler<HTMLElement> | undefined;
  children?: any | null;
};

export type TBreadCrumb = {
  title: string;
  href?: string;
};

export type NonNullPath<O extends any, P extends List.List<Any.Key>> = NonNullable<
  Object.Path<O, P>
>;

export const notEmpty = <TValue>(value: TValue | null | undefined | false): value is TValue => {
  return value !== null && value !== undefined;
};

export type User = NonNullPath<PapiGqlGetUserQuery, ['user']>;
export type Customer = Omit<NonNullPath<User, ['customer']>, '__typename'>;

/**
 * Partial<T> allows any top-level property of T to be undefined;
 * RecursivePartial<T> allows any property of T and any property of
 * its array or object properties to be undefined (i.e. all properties
 * at all levels in the object are optional).
 */
export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object | undefined
      ? RecursivePartial<T[P]>
      : T[P];
};

// This allows you to pull a singular element out of gql generated query types
// that result in an array of elements, null or undefined
export type SingularElement<T> = T extends (infer U)[]
  ? U
  : T extends null | undefined
    ? never
    : never;
